import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { OpenInfo, OpenInfoStatusJa } from '../../../../models/gmb-location';

import BaseRaw from './BaseRaw';
import OpenInfoModal from '../form-modal/OpenInfoModal';

type Props = {
  label: string;
  openInfo: OpenInfo | null;
  handleUpdate?: (params: UpdateGmbLocationParams) => Promise<void>;
  editable?: boolean;
};

const ProfileRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        value={`${props.openInfo?.openingDate?.year || '-'}年${
          props.openInfo?.openingDate?.month || '-'
        }月${props.openInfo?.openingDate?.day || '-'}日(${
          OpenInfoStatusJa[props.openInfo?.status || 'OPEN']
        })`}
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <OpenInfoModal
          isShow={isShow}
          label={props.label}
          openInfo={props.openInfo}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(ProfileRaw);
