import React, { useEffect, memo, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { GmbLocation } from '../../../models/gmb-location';
import { SearchGmbLocationParams as PartnerSearchGmbLocationParams } from '../../../requests/partner/gmb-locations';
import { SearchGmbLocationParams as CompanySearchGmbLocationParams } from '../../../requests/company/gmb-locations';

type SearchGmbLocationParams = PartnerSearchGmbLocationParams | CompanySearchGmbLocationParams;

type Props = {
  gmbLocationId?: number;
  handleGmbLocationRequest: (params: SearchGmbLocationParams) => Promise<GmbLocation[]>;
  handleAfterSearch: (companyId?: number, name?: string) => Promise<void>;
};

type Option = {
  label: string;
  value: number;
};

const AyncSelectCompany: React.FC<Props> = (props) => {
  const { gmbLocationId, handleGmbLocationRequest, handleAfterSearch } = props;

  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [selectedGmbLocation, setSelectedGmbLocation] = useState<
    { label: string; value: number } | undefined
  >(undefined);

  useEffect(() => {
    handleSearch({ gmbLocationId }).then((v) => {
      setSelectedGmbLocation({ label: v[0]?.label, value: v[0]?.value });
    });
  }, [gmbLocationId]);

  const handleSearch = async (params: SearchGmbLocationParams) => {
    const result = await handleGmbLocationRequest(params);
    return result.map((v: GmbLocation) => {
      return {
        label: v.locationName,
        value: v.id,
      };
    });
  };

  const loadOptions = async (name: string): Promise<Option[]> => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    return await handleSearch({ name });
  };

  if (selectedGmbLocation === undefined) {
    return <></>;
  }

  return (
    <div style={{ width: '100%' }}>
      <AsyncSelect
        isClearable
        defaultValue={selectedGmbLocation?.label ? selectedGmbLocation : undefined}
        placeholder="ショップ検索"
        onChange={async (option: Option) => {
          setSelectedGmbLocation({ label: option?.label, value: option?.value });
          await handleAfterSearch(option?.value, option?.label);
        }}
        loadOptions={loadOptions}
      />
    </div>
  );
};

export default memo(AyncSelectCompany);
