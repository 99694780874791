import React, { memo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import BootstrapModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import BootstrapAlert from 'react-bootstrap/Alert';
import { GmbLocationReview } from '../../../models/gmb-location-review';
import { UpdateReplyParams } from '../../../requests/partner/gmb-location-reviews';

type Props = {
  isShow: boolean;
  gmbLocationReview: GmbLocationReview;
  handleClose: () => void;
  handleUpdate: (id: number, params: UpdateReplyParams) => Promise<void>;
  keywords?: string[];
};

const ReviewModal: React.FC<Props> = (props) => {
  const { keywords } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [replyComment, setReplyComment] = useState<string | undefined>(
    props.gmbLocationReview.reply?.comment
  );

  const companyNames = props.gmbLocationReview.companies.map((v) => v.name).join('/');

  const handleClose = () => {
    props.handleClose();
  };
  const handleUpdate = props.handleUpdate;

  const handleChangeInput = (v: string) => {
    setReplyComment(v);
  };

  return (
    <BootstrapModal show={props.isShow} onHide={handleClose} size="lg" centered>
      {isLoading ? (
        <BootstrapModal.Body style={{ textAlign: 'center' }}>
          <Spinner animation="border" role="status" />
        </BootstrapModal.Body>
      ) : (
        <>
          <BootstrapModal.Header closeButton>
            <BootstrapModal.Title>{
              // eslint-disable-next-line no-irregular-whitespace
              `${companyNames}　|　${props.gmbLocationReview.locationName}`
            }</BootstrapModal.Title>
          </BootstrapModal.Header>
          <BootstrapModal.Body>
            <p style={{ whiteSpace: 'pre-line' }}>
              {props.gmbLocationReview.comment || 'コメントのないレビューです'}
            </p>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={6}
                placeholder="例: 口コミありがとうございます。これからもがんばります。"
                value={replyComment}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeInput(e.target.value);
                }}
              />
            </Form.Group>
            {(keywords?.length || 0) > 0 && (
              <Form.Group>
                <BootstrapAlert variant={'primary'}>
                  設定キーワード: {keywords?.map((keyword) => `「${keyword}」`).join(',')}
                </BootstrapAlert>
              </Form.Group>
            )}
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              閉じる
            </Button>
            {handleUpdate && (
              <Button
                variant="primary"
                disabled={replyComment === undefined || replyComment == ''}
                onClick={async () => {
                  if (replyComment === undefined || replyComment == '') {
                    return;
                  }
                  setIsLoading(true);
                  try {
                    await handleUpdate(props.gmbLocationReview.id, { comment: replyComment });
                  } finally {
                    setIsLoading(false);
                  }
                }}
              >
                返信
              </Button>
            )}
          </BootstrapModal.Footer>
        </>
      )}
    </BootstrapModal>
  );
};

export default memo(ReviewModal);
