import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import { Categories, Category } from '../../../../models/gmb-location';

import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { RequestGmbCategory } from '../../../../requests/gmb-categories';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  categories: Categories;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

type Option = {
  label: string;
  value: string;
};

const AdditionalCategoriesModal: React.FC<Props> = (props) => {
  const [additionalCategories, setAdditionalCategories] = useState<Category[]>(
    props.categories.additionalCategories
  );
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const handleClose = () => {
    setAdditionalCategories(props.categories.additionalCategories);
    props.handleModal();
  };

  const searchCategories = async (value: string): Promise<Option[]> => {
    const result = await RequestGmbCategory.search({ displayName: value });
    return result.map((v: Category) => {
      return {
        label: v.displayName,
        value: v.name,
      };
    });
  };

  const loadOptions = async (inputValue: string): Promise<Option[]> => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    return await searchCategories(inputValue);
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          await props.handleUpdate({
            categories: {
              primaryCategory: props.categories.primaryCategory,
              additionalCategories,
            },
          });
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AsyncSelect
            defaultValue={additionalCategories.map((v: Category) => {
              return {
                label: v.displayName,
                value: v.name,
              };
            })}
            isMulti
            onChange={(options) => {
              setAdditionalCategories(
                options.map((v): Category => {
                  return {
                    displayName: v.label,
                    name: v.value,
                  };
                })
              );
            }}
            loadOptions={loadOptions}
          />
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(AdditionalCategoriesModal);
