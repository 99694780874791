import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TextField from '@material-ui/core/TextField';
import { SaveLocalBulkPostParams } from '../../../../requests/partner/gmb-local-bulk-post';

import { GmbLocalBulkPost, CallToActionType } from '../../../../models/gmb-local-bulk-post';

import BaseModal from '../../BaseModal';
import ImageUpload from '../../gmb-local-post-share/ImageUpload';

import { validateUrl } from '../../../../utils/validate';
import { Alert } from '../../../../service/alert';

import { useUploadImage } from '../../../../hooks/useUploadImage';

import moment from '../../../../utils/moment-ja';

type Props = {
  isShow: boolean;
  companyId: number;
  gmbLocalBulkPost?: GmbLocalBulkPost;
  handleModal: () => void;
  handleCreate: (params: SaveLocalBulkPostParams) => Promise<void>;
  handleDelete?: () => Promise<void>;
};

const OfferModal: React.FC<Props> = (props) => {
  const { isShow, companyId, gmbLocalBulkPost, handleModal, handleCreate, handleDelete } = props;
  const [summary, setSummary] = useState<string>(gmbLocalBulkPost?.localPost?.summary || '');
  const [offerTitle, setOfferTitle] = useState<string>(
    gmbLocalBulkPost?.localPost?.event.title || ''
  );
  const [startDate, setStartDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endDate, setEndDate] = useState<string>(moment().add(7, 'days').format('YYYY-MM-DD'));
  const [endTime, setEndTime] = useState<string>('00:00');
  const [couponCode, setCouponCode] = useState<string>(
    gmbLocalBulkPost?.localPost?.offer?.couponCode || ''
  );
  const [redeemOnlineUrl, setRedeemOnlineUrl] = useState<string>(
    gmbLocalBulkPost?.localPost?.offer?.redeemOnlineUrl || ''
  );
  const [termsConditions, setTermsConditions] = useState<string>(
    gmbLocalBulkPost?.localPost?.offer?.termsConditions || ''
  );
  const [callToActionType, setCallToActionType] =
    useState<CallToActionType>('ACTION_TYPE_UNSPECIFIED');
  const [callToActionUrl, setCallToActioUrl] = useState<string>('');
  const { publicUrl, selectingPostImage, handleAttachImageFile } = useUploadImage();

  const resetForm = () => {
    setOfferTitle('');
    setCallToActionType('ACTION_TYPE_UNSPECIFIED');
    setCallToActioUrl('');
  };

  const handleClose = () => {
    resetForm();
    handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        alert={true}
        buttonText="投稿"
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          if (offerTitle == '') {
            throw Alert.danger('特典タイトルは必須です');
          }
          if (redeemOnlineUrl != '' && !validateUrl(redeemOnlineUrl)) {
            throw Alert.danger('利用規約へののリンクが正しくありません');
          }

          const momentStartDateTime = moment(`${startDate} ${startTime}`);
          const momentEndDateTime = moment(`${endDate} ${endTime}`);
          try {
            await handleCreate({
              companyId,
              params: {
                summary: summary,
                event: {
                  title: offerTitle,
                  schedule: {
                    startDate: {
                      year: momentStartDateTime.year(),
                      month: momentStartDateTime.month() + 1,
                      day: momentStartDateTime.date(),
                    },
                    startTime: {
                      hours: momentStartDateTime.hour(),
                      minutes: momentStartDateTime.minutes(),
                      seconds: 0,
                      nanos: 0,
                    },
                    endDate: {
                      year: momentEndDateTime.year(),
                      month: momentEndDateTime.month() + 1,
                      day: momentEndDateTime.date(),
                    },
                    endTime: {
                      hours: momentEndDateTime.hour(),
                      minutes: momentEndDateTime.minutes(),
                      seconds: 0,
                      nanos: 0,
                    },
                  },
                },
                languageCode: 'JP',
                topicType: 'OFFER',
                offer: {
                  couponCode,
                  redeemOnlineUrl,
                  termsConditions,
                },
                callToAction:
                  callToActionType == 'ACTION_TYPE_UNSPECIFIED'
                    ? undefined
                    : {
                        actionType: callToActionType,
                        url: callToActionType == 'CALL' ? '' : callToActionUrl,
                      },
                media:
                  publicUrl !== null
                    ? [
                        {
                          mediaFormat: 'PHOTO',
                          sourceUrl: publicUrl,
                        },
                      ]
                    : undefined,
              },
            });
            Alert.success('投稿しました');
            handleModal();
          } catch {
            Alert.danger('投稿に失敗しました');
          }
        }}
        handleDelete={handleDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`最新情報投稿の追加`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUpload
            selectingPostImage={selectingPostImage}
            handleAttachImageFile={handleAttachImageFile}
          />
          <Form.Group>
            <Form.Label>特典タイトル</Form.Label>
            <Form.Control
              placeholder="特典タイトルを入力してください"
              value={offerTitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOfferTitle(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>開催日</Form.Label>
            <Form.Group>
              <TextField
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={startDate}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setStartDate(cv.target.value);
                }}
              />
              <TextField
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={startTime}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setStartTime(cv.target.value);
                }}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>終了日</Form.Label>
            <Form.Group>
              <TextField
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={endDate}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setEndDate(cv.target.value);
                }}
              />
              <TextField
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={endTime}
                inputProps={{
                  step: 300, // 5 min
                }}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setEndTime(cv.target.value);
                }}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>特典の詳細(省略可)</Form.Label>
            <Form.Control
              placeholder="特典の詳細を入力してください"
              value={summary}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSummary(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>クーポンコード(省略可)</Form.Label>
            <Form.Control
              placeholder="クーポンコードを入力してください"
              value={couponCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCouponCode(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>特典利用へのリンク(省略可)</Form.Label>
            <Form.Control
              placeholder="特典利用へのリンクを入力してください"
              value={redeemOnlineUrl}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRedeemOnlineUrl(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>利用規約(省略可)</Form.Label>
            <Form.Control
              placeholder="利用規約を入力してください"
              value={termsConditions}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTermsConditions(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(OfferModal);
