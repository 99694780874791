export const commonExpalin = (type: string): string => {
  return `評価基準についての詳細は<a key="link_${type}" target="_blank" href="https://analyzer-business.mypl.net/f5fb49fbf35e4d41bfd6c66fef95dd4a#6be4f3734c154c35885b735e4487dfb3">ユーザーガイド<i class="fas fa-external-link-alt"></i></a>をご覧ください`;
};

export type ExplainContent = {
  title?: string;
  bodies: string[];
};

export const gmbLocalEvaluationPostExplain: ExplainContent[] = [
  {
    title: '指定キーワードの出現有無',
    bodies: ['◎ 指定のキーワードが含まれている × 指定のキーワードが含まれていない'],
  },
  {
    title: '平均文字数',
    bodies: ['◎ 全角301文字以上 〇 全角201～300文字以上 △ 全角101～200文字以上 × 全角100文字未満'],
  },
  {
    title: '投稿有無',
    bodies: [
      '◎ 1投稿以上 × 投稿なし（0投稿） 投稿の継続週数 ◎ 8週以上 〇 5週～7週 △ 2週～4週 ×0週～1週',
    ],
  },
  {
    title: undefined,
    bodies: [commonExpalin('GmbLocalEvaluationPostExplain')],
  },
];

export const gmbLocalEvaluationReviewExplain: ExplainContent[] = [
  {
    title: '口コミ内キーワード出現回数',
    bodies: ['◎ 4回以上 〇 2回～3回 △ 1回 × 0回'],
  },
  {
    title: '返信内キーワード出現回数',
    bodies: ['◎ 4回以上 〇 2回～3回 △ 1回 × 0回'],
  },
  {
    title: '口コミ返信率口コミ返信率',
    bodies: ['◎ 81～100% 〇 61～80% △ 31～60% × 0～30%'],
  },
  {
    title: '口コミの平均返信期間',
    bodies: ['◎ 24時間以内 〇 48時間以内 △ 168時間（24時間×7）以内 × 168時間（24時間×7）より長い'],
  },
  {
    title: '口コミ平均点',
    bodies: ['◎ 4.1以上 〇 3.5～4.0 △ 3.0～3.4 × 2.9未満'],
  },
  {
    title: '口コミ件数',
    bodies: ['◎ 4件以上 〇 2件～3件 △ 1件 × 0件'],
  },
  {
    title: undefined,
    bodies: [commonExpalin('GmbLocalEvaluationReviewExplain')],
  },
];

export const gmbLocalEvaluationMediaImageExplain: ExplainContent[] = [
  {
    title: 'ロゴ写真、カバー写真の設定',
    bodies: [
      '◎ ロゴ、カバーいずれも設定あり 〇 ロゴ設定あり、カバー設定なし △ ロゴ設定なし、カバー画像設定あり × ロゴ、カバーいずれも設定なし',
    ],
  },
  {
    title: '写真追加有無',
    bodies: ['◎ 1枚以上追加 × 追加なし（0枚）'],
  },
  {
    title: '写真追加の継続週数',
    bodies: ['◎ 8週以上 〇 5週～7週 △ 2週～4週 × 0週～1週'],
  },
  {
    title: undefined,
    bodies: [commonExpalin('GmbLocalEvaluationMediaImageExplain')],
  },
];

export const gmbLocalEvaluationBasicExplain: ExplainContent[] = [
  {
    title: '指定キーワード網羅数',
    bodies: [
      '指定キーワード数 = 1 ⇒ ◎ 1キーワード含む × 0キーワード含む',
      '指定キーワード数 = 2 ⇒ ◎ 2キーワード含む △ 1キーワード含む × 0キーワード含む',
      '指定キーワード数 = 3 ⇒ ◎ 3キーワード含む 〇 2キーワード含む △ 1キーワード含む × 0キーワード含む',
      '指定キーワード数 = 4 ⇒ ◎ 4キーワード含む 〇 3キーワード含む △ 2キーワード含む × 1キーワード含む × 0キーワード含む',
    ],
  },
  {
    title: '文字数',
    bodies: ['◎ 全角500文字以上 〇 全角200文字以上 △ 全角100文字以上 × 全角100文字未満'],
  },
  {
    title: undefined,
    bodies: [commonExpalin('GmbLocalEvaluationBasicExplain')],
  },
];
