import React, { memo, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import MediaItemCard from './MediaItemCard';
import ViewModal from './ViewModal';

import { MediaItem } from '../../../models/location-media';

import { sliceByNumber } from '../../../utils/app-array';
import { isSP } from '../../../utils/app-util';

type Props = {
  locationMediaPath: string;
  fetchLatestLocationMediaImages: () => Promise<MediaItem[]>;
};

const LocalPostList: React.FC<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [selectedMediaItem, setSelectedMediaItem] = useState<MediaItem | null>(null);

  const refresh = async (): Promise<void> => {
    setIsLoading(true);
    const v = await props.fetchLatestLocationMediaImages();
    setMediaItems(v);
    setIsLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handleModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mt-3">
        {mediaItems.length <= 0 ? (
          <h5>写真がありません</h5>
        ) : (
          <>
            {sliceByNumber(mediaItems, isSP() ? 2 : 5).map((splitMediaItems, index) => (
              <Row key={`gmb_location_media_image_view_parent_${index}`} sm={2} md={5} lg={5}>
                {splitMediaItems.map((mediaItem, childIndex) => {
                  return (
                    <Col key={`gmb_location_media_image_view_${childIndex}`}>
                      <MediaItemCard
                        isLoading={false}
                        mediaItem={mediaItem}
                        handleModal={() => {
                          setSelectedMediaItem(mediaItem);
                          setShowModal(true);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
            {selectedMediaItem && (
              <ViewModal
                isShow={showModal}
                mediaItem={selectedMediaItem}
                handleModal={handleModal}
              />
            )}
          </>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <a href={props.locationMediaPath} type="button" className="btn btn-link">
          投稿画像一覧
        </a>
      </div>
    </>
  );
};

export default memo(LocalPostList);
