import Axios from 'axios';
import { ApiClient } from './client';
import { AttachmentModel } from '../models/share/attachment-model';

type putFileResponse = {
  presignedUrl: string;
  publicLink: string;
};

type uploadLocationMediaParams = {
  gmbLocationId: number;
  publicLink: string;
};

type uploadLocationMediaResponse = {
  resourceName: string;
};

export class RequestGoogleMedias {
  static postPresignedUrl = async (): Promise<putFileResponse> => {
    const res = await ApiClient.get(`/api/google_medias/post_presigned_url`);
    return res.data;
  };
  static putFile = async (presignedUrl: string, attachment: AttachmentModel): Promise<void> => {
    await Axios.put(presignedUrl, attachment.file, {
      headers: {
        'Content-Type': attachment.file.type,
      },
    });
  };

  static uploadLocationMedia = async (
    params: uploadLocationMediaParams
  ): Promise<uploadLocationMediaResponse> => {
    const res = await ApiClient.post(`/api/google_medias/upload_location_media`, params);
    return res.data;
  };
}
