import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import MoreHoursDetailRaw from '../../general/gmb-locations/raws/MoreHoursDetailRaw';
import { Alert } from '../../../service/alert';
import AsyncSelect from 'react-select/async';

import { Category, MoreHours } from '../../../models/gmb-location';
import { HoursType } from '../../../models/gmb-location/hours-type';
import { CompanyMoreHoursTemplate } from '../../../models/company-more-hours-template';

import { UpdateGmbLocationParams } from '../../../requests/partner/gmb-locations';
import { RequestGmbHoursType } from '../../../requests/gmb-hours-types';
import { RequestGmbCategory } from '../../../requests/gmb-categories';

type Props = {
  moreHours: MoreHours[];
  handleUpdate: (
    params: UpdateGmbLocationParams,
    attributesTemplateId?: number,
    categoryId?: string,
    templateTitle?: string
  ) => Promise<CompanyMoreHoursTemplate>;
  moreHoursTemplateId?: number;
  gmbCategory?: Category;
  templateTitle?: string;
};

type Option = {
  label: string;
  value: string;
};

const MoreHoursForm: React.FC<Props> = (props) => {
  const [allHoursTypes, setAllHoursTypes] = useState<HoursType[]>([]);
  const [hoursTypes, setHoursTypes] = useState<HoursType[]>([]);

  const [moreHours, setMoreHours] = useState<MoreHours[]>(props.moreHours);
  const [categoryId, setCategoryId] = useState<string | undefined>(props.gmbCategory?.categoryId);
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [moreHoursTemplateId, setMoreHoursTemplateId] = useState<number | undefined>(
    props.moreHoursTemplateId
  );

  const defaultCategoryId = props.gmbCategory?.categoryId;
  useEffect(() => {
    if (defaultCategoryId !== undefined) {
      fetchHoursType(defaultCategoryId);
    }
    RequestGmbHoursType.all({
      categoryId: null,
    }).then((v: HoursType[]) => {
      setAllHoursTypes(v);
    });
  }, [defaultCategoryId]);

  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    if (templateTitle === undefined || templateTitle === '') {
      Alert.danger('タイトルは必須です');
      throw 'タイトルは必須です';
    }
    try {
      const result = await props.handleUpdate(
        params,
        moreHoursTemplateId,
        categoryId,
        templateTitle
      );
      setMoreHoursTemplateId(result.id);
      setMoreHours(result.moreHours);
      Alert.success('保存しました');
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };

  const searchCategories = async (value: string): Promise<Option[]> => {
    const result = await RequestGmbCategory.search({ displayName: value });
    return result.map((v: Category) => {
      return {
        label: v.displayName,
        value: v.categoryId,
      };
    });
  };

  const fetchHoursType = async (categoryId: string): Promise<void> => {
    RequestGmbHoursType.all({
      categoryId: categoryId,
    }).then((v: HoursType[]) => {
      setHoursTypes(v);
    });
  };

  const loadOptions = async (inputValue: string): Promise<Option[]> => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    return await searchCategories(inputValue);
  };

  if (allHoursTypes.length < 1) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>カテゴリを選択してください</Form.Label>
          <AsyncSelect
            defaultValue={{
              label: props.gmbCategory?.displayName,
              value: props.gmbCategory?.categoryId,
            }}
            isDisabled={props.gmbCategory?.categoryId !== undefined}
            onChange={(option: Option) => {
              setCategoryId(option.value);
              fetchHoursType(option.value);
            }}
            loadOptions={loadOptions}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>テンプレートを設定してください</Form.Label>
          <Row>
            <Col>
              <MoreHoursDetailRaw
                label="営業時間テンプレート"
                allHoursTypes={allHoursTypes}
                moreHours={moreHours}
                hoursTypes={hoursTypes}
                handleUpdate={handleUpdate}
                templateTitle={props.templateTitle}
              />
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </>
  );
};

export default memo(MoreHoursForm);
