import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseRaw from './BaseRaw';
import PrimaryPhoneModal from '../form-modal/PrimaryPhoneModal';
import { PhoneNumbers } from 'models/gmb-location';

type Props = {
  label: string;
  phoneNumbers?: PhoneNumbers | null;
  handleUpdate?: (params: UpdateGmbLocationParams) => Promise<void>;
  editable?: boolean;
};

const LocationNameRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    setIsLoading(true);
    if (props.handleUpdate !== undefined) await props.handleUpdate(params);
    setIsLoading(false);
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        value={props.phoneNumbers?.primaryPhone || '未設定'}
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <PrimaryPhoneModal
          isShow={isShow}
          label={props.label}
          phoneNumbers={props.phoneNumbers}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(LocationNameRaw);
