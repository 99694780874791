import React, { memo, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { useLoadScript, Autocomplete } from '@react-google-maps/api';
const GmapApiKey = 'AIzaSyDXBhCGpf2zncYvWblB6AnQfjF8oBvtnNY';
type places = 'places';
const libraries = ['places' as places];

type Props = {
  handlePlaceName: (v: string) => void;
};

const GmapPlace: React.FC<Props> = (props) => {
  const [placeValue, setPlaceValue] = useState<string>('');
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GmapApiKey,
    libraries,
  });

  if (loadError) return <>Error</>;
  if (!isLoaded) return <>Loading...</>;

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete types={['(regions)']}>
        <Form.Control
          type="text"
          value={placeValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPlaceValue(e.target.value);
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPlaceValue('');
            props.handlePlaceName(e.target.value);
          }}
        />
      </Autocomplete>
    </div>
  );
};

export default memo(GmapPlace);
