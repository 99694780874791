import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import BaseRaw from './BaseRaw';
import PrimaryCategoryModal from '../form-modal/PrimaryCategoryModal';
import { Categories } from '../../../../models/gmb-location';

type Props = {
  label: string;
  categories: Categories;
  handleUpdate?: (params: UpdateGmbLocationParams) => Promise<void>;
  editable?: boolean;
};

const PrimaryCategoryRaw: React.FC<Props> = (props) => {
  const category = props.categories.primaryCategory;
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        value={category?.displayName || '未設定'}
        handleModal={editable ? handleModal : undefined}
      />
      {editable && category ? (
        <PrimaryCategoryModal
          isShow={isShow}
          label={props.label}
          categories={props.categories}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(PrimaryCategoryRaw);
