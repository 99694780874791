import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

import { Period, DayOfTheWeek } from '../../../../models/gmb-location/period';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { CompanyRegularHoursTemplate } from '../../../../models/company-regular-hours-template';

import PeriodsTable from '../partial/PeriodsTable';

import { usePeriods } from '../../../../hooks/usePeriods';

import BaseModal from '../../BaseModal';

type Template = CompanyRegularHoursTemplate;
type Option = {
  value: Period[];
  label: string;
};

type Props = {
  isShow: boolean;
  label: string;
  regularHoursPeriods: Period[];
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
  templateTitle?: string;
  templateList?: Template[];
};

const MoreHoursModal: React.FC<Props> = (props) => {
  const [templateTitle, setTemplateTitle] = useState<string | undefined>(props.templateTitle);
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const periodsStates = usePeriods({
    periods: props.regularHoursPeriods,
  });

  const { allWeekHoursPeriods, handleClose, resetPeriods } = periodsStates;

  const handleModalClose = () => {
    props.handleModal();
    handleClose();
  };

  const options = props.templateList?.map((v: Template): Option => {
    return { value: v.regularHours.periods, label: v.title };
  });

  const handleSelected = async (option: Option) => {
    setIsLoading(true);
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    resetPeriods(option.value);
    setIsLoading(false);
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleModalClose}
        handleUpdate={async (): Promise<void> => {
          const updatePeriods = Object.entries(allWeekHoursPeriods)
            .map((value: [key: DayOfTheWeek, periods: Period[]]) => {
              const [_key, periods] = value;
              return periods;
            })
            .flat();
          if (templateTitle === undefined && updatePeriods.length <= 0) {
            await props.handleUpdate({ specialHours: { specialHourPeriods: [] } });
          }
          await props.handleUpdate(
            {
              regularHours: { periods: updatePeriods },
            },
            templateTitle
          );
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <Container>
              {templateTitle !== undefined ? (
                <Form.Group>
                  <Form.Label>タイトル</Form.Label>
                  <Form.Control
                    placeholder="タイトル"
                    value={templateTitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTemplateTitle(e.target.value);
                    }}
                  />
                </Form.Group>
              ) : (
                <Form.Group>
                  <Form.Label>テンプレートから選択</Form.Label>
                  <Select onChange={handleSelected} options={options} />
                </Form.Group>
              )}
              <PeriodsTable {...periodsStates} readonly={false} holidayVisible={true} />
            </Container>
          )}
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(MoreHoursModal);
