import React, { memo } from 'react';
import { Line } from 'react-chartjs-2';
import { isSP } from 'utils/app-util';

type Dataset = {
  label: string;
  scaleShowLabels: boolean;
  data: number[];
  borderColor: string[];
  fill: boolean;
  backgroundColor?: string;
};

type Props = {
  labels: string[];
  datasets: Dataset[];
  width?: number;
  height?: number;
  reverse?: boolean;
  ticks?: {
    min: number;
    stepSize: number;
    reverse: boolean;
  };
  ticksMaxLabel?: string;
};

const LineChart: React.FC<Props> = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  const ticks = props.ticksMaxLabel
    ? {
        ...props.ticks,
        callback: function (rank: number) {
          if (rank == 41) {
            return props.ticksMaxLabel;
          } else {
            return rank;
          }
        },
      }
    : props.ticks;

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        onClick: null,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: ticks ? ticks : {},
        },
      ],
    },
  };
  return <Line data={data} options={options} height={isSP() ? props.height : 100} />;
};

export default memo(LineChart);
