import React, { memo, useState } from 'react';
import NewButton from '../../general/button/NewButton';

import { GmbLocation } from '../../../models/gmb-location';
import { LocalPostTopicType, LocalPostTopicTypeJp } from '../../../models/gmb-local-post';

import { SaveLocalPostParams as PartnerSaveLocalPostParams } from '../../../requests/partner/gmb-local-posts';
import { SaveLocalPostParams as CompanySaveLocalPostParams } from '../../../requests/company/gmb-local-posts';

import StandardModal from './modals/StandardModal';
import EventModal from './modals/EventModal';
import OfferModal from './modals/OfferModal';
import AlertModal from './modals/Alert';

export type SaveLocalPostParams = PartnerSaveLocalPostParams | CompanySaveLocalPostParams;

type Props = {
  gmbLocationId: number;
  topicType: LocalPostTopicType;
  handleShowGmbLocation: (id: number) => Promise<GmbLocation>;
  handleCreateLocalPost: (params: SaveLocalPostParams) => Promise<void>;
  handleAferPosted?: () => Promise<void>;
  keywords?: string[];
};

const CreateButton: React.FC<Props> = (props: Props) => {
  const {
    gmbLocationId,
    topicType,
    handleShowGmbLocation,
    handleCreateLocalPost,
    handleAferPosted,
    keywords,
  } = props;

  const [isShow, setIsShow] = useState<boolean>(false);

  const handleModal = () => {
    setIsShow(!isShow);
  };

  const handleCreate = async (params: SaveLocalPostParams) => {
    await handleCreateLocalPost(params);
    if (handleAferPosted) {
      handleAferPosted();
    }
    setIsShow(!isShow);
  };

  return (
    <>
      <NewButton onClick={handleModal}>
        {`${LocalPostTopicTypeJp[props.topicType]}を投稿する`}
      </NewButton>
      {topicType == 'STANDARD' && (
        <StandardModal
          isShow={isShow}
          gmbLocationId={gmbLocationId}
          handleShowGmbLocation={handleShowGmbLocation}
          handleModal={handleModal}
          handleCreate={handleCreate}
          keywords={keywords}
        />
      )}
      {topicType == 'EVENT' && (
        <EventModal
          isShow={isShow}
          gmbLocationId={gmbLocationId}
          handleShowGmbLocation={handleShowGmbLocation}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
      {topicType == 'OFFER' && (
        <OfferModal
          isShow={isShow}
          gmbLocationId={gmbLocationId}
          handleShowGmbLocation={handleShowGmbLocation}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
      {topicType == 'ALERT' && (
        <AlertModal
          isShow={isShow}
          gmbLocationId={gmbLocationId}
          handleShowGmbLocation={handleShowGmbLocation}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
};

export default memo(CreateButton);
