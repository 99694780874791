import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import {
  OpenInfo,
  OpenInfoStatusJa,
  OpenInfoClosedPermanently,
  OpenInfoOpen,
} from '../../../../models/gmb-location';
import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  openInfo: OpenInfo;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const OpenInfoStatusModal: React.FC<Props> = (props) => {
  const [openInfo, setOpenInfo] = useState<OpenInfo>(props.openInfo);
  const handleClose = () => {
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          const updateOpenInfo: OpenInfo = {
            status:
              openInfo.status === OpenInfoClosedPermanently
                ? OpenInfoOpen
                : OpenInfoClosedPermanently,
          };
          await props.handleUpdate({
            openInfo: updateOpenInfo,
          });
          setOpenInfo(updateOpenInfo);
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>営業ステータス</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          営業ステータスを「
          <strong>
            {
              OpenInfoStatusJa[
                openInfo.status === OpenInfoClosedPermanently
                  ? OpenInfoOpen
                  : OpenInfoClosedPermanently
              ]
            }
          </strong>
          」 に変更してもよろしいですか？
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(OpenInfoStatusModal);
