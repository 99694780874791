import React, { memo } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';

import CreateButton from './CreateButton';

import AsyncSelectCompany from '../async-select/company';

import { Company } from '../../../models/company';
import { LocalPostTopicType, LocalPostTopicTypeJp } from '../../../models/gmb-local-bulk-post';

import { SearchCompanyParams as PartnerSearchCompanyParams } from '../../../requests/partner/companies';

import { SearchParams as SearchLocalBulkPostParams } from '../../../hooks/useLocalBulkPostSearch';
import { SaveLocalBulkPostParams } from '../../../requests/company/gmb-local-bulk-post';

export type Option = {
  label: string;
  value: number;
};

export type SelectedItems = {
  companyId?: number;
  selectedTopicType: LocalPostTopicType;
};

type SearchableItem = 'company';
type SearchCompanyParams = PartnerSearchCompanyParams;

type Props = {
  searchable: boolean;
  searchableItem: SearchableItem[];
  selectedItems?: SelectedItems;
  templatePath?: string;
  refreshLocalBulkPosts?: (p: number, params?: SearchLocalBulkPostParams) => Promise<void>;
  handleCreateLocalPost: (params: SaveLocalBulkPostParams) => Promise<void>;
  handleCompanyRequest?: (params: SearchCompanyParams) => Promise<Company[]>;
  handleAfterSearch: (selectedItems?: SelectedItems) => Promise<void>;
};

const SearchBox: React.FC<Props> = (props: Props) => {
  const {
    searchableItem,
    searchable,
    selectedItems,
    templatePath,
    refreshLocalBulkPosts,
    handleCreateLocalPost,
    handleCompanyRequest,
    handleAfterSearch,
  } = props;

  const selectedCompanyId = selectedItems?.companyId;
  const selectedTopicType = selectedItems?.selectedTopicType || 'STANDARD';

  const handlAsyncCompany = async (companyId?: number, name?: string) => {
    const v =
      companyId === undefined || name === undefined ? undefined : { label: name, value: companyId };
    await handleAfterSearch({
      companyId: v?.value,
      selectedTopicType: selectedTopicType,
    });
  };

  const handleSelectTopicType = async (key: LocalPostTopicType) => {
    await handleAfterSearch({
      companyId: selectedCompanyId,
      selectedTopicType: key,
    });
  };

  const handleAferPosted = async () => {
    if (refreshLocalBulkPosts) {
      refreshLocalBulkPosts(1, {
        companyId: selectedCompanyId,
        topicType: selectedTopicType,
      });
    }
  };

  const linkToTemplateList = async () => {
    if (templatePath) {
      location.href = templatePath;
      return;
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={selectedItems?.selectedTopicType || 'STANDARD'}
        className="mb-3"
        onSelect={handleSelectTopicType}
      >
        <Tab eventKey="STANDARD" title={LocalPostTopicTypeJp['STANDARD']}></Tab>
        <Tab eventKey="EVENT" title={LocalPostTopicTypeJp['EVENT']}></Tab>
        <Tab eventKey="OFFER" title={LocalPostTopicTypeJp['OFFER']}></Tab>
        <Tab eventKey="ALERT" title={LocalPostTopicTypeJp['ALERT']}></Tab>
      </Tabs>
      <div className={`d-flex ${searchable ? 'justify-content-between' : 'justify-content-end'}`}>
        {searchable && (
          <div className="d-flex justify-content-start mr-2" style={{ width: '65%' }}>
            {searchableItem.includes('company') && handleCompanyRequest && (
              <div style={{ width: '100%' }}>
                <AsyncSelectCompany
                  companyId={selectedItems?.companyId}
                  handleRequest={handleCompanyRequest}
                  handleAfterSearch={handlAsyncCompany}
                />
              </div>
            )}
          </div>
        )}
        <div className="d-flex justify-content-end" style={{ width: '50%' }}>
          {templatePath && (
            <Button onClick={linkToTemplateList} variant="light">
              テンプレート
            </Button>
          )}
          {selectedCompanyId && (
            <div className="ml-2">
              <CreateButton
                companyId={selectedCompanyId}
                topicType={selectedTopicType}
                handleCreateLocalPost={handleCreateLocalPost}
                handleAferPosted={handleAferPosted}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SearchBox);
