export const LogoMediaValue = 'profile';
export const CoverMediaValue = 'cover';
export const AdditionalMediaValue = 'additional';
export const ExteriorMediaValue = 'exterior';
export const InteriorMediaValue = 'interior';
export const AtWorkMediaValue = 'at_work';
export const FoodAndDrinkValue = 'food_and_drink';
export const MenuMediaValue = 'menu';
export const CommonAreaMediaValue = 'common_area';
export const TeamsMediaValue = 'teams';

export type LogoMedia = typeof LogoMediaValue;
export type CoverMedia = typeof CoverMediaValue;
export type AdditionalMedia = typeof AdditionalMediaValue;
export type ExteriorMedia = typeof ExteriorMediaValue;
export type InteriorMedia = typeof InteriorMediaValue;
export type AtWorkMedia = typeof AtWorkMediaValue;
export type FoodAndDrink = typeof FoodAndDrinkValue;
export type MenuMedia = typeof MenuMediaValue;
export type CommonAreaMedia = typeof CommonAreaMediaValue;
export type TeamMedia = typeof TeamsMediaValue;

export const MediaCategoryJp = {
  [LogoMediaValue]: 'ロゴ',
  [CoverMediaValue]: 'カバー',
  [AdditionalMediaValue]: 'オーナー提供',
  [InteriorMediaValue]: '店内',
  [ExteriorMediaValue]: '外観',
  [FoodAndDrinkValue]: '食料と飲料',
  [AtWorkMediaValue]: '職場',
  [MenuMediaValue]: 'メニュー',
  [TeamsMediaValue]: 'チーム',
};

export type MediaCategory =
  | LogoMedia
  | CoverMedia
  | AdditionalMedia
  | ExteriorMedia
  | InteriorMedia
  | AtWorkMedia
  | FoodAndDrink
  | MenuMedia
  | CommonAreaMedia
  | TeamMedia;

export type Media = {
  mediaFormat: 'PHOTO' | 'VIDEO';
  googleUrl: string;
  locationAssociation: {
    category: MediaCategory;
  };
  createTime: string;
};

export type MediaItem = {
  id: number;
  gmbLocationId: number;
  locationName: string;
  viewCount: number;
  createTime: string;
  item: Media;
};
