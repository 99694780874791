import { ApiClient, PagenationApiResponse } from '../client';
import {
  GmbLocalBulkPost,
  LocalPostEvent,
  LocalPostOffer,
  LocalPostTopicType,
  CallToAction,
} from '../../models/gmb-local-bulk-post';
import { Pagination } from '../../models/pagination';

export type ListGmbLocalBulkPostParams = {
  companyId?: number;
  topicType: LocalPostTopicType;
};

export type SaveLocalBulkPostParams = {
  companyId: number;
  params: {
    summary?: string;
    event?: LocalPostEvent;
    languageCode: string;
    topicType: LocalPostTopicType;
    callToAction?: CallToAction;
    offer?: LocalPostOffer;
    alertType?: 'COVID_19';
    media?: {
      mediaFormat: 'PHOTO' | 'VIDEO';
      sourceUrl: string;
    }[];
  };
};

export class RequestGmbLocalBulkPost {
  static sync = async (): Promise<boolean> => {
    const res = await ApiClient.post(`/company/api/gmb_local_bulk_posts/sync`);
    return res.data;
  };

  static create = async (params?: SaveLocalBulkPostParams): Promise<void> => {
    const res = await ApiClient.post(`/company/api/gmb_local_bulk_posts`, params);
    return res.data;
  };

  static update = async (
    id: number,
    params?: SaveLocalBulkPostParams
  ): Promise<GmbLocalBulkPost> => {
    const res = await ApiClient.patch(`/company/api/gmb_local_bulk_posts/${id}`, params);
    return res.data;
  };

  static destroy = async (id: number): Promise<void> => {
    const res = await ApiClient.delete(`/company/api/gmb_local_bulk_posts/${id}`);
    return res.data;
  };

  static list = async (
    page: number,
    params?: ListGmbLocalBulkPostParams
  ): Promise<PagenationApiResponse<GmbLocalBulkPost[], Pagination>> => {
    const res = await ApiClient.get(`/company/api/gmb_local_bulk_posts?page=${page}`, { params });
    return res.data;
  };
}
