import React, { memo } from 'react';

import { GmbLocation, Attribute } from '../../../../models/gmb-location';
import Form from '../../../general/gmb-locations/Form';
import { RequestGmbLocation } from '../../../../requests/partner/gmb-locations';
import { GmbLocationSetting } from '../../../../models/gmb-location-setting';
import TamperPreventionButton from '../../../general/gmb-locations/switch/TamperPreventionButton';

type Props = {
  gmbLocationId: number;
  isListedStore: boolean;
  gmbLocationSetting: GmbLocationSetting;
  location: GmbLocation;
  locationAttributes: Attribute[];
};

const GmbLocationForm: React.FC<Props> = (props) => {
  return (
    <>
      {!props.isListedStore && (
        <TamperPreventionButton
          gmbLocationId={props.gmbLocationId}
          isTamperPrevention={props.gmbLocationSetting.tamperPrevention}
        />
      )}
      <Form
        gmbLocationId={props.gmbLocationId}
        isListedStore={props.isListedStore}
        location={props.location}
        locationAttributes={props.locationAttributes}
        handleUpdate={RequestGmbLocation.update}
        handleUpdateLocationAttributes={RequestGmbLocation.updateLocationAttributes}
        handleGmbLocationKeyword={RequestGmbLocation.keywords}
      />
    </>
  );
};

export default memo(GmbLocationForm);
