import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReviewCard from '../../general/gmb-location-reviews/ReviewCard';
import ReviewModal from '../../general/gmb-location-reviews/ReviewModal';
import { Alert } from '../../../service/alert';

import { GmbLocationReview } from '../../../models/gmb-location-review';
import { UpdateReplyParams } from '../../../requests/partner/gmb-location-reviews';

import { sliceByNumber } from '../../../utils/app-array';
import { isSP } from '../../../utils/app-util';
import { CancelTokenSource } from 'axios';
import { useKeywords } from '../../../hooks/useKeywords';

type Props = {
  gmbLocationReviewsListPath?: string;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
  fetchLatestNoReplyReviews: () => Promise<GmbLocationReview[]>;
  handleUpdateReply: (id: number, params: UpdateReplyParams) => Promise<void>;
};

const NoReplyReview: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLocationReviews, setSelectedLocationReviews] = useState<GmbLocationReview | null>(
    null
  );
  const [gmbLocationReviews, setGmbLocationReviews] = useState<GmbLocationReview[]>([]);

  const refresh = async (): Promise<void> => {
    const v = await props.fetchLatestNoReplyReviews();
    setGmbLocationReviews(v);
  };

  useEffect(() => {
    refresh();
  }, []);

  const { keywords, clearKeywords } = useKeywords({
    gmbLocationId: selectedLocationReviews?.gmbLocationId,
    handleGmbLocationKeyword: props.handleGmbLocationKeyword,
  });

  const handleUpdateReply = async (id: number, params: UpdateReplyParams): Promise<void> => {
    try {
      await props.handleUpdateReply(id, params);
      setShowModal(false);
      Alert.success('返信しました');
      refresh();
    } catch {
      Alert.danger('返信に失敗しました');
    }
  };

  return (
    <>
      {gmbLocationReviews.length <= 0 ? (
        <h5>未返信の口コミはありません🎉</h5>
      ) : (
        <>
          {sliceByNumber(gmbLocationReviews, isSP() ? 2 : 3).map(
            (splitGmbLocationReviews, index) => (
              <Row key={`gmb_location_view_parent_${index}`}>
                {splitGmbLocationReviews.map((gmbLocationReview, childIndex) => {
                  return (
                    <Col key={`gmb_location_view_card_${childIndex}`}>
                      <ReviewCard
                        isLoading={false}
                        gmbLocationReview={gmbLocationReview}
                        handleModal={() => {
                          setShowModal(true);
                          setSelectedLocationReviews(gmbLocationReview);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            )
          )}
          {selectedLocationReviews && (
            <ReviewModal
              isShow={showModal}
              gmbLocationReview={selectedLocationReviews}
              handleClose={() => {
                clearKeywords();
                setShowModal(false);
                setSelectedLocationReviews(null);
              }}
              handleUpdate={handleUpdateReply}
              keywords={keywords}
            />
          )}
        </>
      )}
      {props.gmbLocationReviewsListPath && (
        <div className="d-flex justify-content-end">
          <a href={props.gmbLocationReviewsListPath} type="button" className="btn btn-link">
            口コミ一覧
          </a>
        </div>
      )}
    </>
  );
};

export default memo(NoReplyReview);
