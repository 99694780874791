import { GmbLocation } from 'models/gmb-location';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {
  GmbLocalBasicEvaluation,
  GmbLocalEvaluationBasicValue,
  GmbLocalEvaluationMediaImageValue,
  GmbLocalEvaluationPostValue,
  GmbLocalEvaluationReviewValue,
  GmbLocalEvaluationType,
  GmbLocalMediaImageEvaluation,
  GmbLocalPostEvaluation,
  GmbLocalReviewEvaluation,
  GmbLocalTotalAverageScoreEvaluation,
} from '../models/gmb-local-evaluation';

import { RequestGmbLocalEvaluation as CompanyRequestGmbLocalEvaluation } from '../requests/company/gmb-local-evaluation';
import { RequestGmbLocalEvaluation as PartnerRequestGmbLocalEvaluation } from '../requests/partner/gmb-local-evaluation';

import { RequestGmbLocation as CompanyRequestGmbLocation } from '../requests/company/gmb-locations';
import { RequestGmbLocation as PartnerRequestGmbLocation } from '../requests/partner/gmb-locations';

type Props = {
  isPartner: boolean;
  specifiedGmbLocationId: number;
  gmbOperationalEvaluationPath: string;
};

type ReturnType = {
  gmbLocation: GmbLocation | undefined;
  totalAverageScoreEvaluationData: GmbLocalTotalAverageScoreEvaluation | undefined; // 総合評価データ
  postEvaluationData: GmbLocalPostEvaluation | undefined; // 投稿評価データ
  mediaImageEvaluationData: GmbLocalMediaImageEvaluation | undefined; // 写真評価データ
  reviewEvaluationData: GmbLocalReviewEvaluation | undefined; // 口コミ評価データ
  basicEvaluationData: GmbLocalBasicEvaluation | undefined; // 基本情報データ
  evaluationType: GmbLocalEvaluationType;
  isLoading: boolean;
  handleSelectEvaluationType: (key: GmbLocalEvaluationType) => void;
  handleSetGmbLocation: (v: GmbLocation) => void;
  fetchEvaluationData: () => Promise<void>;
};

export const useFetchGmbLocalEvaluation = (props: Props): ReturnType => {
  const RequestGmbLocation = props.isPartner
    ? PartnerRequestGmbLocation
    : CompanyRequestGmbLocation;

  const RequestGmbLocalEvaluation = props.isPartner
    ? PartnerRequestGmbLocalEvaluation
    : CompanyRequestGmbLocalEvaluation;

  const { specifiedGmbLocationId, gmbOperationalEvaluationPath } = props;

  const qs = queryString.parse(location.search);

  const evaluationParam = qs?.evaluationType
    ? (qs?.evaluationType as GmbLocalEvaluationType)
    : 'POST';

  const [evaluationType, setEvaluationType] = useState(evaluationParam);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [totalAverageScoreEvaluationData, setTotalAverageScoreEvaluationData] = useState<
    GmbLocalTotalAverageScoreEvaluation | undefined
  >(undefined);

  const [postEvaluationData, setPostEvaluationData] = useState<GmbLocalPostEvaluation | undefined>(
    undefined
  );
  const [mediaImageEvaluationData, setMediaImageEvaluationData] = useState<
    GmbLocalMediaImageEvaluation | undefined
  >(undefined);
  const [reviewEvaluationData, setReviewEvaluationData] = useState<
    GmbLocalReviewEvaluation | undefined
  >(undefined);
  const [basicEvaluationData, setBasicEvaluationData] = useState<
    GmbLocalBasicEvaluation | undefined
  >(undefined);
  const [gmbLocation, setGmbLocation] = useState<GmbLocation | undefined>(undefined);
  const handleSetGmbLocation = (v: GmbLocation): void => {
    setGmbLocation(v);
  };

  const fetchEvaluationData = async () => {
    setIsLoading(true);

    if (evaluationType === GmbLocalEvaluationPostValue) {
      const res = await RequestGmbLocalEvaluation.postEvaluation(specifiedGmbLocationId);
      setPostEvaluationData(res);
    } else if (evaluationType === GmbLocalEvaluationMediaImageValue) {
      const res = await RequestGmbLocalEvaluation.mediaImageEvaluation(specifiedGmbLocationId);
      setMediaImageEvaluationData(res);
    } else if (evaluationType === GmbLocalEvaluationReviewValue) {
      const res = await RequestGmbLocalEvaluation.reviewEvaluation(specifiedGmbLocationId);
      setReviewEvaluationData(res);
    } else if (evaluationType === GmbLocalEvaluationBasicValue) {
      const res = await RequestGmbLocalEvaluation.basicEvaluation(specifiedGmbLocationId);
      setBasicEvaluationData(res);
    }

    const res = await RequestGmbLocation.show(specifiedGmbLocationId);
    setGmbLocation(res);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchEvaluationData();
  }, [evaluationType, specifiedGmbLocationId]);

  // 総合評価データを取得
  useEffect(() => {
    const fetchComprehensiveEvaluation = async () => {
      const res = await RequestGmbLocalEvaluation.totalAverageScoreEvaluation(
        specifiedGmbLocationId
      );
      setTotalAverageScoreEvaluationData(res);
    };

    fetchComprehensiveEvaluation();
  }, []);

  const history = window.history;

  const handleSelectEvaluationType = (
    key: GmbLocalEvaluationType,
    actionType?: 'href' | 'push'
  ): void => {
    const evaluationTypeParams = `?evaluationType=${key}`;
    if (actionType == 'href') {
      location.href = `${gmbOperationalEvaluationPath}${evaluationTypeParams}`;
    } else {
      history.pushState(null, '', `${gmbOperationalEvaluationPath}${evaluationTypeParams}`);
      setEvaluationType(key);
    }
  };

  return {
    gmbLocation,
    totalAverageScoreEvaluationData,
    postEvaluationData,
    mediaImageEvaluationData,
    reviewEvaluationData,
    basicEvaluationData,
    evaluationType,
    isLoading,
    handleSelectEvaluationType,
    handleSetGmbLocation,
    fetchEvaluationData,
  };
};
