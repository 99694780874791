import { useState } from 'react';
import queryString from 'query-string';

import { SelectedItems } from '../components/general/gmb-local-posts/SearchBox';

import {
  GmbLocalBulkPost,
  LocalPostTopicType,
  LocalPostTopicTypeJp,
} from '../models/gmb-local-bulk-post';
import { Pagination as PaginationModel, initializePagination } from '../models/pagination';

import { PagenationApiResponse } from '../requests/client';
import { ListGmbLocalBulkPostParams as PartnerListGmbLocalBulkPostParams } from '../requests/partner/gmb-local-bulk-post';
import { ListGmbLocalBulkPostParams as CompanyListGmbLocalBulkPostParams } from '../requests/company/gmb-local-bulk-post';

export type SearchParams = PartnerListGmbLocalBulkPostParams | CompanyListGmbLocalBulkPostParams;

type Props = {
  specifiedCompanyId?: number;
  requestLocalBulkPosts: (
    page: number,
    params?: SearchParams
  ) => Promise<PagenationApiResponse<GmbLocalBulkPost[], PaginationModel>>;
};

type Queries = {
  currentPage: number;
  topicType: LocalPostTopicType;
  companyId?: number;
};

type usePeriodsData = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    topicType: LocalPostTopicType;
    companyId?: number;
  };
  pagination: PaginationModel;
  gmbLocalBulkPosts?: GmbLocalBulkPost[];
  refresh(p: number, params?: SearchParams): Promise<void>;
  handleSearch(selectedItems?: SelectedItems): Promise<void>;
};

export const useLocalBulkPostSearch = (props: Props): usePeriodsData => {
  const { specifiedCompanyId, requestLocalBulkPosts } = props;

  const qs = queryString.parse(location.search);
  const currentPage = qs?.page ? parseInt(qs.page as string) : 1;
  const topicType = qs?.topicType ? (qs.topicType as LocalPostTopicType) : 'STANDARD';
  const companyId = specifiedCompanyId
    ? specifiedCompanyId
    : qs?.companyId
    ? parseInt(qs.companyId as string)
    : undefined;

  const vlidTopicType = Object.entries(LocalPostTopicTypeJp).some(
    (value: [key: LocalPostTopicType, jp: string]) => {
      const [key, _] = value;
      return key === topicType;
    }
  );

  const [queries, setQueries] = useState<Queries>({
    currentPage: currentPage,
    topicType: vlidTopicType ? topicType : 'STANDARD',
    companyId: companyId,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationModel>(initializePagination);
  const [gmbLocalBulkPosts, setGmbLocalBulkPosts] = useState<GmbLocalBulkPost[] | undefined>(
    undefined
  );

  const refresh = async (p: number, params?: SearchParams): Promise<void> => {
    setIsLoading(true);
    const v = await requestLocalBulkPosts(p, params);
    setGmbLocalBulkPosts(v.data);
    setPagination(v.pagination);
    setIsLoading(false);
  };

  const handleSearch = async (selectedItems?: SelectedItems): Promise<void> => {
    setQueries({
      currentPage: currentPage,
      companyId: selectedItems?.companyId,
      topicType: selectedItems?.selectedTopicType || topicType,
    });
  };

  return {
    isLoading,
    queries,
    pagination,
    gmbLocalBulkPosts,
    refresh,
    handleSearch,
  };
};
