import React, { memo, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { OpenInfo, OpenInfoStatusJa, OpenInfoOpen } from '../../../../models/gmb-location';
import OpenInfoStatusModal from '../form-modal/OpenInfoStatusModal';

type Props = {
  openInfo: OpenInfo | null;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const ProfileRaw: React.FC<Props> = (props) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<OpenInfo | null>(props.openInfo);

  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    await props.handleUpdate(params);
    if (params.openInfo) {
      setOpenInfo(params.openInfo);
    }
    setIsShow(!isShow);
  };

  if (!openInfo?.status) {
    return <></>;
  }

  return (
    <>
      <Button
        variant={openInfo.status === OpenInfoOpen ? 'success' : 'secondary'}
        onClick={handleModal}
      >
        {OpenInfoStatusJa[openInfo.status]}
      </Button>
      <OpenInfoStatusModal
        isShow={isShow}
        openInfo={openInfo}
        handleModal={handleModal}
        handleUpdate={handleSubmit}
      />
    </>
  );
};

export default memo(ProfileRaw);
