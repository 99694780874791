import { ApiClient } from './client';
import { AttributeGroup } from '../models/gmb-location/attribute-group';

type SearchParams = {
  categoryId?: string;
};

export class RequestGmbAttributeGroup {
  static all = async (params: SearchParams): Promise<AttributeGroup[]> => {
    const res = await ApiClient.get(`/api/gmb_attribute_groups/all`, { params });
    return res.data;
  };
}
