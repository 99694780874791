import React, { memo, useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import BootstrapAlert from 'react-bootstrap/Alert';
import ImageBox from '../../ImageBox';
import { ApiCancelToken, isApiCancel } from '../../../../requests/client';

import ImageUpload from '../../gmb-local-post-share/ImageUpload';
import ActionButtonSelect, { Option } from './ActionButtonSelect';
import BaseModal from '../../BaseModal';

import { SaveLocalPostParams } from '../../../../requests/partner/gmb-local-posts';

import { GmbLocation } from '../../../../models/gmb-location';
import { GmbLocalPost, CallToActionType } from '../../../../models/gmb-local-post';

import { validateUrl } from '../../../../utils/validate';
import { Alert } from '../../../../service/alert';

import { useUploadImage } from '../../../../hooks/useUploadImage';
import { CancelTokenSource } from 'axios';

type Props = {
  isShow: boolean;
  gmbLocationId: number;
  gmbLocalPost?: GmbLocalPost;
  handleModal: () => void;
  handleShowGmbLocation: (id: number, cancelToken?: CancelTokenSource) => Promise<GmbLocation>;
  handleCreate?: (params: SaveLocalPostParams) => Promise<void>;
  handleDelete?: () => Promise<void>;
  keywords?: string[];
};

const StandardModal: React.FC<Props> = (props) => {
  const {
    isShow,
    gmbLocationId,
    gmbLocalPost,
    handleModal,
    handleShowGmbLocation,
    handleCreate,
    handleDelete,
    keywords,
  } = props;

  const [gmbLocation, setGmbLocation] = useState<GmbLocation | null>(null);
  const [summary, setSummary] = useState<string>(gmbLocalPost?.localPost?.summary || '');
  const [callToActionType, setCallToActionType] = useState<CallToActionType>(
    gmbLocalPost?.localPost?.callToAction?.actionType || 'ACTION_TYPE_UNSPECIFIED'
  );
  const [callToActionUrl, setCallToActioUrl] = useState<string>(
    gmbLocalPost?.localPost?.callToAction?.url || ''
  );
  const { publicUrl, selectingPostImage, handleAttachImageFile } = useUploadImage();

  const handleChangeInput = (value: string) => {
    setSummary(value);
  };

  const fetchGmbLocation = useCallback(
    async (cancelToken?: CancelTokenSource) => {
      try {
        const v = await handleShowGmbLocation(gmbLocationId, cancelToken);
        setGmbLocation(v);
      } catch (e) {
        if (isApiCancel(e)) return;
      }
    },
    [gmbLocationId]
  );

  useEffect(() => {
    const cancelToken = ApiCancelToken.source();
    fetchGmbLocation(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [fetchGmbLocation]);

  const handleChangeActionButtonType = (option: Option) => {
    setCallToActionType(option.value);
  };

  const handleChangeActionUrl = (val: string) => {
    setCallToActioUrl(val);
  };

  const resetForm = () => {
    setSummary('');
    setCallToActionType('ACTION_TYPE_UNSPECIFIED');
    setCallToActioUrl('');
  };

  const handleClose = () => {
    resetForm();
    handleModal();
  };

  const disabled = handleCreate === undefined;

  if (gmbLocation === null) {
    return <></>;
  }

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        buttonText="投稿"
        alert={true}
        handleClose={handleClose}
        handleUpdate={
          disabled
            ? undefined
            : async (): Promise<void> => {
                if (handleCreate === undefined) {
                  return;
                }
                if (summary == '') {
                  throw Alert.danger('投稿内容は必須です');
                }
                if (
                  callToActionType != 'ACTION_TYPE_UNSPECIFIED' &&
                  callToActionType != 'CALL' &&
                  !validateUrl(callToActionUrl)
                ) {
                  throw Alert.danger('ボタンのリンクが正しくありません');
                }
                try {
                  await handleCreate({
                    gmbLocationId,
                    params: {
                      summary,
                      languageCode: 'JP',
                      topicType: 'STANDARD',
                      callToAction:
                        callToActionType == 'ACTION_TYPE_UNSPECIFIED'
                          ? undefined
                          : {
                              actionType: callToActionType,
                              url: callToActionType == 'CALL' ? '' : callToActionUrl,
                            },
                      media:
                        publicUrl !== null
                          ? [
                              {
                                mediaFormat: 'PHOTO',
                                sourceUrl: publicUrl,
                              },
                            ]
                          : undefined,
                    },
                  });
                  Alert.success('投稿しました');
                  handleModal();
                } catch {
                  Alert.danger('投稿に失敗しました');
                }
              }
        }
        handleDelete={handleDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`最新情報投稿の追加(${gmbLocation.locationName})`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectingPostImage == null && gmbLocalPost?.localPost.media[0] && (
            <ImageBox imageUrl={gmbLocalPost.localPost.media[0].googleUrl} />
          )}
          <ImageUpload
            selectingPostImage={selectingPostImage}
            handleAttachImageFile={handleAttachImageFile}
          />
          <Form.Group>
            <Form.Label>投稿内容</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="投稿内容を入力してください"
              rows={10}
              disabled={disabled}
              value={summary}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInput(e.target.value);
              }}
            />
          </Form.Group>
          {(keywords?.length || 0) > 0 && (
            <Form.Group>
              <BootstrapAlert variant={'primary'}>
                設定キーワード: {keywords?.map((keyword) => `「${keyword}」`).join(',')}
              </BootstrapAlert>
            </Form.Group>
          )}
          <Form.Group>
            <ActionButtonSelect
              disabled={disabled}
              phoneNumber={gmbLocation.primaryPhone}
              selectedCallToActionType={callToActionType}
              callToActionUrl={callToActionUrl}
              handleChangeActionUrl={handleChangeActionUrl}
              handleSelected={handleChangeActionButtonType}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(StandardModal);
