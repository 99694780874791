import React, { memo, useEffect } from 'react';

import MediaImageList from '../../../general/gmb-location-media-images/MediaImageList';
import SearchBox from '../../../general/gmb-location-media-images/SearchBox';

import { RequestGmbLocationMediaImage } from '../../../../requests/company/gmb-location-media-images';

import { useGmbLocationMediaImage } from '../../../../hooks/useGmbLocationMedisSearch';

type Props = {
  specifiedGmbLocationId?: number;
  paginationPath: string;
};

const ListMediaImage: React.FC<Props> = (props) => {
  const { specifiedGmbLocationId, paginationPath } = props;
  const { isLoading, queries, pagination, mediaItems, refresh, handleSearch } =
    useGmbLocationMediaImage({
      specifiedGmbLocationId,
      requestLocationMediaImages: RequestGmbLocationMediaImage.list,
    });

  useEffect(() => {
    refresh(queries.currentPage, {
      mediaCategory: queries.mediaCategory,
      gmbLocationId: queries.gmbLocationId,
    });
  }, [queries.currentPage, queries.mediaCategory, queries.gmbLocationId]);

  if (mediaItems === undefined) {
    return <></>;
  }

  return (
    <>
      <SearchBox
        selectedItems={{
          gmbLocationId: queries.gmbLocationId,
          selectedMediaCategory: queries.mediaCategory,
        }}
        refreshLocationMedia={refresh}
        handleCreateLocationMedia={RequestGmbLocationMediaImage.create}
        mediaItems={mediaItems}
        searchable={false}
        handleAfterSearch={handleSearch}
      />
      <MediaImageList
        queries={queries}
        isLoading={isLoading}
        pagination={pagination}
        searchable={true}
        mediaItems={mediaItems}
        paginationPath={paginationPath}
        handleSearch={refresh}
        handeleDeleteMediaImage={RequestGmbLocationMediaImage.delete}
      />
    </>
  );
};

export default memo(ListMediaImage);
