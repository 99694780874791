import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AttachmentModel } from '../../models/share/attachment-model';

const DropZone = styled.button<{ dragging: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px dotted #3d91f7;
  border-radius: 5px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  background-color: white;
  :focus {
    outline: 0;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

export type FileuploadAreaProps = {
  fileId: string;
  attachmentFile?: AttachmentModel | null;
  handleAttachFile: (file: AttachmentModel) => void;
  children?: React.ReactChild[] | React.ReactChild | string;
  multiple?: boolean;
  fileType?: 'text' | 'image';
};

export class AttachFileFailureError {
  public code = 'AttachFileFailureError';
}

const FileuploadArea: React.FC<FileuploadAreaProps> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  const setAttachmentFiles = (files: FileList): void => {
    props.handleAttachFile({
      kind: 'selecting',
      id: '0',
      fileName: files[0].name,
      url: URL.createObjectURL(files[0]),
      file: files[0],
    });
  };

  const handleDragOver = (event: DragEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    setDragging(true);

    if (event.dataTransfer !== null) {
      event.dataTransfer.dropEffect = 'copy';
    }
  };

  const handleDragLeave = (): void => {
    setDragging(false);
  };

  const handleDrop = (event: DragEvent): void => {
    event.stopPropagation();
    event.preventDefault();
    setDragging(false);

    if (event.dataTransfer !== null) {
      const files = event.dataTransfer.files;

      if (files !== null) {
        setAttachmentFiles(files);
      }
    }
  };

  useEffect((): (() => void) | void => {
    const dropZone = document.getElementById(props.fileId);
    if (dropZone !== null) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
      return (): void => {
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('drop', handleDrop);
      };
    }
    return;
  }, [props.attachmentFile]);

  const handleClick = (): void => {
    // if (!props.multiple && props.attachmentFile != null) return;
    if (inputRef.current) inputRef.current.click();
  };

  const handleSingleFileSelect = (files: FileList): void => {
    if (files !== null) {
      setAttachmentFiles(files);
    }
  };

  const handleSelectFile = (): void => {
    if (inputRef.current) {
      handleSingleFileSelect(inputRef.current.files || new FileList());
    }
  };
  return (
    <>
      <DropZone
        id={props.fileId}
        dragging={dragging}
        onChange={handleSelectFile}
        onClick={handleClick}
      >
        <form>
          <HiddenInput type="file" ref={inputRef} multiple />
        </form>
        {props.children && props.children}
      </DropZone>
    </>
  );
};

export default React.memo(FileuploadArea);
