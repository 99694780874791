import React, { memo } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

export type AppButtonProps = {
  children?: React.ReactElement;
} & ButtonProps;

const AppButton: React.FC<AppButtonProps> = (props) => {
  const { children, variant, onClick, disabled, size } = props;
  return (
    <Button variant={variant} onClick={onClick} disabled={disabled} size={size}>
      {children}
    </Button>
  );
};

export default memo(AppButton);
