export type HoursType = {
  displayName: string;
  jaDisplayName: string;
  hoursTypeId: string;
};

export const getHoursType = (hoursTypes: HoursType[], hoursTypeId: string): string => {
  const result = hoursTypes.find((hoursType: HoursType) => hoursType.hoursTypeId == hoursTypeId);
  return result ? result.jaDisplayName : '';
};
