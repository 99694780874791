import React, { memo } from 'react';
import EvaluationCell from './cell/EvaluationCell';
import AchievementCell from './cell/AchievementCell';

type Props = {
  evaluation: number | undefined;
  achievement: string | undefined;
};

const EvaluationConversionCells: React.FC<Props> = (props: Props) => {
  const { evaluation, achievement } = props;

  return (
    <>
      <EvaluationCell evaluation={evaluation} rowSpan={1} />
      <AchievementCell evaluation={evaluation} achievement={achievement} />
    </>
  );
};

export default memo(EvaluationConversionCells);
