import React, { memo } from 'react';
import { GmbLocalReviewEvaluation } from '../../../../models/gmb-local-evaluation';
import EvaluationConversionCells from '../TableData/EvaluationConversionCells';

type Props = {
  data: GmbLocalReviewEvaluation | undefined;
  hasKeywordContent: boolean;
};

const ReviewEvaluationTableBody: React.FC<Props> = (props: Props) => {
  const { data, hasKeywordContent } = props;

  const reviewUuserKeywordContent = (data?: GmbLocalReviewEvaluation) => {
    if (hasKeywordContent) {
      return (
        <>
          <tr>
            <th
              className="text-left align-middle"
              rowSpan={data?.userKeywordContent ? data.userKeywordContent.details.length + 1 : 0}
            >
              口コミ内キーワード出現回数 （3カ月間かつ最新100件）
            </th>
          </tr>
          {data?.userKeywordContent?.details.map((item, index: number) => (
            <tr key={index}>
              <td className="text-left align-middle">{item.keyword}</td>
              <td className="text-center align-middle">{item.keywordRank}</td>
              <EvaluationConversionCells
                evaluation={item.evaluation}
                achievement={item.achievement}
              />
            </tr>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const reviewReplyKeywordContent = (data?: GmbLocalReviewEvaluation) => {
    if (data?.replyKeywordContent?.details?.length || 0 > 0) {
      return (
        <>
          <tr>
            <th
              className="text-left align-middle"
              rowSpan={data?.replyKeywordContent ? data.replyKeywordContent.details.length + 1 : 0}
            >
              返信内キーワード出現回数 （3カ月間かつ最新100件）
            </th>
          </tr>
          {data?.replyKeywordContent?.details.map((item, index: number) => (
            <tr key={index}>
              <td className="text-left align-middle">{item.keyword}</td>
              <td className="text-center align-middle">{item.keywordRank}</td>
              <EvaluationConversionCells
                evaluation={item.evaluation}
                achievement={item.achievement}
              />
            </tr>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {reviewUuserKeywordContent(data)}
      {reviewReplyKeywordContent(data)}

      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          口コミ返信率（3カ月間かつ最新100件）
        </th>
        <EvaluationConversionCells
          evaluation={data?.replyRate.evaluation}
          achievement={data?.replyRate.achievement}
        />
      </tr>

      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          口コミの平均返信期間（3カ月間かつ最新100件）
        </th>
        <EvaluationConversionCells
          evaluation={data?.avgReplyPeriod.evaluation}
          achievement={data?.avgReplyPeriod.achievement}
        />
      </tr>

      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          口コミ平均点（3カ月間かつ最新100件）
        </th>
        <EvaluationConversionCells
          evaluation={data?.avgReviewScore.evaluation}
          achievement={data?.avgReviewScore.achievement}
        />
      </tr>

      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          口コミ件数（3カ月間かつ最新100件）
        </th>
        <EvaluationConversionCells
          evaluation={data?.reviewNumber.evaluation}
          achievement={data?.reviewNumber.achievement}
        />
      </tr>
    </>
  );
};

export default memo(ReviewEvaluationTableBody);
