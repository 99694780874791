import React from 'react';
import Notification from 'rc-notification';
import { NotificationInstance } from 'rc-notification/lib/Notification';
import BootstrapAlert from 'react-bootstrap/Alert';

let notification: NotificationInstance;
Notification.newInstance(
  {
    maxCount: 100,
  },
  (n: NotificationInstance) => {
    notification = n;
  }
);

class Alert {
  static success = (message: string): void => {
    notification.notice({
      duration: 3,
      content: (
        <BootstrapAlert variant="success">
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </BootstrapAlert>
      ),
    });
  };

  static danger = (message: string): void => {
    notification.notice({
      duration: 3,
      content: (
        <BootstrapAlert variant="danger">
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </BootstrapAlert>
      ),
    });
  };

  static info = (message: string): void => {
    notification.notice({
      duration: 3,
      content: (
        <BootstrapAlert variant="info">
          {' '}
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </BootstrapAlert>
      ),
    });
  };
}

export { Alert };
