import {
  GmbLocalEvaluationType,
  GmbLocalTotalAverageScoreEvaluation,
} from 'models/gmb-local-evaluation';
import React, { memo } from 'react';
import Nav from 'react-bootstrap/Nav';
import EvaluationLabel from './Tab/EvaluationLabel';

type Props = {
  variant?: 'pills' | 'tabs';
  actionType?: 'href' | 'push';
  fontSize?: string;
  align?: 'center' | 'left' | 'right';
  evaluationType: GmbLocalEvaluationType;
  columnWidth?: string;
  totalAverageScoreEvaluationData?: GmbLocalTotalAverageScoreEvaluation;
  handleSelectEvaluationType: (key: GmbLocalEvaluationType, actionType?: 'href' | 'push') => void;
};

const OperationalEvaluation: React.FC<Props> = (props) => {
  const {
    variant,
    fontSize,
    align,
    actionType,
    columnWidth,
    evaluationType,
    totalAverageScoreEvaluationData,
    handleSelectEvaluationType,
  } = props;

  return (
    <Nav
      variant={variant}
      className="mb-3"
      style={{
        fontSize: fontSize ? fontSize : '14px',
        justifyContent: align,
      }}
      defaultActiveKey={evaluationType}
      onSelect={(key: GmbLocalEvaluationType) => {
        handleSelectEvaluationType(key, actionType);
      }}
    >
      {totalAverageScoreEvaluationData &&
        totalAverageScoreEvaluationData.map((data, id) => (
          <Nav.Item
            style={{
              width: columnWidth,
              textAlign: 'center',
            }}
            key={id}
          >
            <Nav.Link eventKey={data.type}>
              {data.name}
              <EvaluationLabel evaluation={data.evaluation} />
            </Nav.Link>
          </Nav.Item>
        ))}
    </Nav>
  );
};

export default memo(OperationalEvaluation);
