import React, { memo, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import { Categories, Category } from '../../../../models/gmb-location';

import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { RequestGmbCategory } from '../../../../requests/gmb-categories';

import BaseModal from '../../BaseModal';

import Form from 'react-bootstrap/Form';

type Props = {
  isShow: boolean;
  label: string;
  categories: Categories;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

type Option = {
  label: string;
  value: string;
};

const PrimaryCategoryModal: React.FC<Props> = (props) => {
  const category = props.categories.primaryCategory;
  const [displayName, setDisplayName] = useState<string>(category.displayName);
  const [categoryId, setCategoryId] = useState<string>(category.name);
  const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [presentDisplayName] = useState<string>(category.displayName);

  const handleClose = () => {
    setDisplayName(category.displayName);
    setCategoryId(category.name);
    props.handleModal();
  };

  const searchCategories = async (value: string): Promise<Option[]> => {
    const result = await RequestGmbCategory.search({ displayName: value });
    return result.map((v: Category) => {
      return {
        label: v.displayName,
        value: v.name,
      };
    });
  };

  const loadOptions = async (inputValue: string): Promise<Option[]> => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    return await searchCategories(inputValue);
  };

  useEffect(() => {
    searchCategories(category.displayName).then((result) => {
      setDefaultOptions(result);
    });
  }, [category.displayName]);
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          console.log(categoryId);
          console.log(categoryId);

          await props.handleUpdate({
            categories: {
              primaryCategory: {
                name: categoryId,
                displayName,
              },
              additionalCategories: props.categories.additionalCategories,
            },
          });
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>現在のカテゴリー</Form.Label>
            <Form.Control type="text" disabled={true} value={presentDisplayName} />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              変更するカテゴリー<p className="text-danger">※1つのみ選択可</p>
            </Form.Label>
            <AsyncSelect
              defaultValue={{
                label: '',
                value: '',
              }}
              defaultOptions={defaultOptions}
              onChange={(option: Option) => {
                setDisplayName(option.label);
                setCategoryId(option.value);
              }}
              loadOptions={loadOptions}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(PrimaryCategoryModal);
