import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { MoreHours } from '../../../../models/gmb-location';
import { Period, DayOfTheWeek } from '../../../../models/gmb-location/period';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import PeriodsTable from '../partial/PeriodsTable';

import { usePeriods } from '../../../../hooks/usePeriods';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  moreHours: MoreHours[];
  hoursTypeId: string;
  moreHoursPeriods: Period[];
  templateTitle?: string;
  handleModal: () => void;
  handleUpdateMorehours: (mourhours: MoreHours[]) => void;
  handleUpdate: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
};

const MoreHoursModal: React.FC<Props> = (props) => {
  const periodsStates = usePeriods({
    periods: props.moreHoursPeriods,
  });

  const { allWeekHoursPeriods, handleClose } = periodsStates;
  const handleModalClose = () => {
    handleClose();
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleModalClose}
        handleUpdate={async () => {
          const updateHoursType: MoreHours = {
            hoursTypeId: props.hoursTypeId,
            periods: Object.entries(allWeekHoursPeriods)
              .map((value: [key: DayOfTheWeek, periods: Period[]]) => {
                const [_key, periods] = value;
                return periods;
              })
              .flat(),
          };
          const updateHours = props.moreHours.map((v: MoreHours) => {
            return v.hoursTypeId == props.hoursTypeId ? updateHoursType : v;
          });
          await props.handleUpdate(
            {
              moreHours: updateHours,
            },
            props.templateTitle
          );
          props.handleModal();
          props.handleUpdateMorehours(updateHours);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PeriodsTable {...periodsStates} readonly={false} holidayVisible={true} />
          </Container>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(MoreHoursModal);
