import React, { memo, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapTable from 'react-bootstrap/Table';
import { useFetchGmbLocalEvaluation } from '../../../../hooks/useFetchGmbLocalEvaluation';
import {
  GmbLocalEvaluationBasicValue,
  GmbLocalEvaluationMediaImageValue,
  GmbLocalEvaluationPostValue,
  GmbLocalEvaluationReviewValue,
} from '../../../../models/gmb-local-evaluation';
import BasicEvaluationTableBody from '../../../general/gmb-local-evaluation/TableBody/BasicEvaluationTableBody';
import MediaImageEvaluationTableBody from '../../../general/gmb-local-evaluation/TableBody/MediaImageEvaluationTableBody';
import PostEvaluationTableBody from '../../../general/gmb-local-evaluation/TableBody/PostEvaluationTableBody';
import ReviewEvaluationTableBody from '../../../general/gmb-local-evaluation/TableBody/ReviewEvaluationTableBody';

import CreateButton from '../../../general/gmb-local-posts/CreateButton';
import ProfileRaw from '../../../general/gmb-locations/raws/ProfileRaw';

import { LocalPostStndardValue } from '../../../../models/gmb-local-post';

import {
  RequestGmbLocalPost,
  SaveLocalPostParams,
} from '../../../../requests/company/gmb-local-posts';

import {
  RequestGmbLocation as CompanyRequestGmbLocation,
  UpdateGmbLocationParams,
} from '../../../../requests/company/gmb-locations';

import { RequestGmbLocation as PartnerRequestGmbLocation } from '../../../../requests/partner/gmb-locations';

import { Alert } from '../../../../service/alert';
import { useKeywords } from '../../../../hooks/useKeywords';
import {
  gmbLocalEvaluationPostExplain,
  gmbLocalEvaluationBasicExplain,
  gmbLocalEvaluationMediaImageExplain,
  gmbLocalEvaluationReviewExplain,
  ExplainContent,
} from '../../../general/gmb-local-evaluation/operation-explain';

import EvaluationNav from '../../../general/gmb-local-evaluation/EvaluationNav';

type Props = {
  isPartner: boolean;
  specifiedGmbLocationId: number;
  specifiedGmbLocationMyplId: number;
  gmbLocalPostsPath: string;
  gmbLocalPostsMyplIdPath: string;
  gmbLocalPostsListMyplIdPath: string;
  gmbLocationMediaImagesPath: string;
  gmbLocationReviewsPath: string;
  gmbOperationalEvaluationPath: string;
  isListedStore: boolean;
};

const OperationalEvaluation: React.FC<Props> = (props) => {
  const RequestGmbLocation = props.isPartner
    ? PartnerRequestGmbLocation
    : CompanyRequestGmbLocation;

  const {
    specifiedGmbLocationId,
    specifiedGmbLocationMyplId,
    gmbLocalPostsPath,
    gmbLocalPostsMyplIdPath,
    gmbLocalPostsListMyplIdPath,
    gmbLocationMediaImagesPath,
    gmbLocationReviewsPath,
    gmbOperationalEvaluationPath,
  } = props;

  const {
    totalAverageScoreEvaluationData,
    gmbLocation,
    postEvaluationData,
    mediaImageEvaluationData,
    reviewEvaluationData,
    basicEvaluationData,
    evaluationType,
    isLoading,
    handleSelectEvaluationType,
    handleSetGmbLocation,
    fetchEvaluationData,
  } = useFetchGmbLocalEvaluation({
    isPartner: props.isPartner,
    specifiedGmbLocationId,
    gmbOperationalEvaluationPath,
  });

  const { keywords } = useKeywords({
    gmbLocationId: specifiedGmbLocationId,
    handleGmbLocationKeyword: RequestGmbLocation.keywords,
  });

  const handleUpdate = async (params: UpdateGmbLocationParams): Promise<void> => {
    try {
      const data = await RequestGmbLocation.update(specifiedGmbLocationId, params);
      await fetchEvaluationData();
      handleSetGmbLocation(data);
      Alert.success('保存しました');
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };

  const createExplainNodes = (contents: ExplainContent[], type: string) => {
    return (
      <div className="w-auto mt-5 mb-3 p-3 border rounded bg-light">
        {contents.map((explain, pidx) => {
          return (
            <div key={`${type}_title_${pidx}`}>
              {explain.title && <p className="mb-1 font-weight-bold">{explain.title}</p>}
              {explain.bodies.map((body, index) => {
                return (
                  <p
                    className="text-dark"
                    key={`${type}_${pidx}_${index}`}
                    dangerouslySetInnerHTML={{
                      __html: body,
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const createGmbLocalPost = async (params: SaveLocalPostParams): Promise<void> => {
    await RequestGmbLocalPost.create(params);
    await fetchEvaluationData();
  };

  const hasKeywordContent = useCallback((): boolean => {
    switch (evaluationType) {
      case GmbLocalEvaluationPostValue:
        return (postEvaluationData?.keywordContent?.details?.length || 0) > 0;
      case GmbLocalEvaluationReviewValue:
        return (
          (reviewEvaluationData?.userKeywordContent?.details?.length || 0) > 0 ||
          (reviewEvaluationData?.replyKeywordContent?.details?.length || 0) > 0
        );
      case GmbLocalEvaluationBasicValue:
        return (basicEvaluationData?.keywordContent?.details?.length || 0) > 0;
      default:
        return false;
    }
  }, [reviewEvaluationData, postEvaluationData, basicEvaluationData, evaluationType]);

  return (
    <>
      <EvaluationNav
        variant="tabs"
        evaluationType={evaluationType}
        totalAverageScoreEvaluationData={totalAverageScoreEvaluationData}
        handleSelectEvaluationType={handleSelectEvaluationType}
      />
      {isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <>
          <BootstrapTable responsive bordered style={{ minWidth: '960px' }}>
            <thead className="text-center" style={{ backgroundColor: '#e9ecef' }}>
              <tr>
                <th colSpan={hasKeywordContent() ? 1 : 2} className="text-left">
                  項目
                </th>
                {hasKeywordContent() && (
                  <th colSpan={2} className="text-left">
                    最新のキーワード順位
                  </th>
                )}
                <th>評価</th>
                <th>運用実績</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {evaluationType === GmbLocalEvaluationPostValue && (
                <PostEvaluationTableBody
                  data={postEvaluationData}
                  hasKeywordContent={hasKeywordContent()}
                />
              )}
              {evaluationType === GmbLocalEvaluationMediaImageValue && (
                <MediaImageEvaluationTableBody data={mediaImageEvaluationData} />
              )}
              {evaluationType === GmbLocalEvaluationReviewValue && (
                <ReviewEvaluationTableBody
                  data={reviewEvaluationData}
                  hasKeywordContent={hasKeywordContent()}
                />
              )}
              {evaluationType === GmbLocalEvaluationBasicValue && (
                <BasicEvaluationTableBody
                  data={basicEvaluationData}
                  hasKeywordContent={hasKeywordContent()}
                />
              )}
            </tbody>
          </BootstrapTable>
          {evaluationType === GmbLocalEvaluationPostValue && (
            <>
              {!props.isPartner && (
                <div className="mt-3 mb-3">
                  {!specifiedGmbLocationMyplId && (
                    <>
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <CreateButton
                            gmbLocationId={specifiedGmbLocationId}
                            topicType={LocalPostStndardValue}
                            handleCreateLocalPost={createGmbLocalPost}
                            handleShowGmbLocation={RequestGmbLocation.show}
                            keywords={keywords}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <Button
                            variant="secondary"
                            onClick={() => {
                              window.open(gmbLocalPostsPath, '_blank');
                            }}
                          >
                            <i className="fas fa-list"></i> 投稿一覧を確認する
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {specifiedGmbLocationMyplId && (
                    <>
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <Button
                            variant="dark"
                            onClick={() => {
                              window.open(gmbLocalPostsMyplIdPath, '_blank');
                            }}
                          >
                            <i className="fas fa-plus-circle"></i> ニュースを作成する
                          </Button>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col md="auto">
                          <Button
                            variant="secondary"
                            onClick={() => {
                              window.open(gmbLocalPostsListMyplIdPath, '_blank');
                            }}
                          >
                            <i className="fas fa-list"></i> ニュース投稿一覧を見る
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}
              {createExplainNodes(gmbLocalEvaluationPostExplain, 'gmbLocalEvaluationPostExplain')}
            </>
          )}
          {evaluationType === GmbLocalEvaluationMediaImageValue && (
            <>
              {!props.isPartner && (
                <div className="mt-3 mb-3">
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.open(gmbLocationMediaImagesPath, '_blank');
                        }}
                      >
                        <i className="fas fa-list"></i> 写真一覧を確認する
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              {createExplainNodes(
                gmbLocalEvaluationMediaImageExplain,
                'gmbLocalEvaluationMediaImageExplain'
              )}
            </>
          )}
          {evaluationType === GmbLocalEvaluationReviewValue && (
            <>
              {!props.isPartner && (
                <div className="mt-3 mb-3">
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          window.open(gmbLocationReviewsPath, '_blank');
                        }}
                      >
                        <i className="fas fa-list"></i> 未返信一覧を確認する
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              {createExplainNodes(
                gmbLocalEvaluationReviewExplain,
                'gmbLocalEvaluationReviewExplain'
              )}
            </>
          )}
          {gmbLocation && evaluationType === GmbLocalEvaluationBasicValue && (
            <>
              <div className="mt-3 mb-3">
                <ProfileRaw
                  label={'ビジネス説明'}
                  profile={gmbLocation.profile}
                  handleUpdate={handleUpdate}
                  editable={!props.isPartner}
                  keywords={keywords}
                  isListedStore={props.isListedStore}
                />
              </div>
              {createExplainNodes(gmbLocalEvaluationBasicExplain, 'gmbLocalEvaluationBasicExplain')}
            </>
          )}
        </>
      )}
    </>
  );
};

export default memo(OperationalEvaluation);
