import React, { memo } from 'react';
import { evaluationConversion } from '../../../../../utils/gmb-local-evaluation';

type Props = {
  evaluation: number | undefined;
  rowSpan: number | undefined;
};

const EvaluationCell: React.FC<Props> = (props: Props) => {
  const { evaluation, rowSpan } = props;

  return (
    <>
      <td
        className={'align-middle evaluation-mark evaluation-color-' + evaluation}
        rowSpan={rowSpan}
      >
        {evaluationConversion(evaluation)}
      </td>
    </>
  );
};

export default memo(EvaluationCell);
