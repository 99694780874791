import React, { memo } from 'react';

import { GmbLocalPost } from '../../../../models/gmb-local-post';

import { RequestGmbLocation } from '../../../../requests/partner/gmb-locations';

import LatestLocalPosts from '../../../general/gmb-local-posts/LatestLocalPosts';

type Props = {
  localPostListPath: string;
  gmbLocationId: number;
};

const LatestLocalPostsView: React.FC<Props> = (props: Props) => {
  const refresh = async (): Promise<GmbLocalPost[]> => {
    return await RequestGmbLocation.latestLocalPosts(props.gmbLocationId);
  };

  return (
    <LatestLocalPosts
      localPostListPath={props.localPostListPath}
      fetchLatestLocalPosts={refresh}
      handleShowGmbLocation={RequestGmbLocation.show}
      handleGmbLocationKeyword={RequestGmbLocation.keywords}
    />
  );
};

export default memo(LatestLocalPostsView);
