import {
  CallToActionTypeOption,
  LocalPostTopicTypeJp,
  LocalPostEventValue,
  LocalPostOfferValue,
  eventStartDateTime,
  eventEndDateTime,
} from './gmb-local-post';

import type {
  LocalPost,
  AlertType,
  CallToActionType,
  CallToAction,
  LocalPostEvent,
  LocalPostOffer,
  MediaItem,
  LocalPostState,
  LocalPostTopicType,
} from './gmb-local-post';

export type {
  AlertType,
  CallToActionType,
  CallToAction,
  LocalPostEvent,
  LocalPostOffer,
  MediaItem,
  LocalPostState,
  LocalPostTopicType,
};

export {
  CallToActionTypeOption,
  LocalPostTopicTypeJp,
  LocalPostEventValue,
  LocalPostOfferValue,
  eventStartDateTime,
  eventEndDateTime,
};

export type GmbLocalBulkPost = {
  id: number;
  companyId: number;
  companyName: string;
  localPost: LocalPost;
};
