import { ApiClient } from './client';
import { Category } from '../models/gmb-location';

type SearchParams = {
  displayName: string;
};
export class RequestGmbCategory {
  static search = async (params: SearchParams): Promise<Category[]> => {
    const res = await ApiClient.get(`/api/gmb_categories/search?displayName=${params.displayName}`);
    return res.data;
  };
}
