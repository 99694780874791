import { ApiClient, PagenationApiResponse } from '../client';
import { MediaItem, MediaCategory } from '../../models/location-media';
import { Pagination } from '../../models/pagination';

export type ListGmbLocationMedisParams = {
  gmbLocationId?: number;
  mediaCategory?: MediaCategory;
};

export type SaveLocationMediaParams = {
  gmbLocationId: number;
  params: {
    locationAssociation: {
      category: MediaCategory;
    };
    mediaFormat: 'PHOTO' | 'VIDEO';
    sourceUrl: string;
  };
};

export class RequestGmbLocationMediaImage {
  static create = async (params?: SaveLocationMediaParams): Promise<void> => {
    const res = await ApiClient.post(`/partner/api/gmb_location_media_images`, params);
    return res.data;
  };

  static delete = async (id: number): Promise<void> => {
    const res = await ApiClient.delete(`/partner/api/gmb_location_media_images/${id}`);
    return res.data;
  };

  static list = async (
    page: number,
    params?: ListGmbLocationMedisParams
  ): Promise<PagenationApiResponse<MediaItem[], Pagination>> => {
    const res = await ApiClient.get(`/partner/api/gmb_location_media_images?page=${page}`, {
      params,
    });
    return res.data;
  };
}
