import React, { memo, useState } from 'react';
import { Categories, Category } from '../../../../models/gmb-location';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseRaw from './BaseRaw';
import AdditionalCategoriesModal from '../form-modal/AdditionalCategoriesModal';

type Props = {
  label: string;
  categories: Categories;
  handleUpdate?: (params: UpdateGmbLocationParams) => Promise<void>;
  editable?: boolean;
};

const AdditionalCategoriesRaw: React.FC<Props> = (props) => {
  const additionalCategories = props.categories.additionalCategories;
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        value={additionalCategories.map((v: Category) => v.displayName).join(' ') || '未設定'}
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <AdditionalCategoriesModal
          isShow={isShow}
          label={props.label}
          categories={props.categories}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(AdditionalCategoriesRaw);
