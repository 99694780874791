import React, { memo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RegularHoursRaw from '../../general/gmb-locations/raws/RegularHoursRaw';
import { Alert } from '../../../service/alert';

import { RegularHours } from '../../../models/gmb-location';
import { CompanyRegularHoursTemplate } from '../../../models/company-regular-hours-template';

import { UpdateGmbLocationParams } from '../../../requests/company/gmb-locations';
import { redirect } from '../../../utils/redirect';

type Props = {
  regularHours: RegularHours;
  handleUpdate: (
    params: UpdateGmbLocationParams,
    templateTitle?: string,
    regularHoursTemplateId?: number
  ) => Promise<CompanyRegularHoursTemplate>;
  redirectUrl?: string;
  regularHoursTemplateId?: number;
  templateTitle?: string;
};

const Form: React.FC<Props> = (props) => {
  const [regularHours, setRegularHours] = useState<RegularHours>(props.regularHours);
  const [regularHoursTemplateId, setRegularHoursTemplateId] = useState<number | undefined>(
    props.regularHoursTemplateId
  );
  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    if (templateTitle === undefined || templateTitle === '') {
      Alert.danger('タイトルは必須です');
      throw 'タイトルは必須です';
    }
    try {
      const result = await props.handleUpdate(params, templateTitle, regularHoursTemplateId);
      setRegularHoursTemplateId(result.id);
      setRegularHours(result.regularHours);
      Alert.success('保存しました');
      if (props.redirectUrl) await redirect(props.redirectUrl);
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };
  return (
    <>
      <Row>
        <Col>
          <RegularHoursRaw
            label="営業時間テンプレート"
            regularHoursPeriods={regularHours.periods}
            handleUpdate={handleUpdate}
            templateTitle={props.templateTitle}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(Form);
