import { useState } from 'react';
import { splitTime } from 'utils/app-time';
import {
  Period,
  DayOfTheWeek,
  AllWeekHoursPeriods,
  handleDevideHoursPeriods,
} from '../models/gmb-location/period';

type Props = {
  periods: Period[];
};

type usePeriodsData = {
  allWeekHoursPeriods: AllWeekHoursPeriods;
  handleOnChangeSwitch: (weekday: DayOfTheWeek) => void;
  handleOnAdd: (weekday: DayOfTheWeek) => void;
  handleOnDelete: (weekday: DayOfTheWeek, index: number) => void;
  handleOnChange: (weekday: DayOfTheWeek, isOpen: boolean, value: string, index: number) => void;
  handleClose: () => void;
  resetPeriods: (periods: Period[]) => void;
};

export const usePeriods = (props: Props): usePeriodsData => {
  const defaultAllWeekHoursPeriods = handleDevideHoursPeriods(props.periods);

  const [sundayHoursPeriods, setSundayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.SUNDAY
  );
  const [mondayHoursPeriods, setMondayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.MONDAY
  );
  const [tuesdayHoursPeriods, setTuesdayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.TUESDAY
  );
  const [wednesdayHoursPeriods, setWednesdayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.WEDNESDAY
  );
  const [thursdayHoursPeriods, setThursdayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.THURSDAY
  );
  const [fridayHoursPeriods, setFridayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.FRIDAY
  );
  const [saturdayHoursPeriods, setSaturdayHoursPeriods] = useState<Period[]>(
    defaultAllWeekHoursPeriods.SATURDAY
  );

  const allWeekHoursPeriods = {
    SUNDAY: sundayHoursPeriods,
    MONDAY: mondayHoursPeriods,
    TUESDAY: tuesdayHoursPeriods,
    WEDNESDAY: wednesdayHoursPeriods,
    THURSDAY: thursdayHoursPeriods,
    FRIDAY: fridayHoursPeriods,
    SATURDAY: saturdayHoursPeriods,
  };

  const resetPeriods = (periods: Period[]) => {
    const resetAllWeekHoursPeriods = handleDevideHoursPeriods(periods);
    setSundayHoursPeriods(
      resetAllWeekHoursPeriods.SUNDAY.length > 0 ? resetAllWeekHoursPeriods.SUNDAY : []
    );
    setMondayHoursPeriods(
      resetAllWeekHoursPeriods.MONDAY.length > 0 ? resetAllWeekHoursPeriods.MONDAY : []
    );
    setTuesdayHoursPeriods(
      resetAllWeekHoursPeriods.TUESDAY.length > 0 ? resetAllWeekHoursPeriods.TUESDAY : []
    );
    setWednesdayHoursPeriods(
      resetAllWeekHoursPeriods.WEDNESDAY.length > 0 ? resetAllWeekHoursPeriods.WEDNESDAY : []
    );
    setThursdayHoursPeriods(
      resetAllWeekHoursPeriods.THURSDAY.length > 0 ? resetAllWeekHoursPeriods.THURSDAY : []
    );
    setFridayHoursPeriods(
      resetAllWeekHoursPeriods.FRIDAY.length > 0 ? resetAllWeekHoursPeriods.FRIDAY : []
    );
    setSaturdayHoursPeriods(
      resetAllWeekHoursPeriods.SATURDAY.length > 0 ? resetAllWeekHoursPeriods.SATURDAY : []
    );
  };

  const handleOnChangeSwitch = (weekday: DayOfTheWeek) => {
    const newPeriods = [
      {
        openDay: weekday,
        openTime: {
          hours: 0,
          minutes: 0,
        },
        closeDay: weekday,
        closeTime: {
          hours: 0,
          minutes: 0,
        },
      },
    ];
    switch (weekday) {
      case 'SUNDAY':
        setSundayHoursPeriods(
          sundayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.SUNDAY.length > 0
            ? defaultAllWeekHoursPeriods.SUNDAY
            : newPeriods
        );
        break;
      case 'MONDAY':
        setMondayHoursPeriods(
          mondayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.MONDAY.length > 0
            ? defaultAllWeekHoursPeriods.MONDAY
            : newPeriods
        );
        break;
      case 'TUESDAY':
        setTuesdayHoursPeriods(
          tuesdayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.TUESDAY.length > 0
            ? defaultAllWeekHoursPeriods.TUESDAY
            : newPeriods
        );
        break;
      case 'WEDNESDAY':
        setWednesdayHoursPeriods(
          wednesdayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.WEDNESDAY.length > 0
            ? defaultAllWeekHoursPeriods.WEDNESDAY
            : newPeriods
        );
        break;
      case 'THURSDAY':
        setThursdayHoursPeriods(
          thursdayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.THURSDAY.length > 0
            ? defaultAllWeekHoursPeriods.THURSDAY
            : newPeriods
        );
        break;
      case 'FRIDAY':
        setFridayHoursPeriods(
          fridayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.FRIDAY.length > 0
            ? defaultAllWeekHoursPeriods.FRIDAY
            : newPeriods
        );
        break;
      case 'SATURDAY':
        setSaturdayHoursPeriods(
          saturdayHoursPeriods.length > 0
            ? []
            : defaultAllWeekHoursPeriods.SATURDAY.length > 0
            ? defaultAllWeekHoursPeriods.SATURDAY
            : newPeriods
        );
        break;
      default:
        break;
    }
  };

  const handleOnAdd = (weekday: DayOfTheWeek) => {
    const newPeriods = [
      {
        openDay: weekday,
        openTime: {
          hours: 0,
          minutes: 0,
        },
        closeDay: weekday,
        closeTime: {
          hours: 0,
          minutes: 0,
        },
      },
    ];
    switch (weekday) {
      case 'SUNDAY':
        setSundayHoursPeriods(sundayHoursPeriods.concat(newPeriods));
        break;
      case 'MONDAY':
        setMondayHoursPeriods(mondayHoursPeriods.concat(newPeriods));
        break;
      case 'TUESDAY':
        setTuesdayHoursPeriods(tuesdayHoursPeriods.concat(newPeriods));
        break;
      case 'WEDNESDAY':
        setWednesdayHoursPeriods(wednesdayHoursPeriods.concat(newPeriods));
        break;
      case 'THURSDAY':
        setThursdayHoursPeriods(thursdayHoursPeriods.concat(newPeriods));
        break;
      case 'FRIDAY':
        setFridayHoursPeriods(fridayHoursPeriods.concat(newPeriods));
        break;
      case 'SATURDAY':
        setSaturdayHoursPeriods(saturdayHoursPeriods.concat(newPeriods));
        break;
      default:
        break;
    }
  };

  const handleOnDelete = (weekday: DayOfTheWeek, index: number) => {
    switch (weekday) {
      case 'SUNDAY':
        setSundayHoursPeriods(sundayHoursPeriods.filter((_v: Period, key: number) => key != index));
        break;
      case 'MONDAY':
        setMondayHoursPeriods(mondayHoursPeriods.filter((_v: Period, key: number) => key != index));
        break;
      case 'TUESDAY':
        setTuesdayHoursPeriods(
          tuesdayHoursPeriods.filter((_v: Period, key: number) => key != index)
        );
        break;
      case 'WEDNESDAY':
        setWednesdayHoursPeriods(
          wednesdayHoursPeriods.filter((_v: Period, key: number) => key != index)
        );
        break;
      case 'THURSDAY':
        setThursdayHoursPeriods(
          thursdayHoursPeriods.filter((_v: Period, key: number) => key != index)
        );
        break;
      case 'FRIDAY':
        setFridayHoursPeriods(fridayHoursPeriods.filter((_v: Period, key: number) => key != index));
        break;
      case 'SATURDAY':
        setSaturdayHoursPeriods(
          saturdayHoursPeriods.filter((_v: Period, key: number) => key != index)
        );
        break;
      default:
        break;
    }
  };

  const handleOnChange = (weekday: DayOfTheWeek, isOpen: boolean, value: string, index: number) => {
    const updateState = (v: Period, key: number) => {
      return {
        openDay: weekday,
        openTime: isOpen && index == key ? splitTime(value) : v.openTime,
        closeDay: weekday,
        closeTime: !isOpen && index == key ? splitTime(value) : v.closeTime,
      };
    };
    switch (weekday) {
      case 'SUNDAY':
        setSundayHoursPeriods(
          sundayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'MONDAY':
        setMondayHoursPeriods(
          mondayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'TUESDAY':
        setTuesdayHoursPeriods(
          tuesdayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'WEDNESDAY':
        setWednesdayHoursPeriods(
          wednesdayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'THURSDAY':
        setThursdayHoursPeriods(
          thursdayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'FRIDAY':
        setFridayHoursPeriods(
          fridayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      case 'SATURDAY':
        setSaturdayHoursPeriods(
          saturdayHoursPeriods.map((v: Period, key: number) => {
            return updateState(v, key);
          })
        );
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setSundayHoursPeriods(defaultAllWeekHoursPeriods.SUNDAY);
    setMondayHoursPeriods(defaultAllWeekHoursPeriods.MONDAY);
    setTuesdayHoursPeriods(defaultAllWeekHoursPeriods.TUESDAY);
    setWednesdayHoursPeriods(defaultAllWeekHoursPeriods.WEDNESDAY);
    setThursdayHoursPeriods(defaultAllWeekHoursPeriods.THURSDAY);
    setFridayHoursPeriods(defaultAllWeekHoursPeriods.FRIDAY);
    setSaturdayHoursPeriods(defaultAllWeekHoursPeriods.SATURDAY);
  };

  return {
    allWeekHoursPeriods,
    handleOnChangeSwitch,
    handleOnAdd,
    handleOnDelete,
    handleOnChange,
    handleClose,
    resetPeriods,
  };
};
