import React, { memo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Star from '../../general/icons/Star';
import SaveButton from '../../general/button/SaveButton';
import AsyncSelectGmbLocation from '../async-select/gmb-location';

import { GmbLocation } from '../../../models/gmb-location';

import { SearchGmbLocationParams as PartnerSearchGmabLocationParams } from '../../../requests/partner/gmb-locations';
import { SearchGmbLocationParams as CompanySearchGmabLocationParams } from '../../../requests/company/gmb-locations';

type SearchGmbLocationParams = PartnerSearchGmabLocationParams | CompanySearchGmabLocationParams;

type Props = {
  disabledSync?: boolean;
  selectedItems: SelectedItems;
  updateQuery(selectedItems?: SelectedItems): Promise<void>;
  handleGmbLocationRequest: (params: SearchGmbLocationParams) => Promise<GmbLocation[]>;
  handleGmbLocationReviewAsync?: () => Promise<boolean>;
  gmbLocationReviewManagementPath?: string;
};

export type SelectedItems = {
  gmbLocationId?: number;
  replied?: boolean;
  rate?: string;
};

const ReviewSearchBox: React.FC<Props> = (props: Props) => {
  const {
    disabledSync,
    selectedItems,
    updateQuery,
    handleGmbLocationRequest,
    handleGmbLocationReviewAsync,
    gmbLocationReviewManagementPath,
  } = props;

  const selectedGmbLocationId = selectedItems?.gmbLocationId;
  const selectedReplyed = selectedItems?.replied;
  const selectedStarRaing = selectedItems?.rate;

  const [syncDisabled, setSyncDisabled] = useState<boolean>(disabledSync || false);

  const handleSync = () => {
    if (handleGmbLocationReviewAsync) {
      setSyncDisabled(true);
      handleGmbLocationReviewAsync();
    }
  };

  const handleSelectReplyStatus = async (repliedStatus?: boolean) => {
    await updateQuery({
      gmbLocationId: selectedGmbLocationId,
      rate: selectedStarRaing,
      replied: repliedStatus,
    });
  };

  const handleSelectRate = async (rate?: string) => {
    await updateQuery({
      gmbLocationId: selectedGmbLocationId,
      rate: rate,
      replied: selectedReplyed,
    });
  };

  const handlAsyncGmbLocation = async (gmbLocationId?: number, name?: string) => {
    const v =
      gmbLocationId === undefined || name === undefined
        ? undefined
        : { label: name, value: gmbLocationId };
    await updateQuery({
      gmbLocationId: v?.value,
      rate: selectedStarRaing,
      replied: selectedReplyed,
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <div className="d-md-flex justify-content-start mb-3 mr-3" style={{ width: '100%' }}>
            <DropdownButton
              id="dropdown-review-status"
              title="返信ステータスで絞込み"
              variant="outline-dark"
              className="mt-2 mr-2"
              renderMenuOnMount={true}
            >
              <Dropdown.Item
                eventKey="all"
                active={selectedReplyed === undefined}
                onSelect={() => {
                  handleSelectReplyStatus(undefined);
                }}
              >
                すベて
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="notReplyed"
                active={selectedReplyed === false}
                onSelect={() => {
                  handleSelectReplyStatus(false);
                }}
              >
                未返信
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="replyed"
                active={selectedReplyed === true}
                onSelect={() => {
                  handleSelectReplyStatus(true);
                }}
              >
                返信済
              </Dropdown.Item>
            </DropdownButton>
            <DropdownButton
              id="dropdown-review-start"
              title="評価数で絞込み"
              variant="outline-dark"
              className="mt-2"
            >
              <Dropdown.Item
                active={selectedStarRaing === ''}
                onSelect={() => {
                  handleSelectRate(undefined);
                }}
              >
                すベて
              </Dropdown.Item>
              {[...Array(5)].map((_, itemIndex) => (
                <Dropdown.Item
                  eventKey={String(itemIndex + 1)}
                  key={`drop_star_item_${itemIndex}`}
                  active={selectedStarRaing === String(itemIndex + 1)}
                  onSelect={(v) => {
                    handleSelectRate(v || undefined);
                  }}
                >
                  <div className="mb-2 text-warning">
                    {[...Array(itemIndex + 1)].map((_, index) => (
                      <Star key={`star_${index}`} />
                    ))}
                  </div>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="mt-2 mb-2">
            <AsyncSelectGmbLocation
              gmbLocationId={selectedItems?.gmbLocationId}
              handleGmbLocationRequest={handleGmbLocationRequest}
              handleAfterSearch={handlAsyncGmbLocation}
            />
          </div>
        </div>
        <div className="d-lg-flex justify-content-end mb-3 mt-2" style={{ height: '50px' }}>
          {gmbLocationReviewManagementPath && (
            <Button
              variant="light"
              onClick={() => {
                location.href = gmbLocationReviewManagementPath;
              }}
            >
              口コミ設定管理
            </Button>
          )}
          {handleGmbLocationReviewAsync && (
            <SaveButton onClick={handleSync} disabled={syncDisabled}>
              {syncDisabled ? 'GBP同期中' : 'GBP同期'}
            </SaveButton>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-start mb-3">
        <h6 className="d-flex align-items-center">
          返信ステータス:&nbsp;
          <Badge variant="secondary">
            {selectedReplyed === undefined ? 'すべて' : selectedReplyed ? '返信済' : '未返信'}
          </Badge>
        </h6>
        <h6 className="d-flex align-items-center ml-3">
          評価数:&nbsp;
          {selectedStarRaing === '' || !selectedStarRaing ? (
            <Badge variant="secondary">すべて</Badge>
          ) : (
            <div className="d-flex align-items-center text-warning">
              {[...Array(parseInt(selectedStarRaing))].map((_, index) => (
                <Star key={`star_${index}`} />
              ))}
            </div>
          )}
        </h6>
      </div>
    </>
  );
};

export default memo(ReviewSearchBox);
