import React, { memo } from 'react';

import { MediaItem } from 'models/location-media';

import { RequestGmbLocation } from '../../../../requests/company/gmb-locations';

import LatestMediaImages from '../../../general/gmb-location-media-images/LatestMediaImages';

type Props = {
  locationMediaPath: string;
  gmbLocationId: number;
};

const LatestMediaImagesView: React.FC<Props> = (props: Props) => {
  const refresh = async (): Promise<MediaItem[]> => {
    return await RequestGmbLocation.latestMediaImages(props.gmbLocationId);
  };

  return (
    <LatestMediaImages
      locationMediaPath={props.locationMediaPath}
      fetchLatestLocationMediaImages={refresh}
    />
  );
};

export default memo(LatestMediaImagesView);
