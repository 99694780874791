import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { OpenInfo } from '../../../../models/gmb-location';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  openInfo: OpenInfo | null;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const OpenInfoModal: React.FC<Props> = (props) => {
  const openInfo = props.openInfo;
  const [year, setYear] = useState<number | null>(props.openInfo?.openingDate?.year || null);
  const [month, setMonth] = useState<number | null>(props.openInfo?.openingDate?.month || null);
  const [day, setDay] = useState<number | null>(props.openInfo?.openingDate?.day || null);
  const handleChangYearInput = (value: number | null) => {
    setYear(value);
  };
  const handleChangMonthInput = (value: number | null) => {
    setMonth(value);
  };
  const handleChangDayInput = (value: number | null) => {
    setDay(value);
  };
  const handleClose = () => {
    setYear(props.openInfo?.openingDate?.year || null);
    setMonth(props.openInfo?.openingDate?.month || null);
    setDay(props.openInfo?.openingDate?.day || null);
    props.handleModal();
  };

  if (openInfo === null) {
    return <></>;
  }

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          await props.handleUpdate({
            openInfo: {
              openingDate: {
                year,
                month,
                day,
              },
            },
          });
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>年</Form.Label>
            <Form.Control
              as="input"
              type="number"
              value={year || 2000}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangYearInput(parseInt(e.target.value));
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>月</Form.Label>
            <Form.Control
              as="input"
              type="number"
              value={month || 1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangMonthInput(parseInt(e.target.value));
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>日</Form.Label>
            <Form.Control
              as="input"
              type="number"
              value={day || 1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangDayInput(parseInt(e.target.value));
              }}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(OpenInfoModal);
