import React, { memo } from 'react';
import { GmbLocalBasicEvaluation } from '../../../../models/gmb-local-evaluation';
import EvaluationConversionCells from '../TableData/EvaluationConversionCells';
import EvaluationKeywordConversionCells from '../TableData/EvaluationKeywordConversionCells';

type Props = {
  data: GmbLocalBasicEvaluation | undefined;
  hasKeywordContent: boolean;
};

const BasicEvaluationTableBody: React.FC<Props> = (props: Props) => {
  const { data, hasKeywordContent } = props;

  const basicKeywordContent = (data?: GmbLocalBasicEvaluation) => {
    if (hasKeywordContent) {
      return (
        <>
          <tr>
            <th
              className="text-left align-middle"
              rowSpan={data?.keywordContent ? data.keywordContent.details.length + 1 : 0}
            >
              指定キーワード網羅数
            </th>
          </tr>
          <EvaluationKeywordConversionCells keywordContent={data?.keywordContent} />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {basicKeywordContent(data)}

      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          文字数
        </th>
        <EvaluationConversionCells
          evaluation={data?.amountOfText.evaluation}
          achievement={data?.amountOfText.achievement}
        />
      </tr>
    </>
  );
};

export default memo(BasicEvaluationTableBody);
