import React, { memo, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { Company } from '../../../models/company';
import { SearchCompanyParams } from '../../../requests/partner/companies';

type Props = {
  companyId?: number;
  handleRequest: (params: SearchCompanyParams) => Promise<Company[]>;
  handleAfterSearch: (companyId?: number, name?: string) => Promise<void>;
};

type Option = {
  label: string;
  value: number;
};

const AyncSelectCompany: React.FC<Props> = (props) => {
  const { companyId, handleRequest, handleAfterSearch } = props;

  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [selectedCompany, setSelectCompnay] = useState<
    { label: string; value: number } | undefined
  >(undefined);

  useEffect(() => {
    handleSearch({ companyId }).then((v) => {
      setSelectCompnay({ label: v[0]?.label, value: v[0]?.value });
    });
  }, [companyId]);

  const handleSearch = async (params: SearchCompanyParams) => {
    const result = await handleRequest(params);
    return result.map((v: Company) => {
      return {
        label: v.name,
        value: v.id,
      };
    });
  };

  const loadOptions = async (inputValue: string): Promise<Option[]> => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    return await handleSearch({ name: inputValue });
  };

  if (selectedCompany === undefined) {
    return <></>;
  }

  return (
    <div style={{ width: '100%' }}>
      <AsyncSelect
        isClearable
        defaultValue={selectedCompany?.label ? selectedCompany : undefined}
        placeholder="ショップグループ検索"
        onChange={async (option: Option) => {
          setSelectCompnay({ label: option?.label, value: option?.value });
          await handleAfterSearch(option?.value, option?.label);
        }}
        loadOptions={loadOptions}
      />
    </div>
  );
};

export default memo(AyncSelectCompany);
