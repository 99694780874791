import Axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { Alert } from '../service/alert';

export const ApiClient = Axios.create();

export const ApiCancelToken = Axios.CancelToken;

export const isApiCancel = (err: unknown): boolean => {
  return Axios.isCancel(err);
};

ApiClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const csrfToken = document.head
    .querySelector('[name=csrf-token][content]')
    ?.getAttribute('content');
  if (csrfToken != null) {
    config.headers['X-CSRF-Token'] = csrfToken;
  }
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  return config;
});

ApiClient.interceptors.response.use(
  async (response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> => {
    return response;
  },
  (error: AxiosError): Promise<never> => {
    if (isApiCancel(error)) return Promise.reject(error);

    if (error.response?.status == 400 && error.response.data.messages !== undefined) {
      Alert.danger(error.response.data.messages.join('<br/>'));
    } else {
      Alert.danger('不明なエラーが発生しました');
    }

    return Promise.reject(error);
  }
);

export interface PagenationApiResponse<T = null, K = null> {
  data: T;
  pagination: K;
}

export default ApiClient;
