import React, { memo } from 'react';
import EvaluationCell from './cell/EvaluationCell';
import AchievementCell from './cell/AchievementCell';
import { KeywordContent } from '../../../../models/gmb-local-evaluation/keyword-content';

type Props = {
  keywordContent: KeywordContent | undefined;
};

const EvaluationKeywordConversionCells: React.FC<Props> = (props: Props) => {
  const { keywordContent } = props;

  return (
    <>
      {keywordContent?.details?.map((item, index: number) => (
        <tr key={index}>
          <td className="text-left align-middle">{item.keyword}</td>
          <td className="text-center align-middle">{item.keywordRank}</td>
          {index == 0 && (
            <EvaluationCell
              evaluation={keywordContent.evaluation}
              rowSpan={keywordContent.details.length}
            />
          )}
          <AchievementCell evaluation={item.evaluation} achievement={item.achievement} />
        </tr>
      ))}
    </>
  );
};

export default memo(EvaluationKeywordConversionCells);
