import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { CallToActionType, CallToActionTypeOption } from '../../../../models/gmb-local-post';

export type Option = {
  value: CallToActionType;
  label: string;
};

type Props = {
  selectedCallToActionType: CallToActionType;
  callToActionUrl: string;
  handleChangeActionUrl: (value: string) => void;
  handleSelected: (value: Option) => void;
};

const ActionButtonSelect: React.FC<Props> = (props) => {
  const { selectedCallToActionType, callToActionUrl, handleChangeActionUrl, handleSelected } =
    props;

  const options = Object.entries(CallToActionTypeOption).map(
    (value: [key: CallToActionType, jp: string]): Option => {
      const [key, jp] = value;
      return { value: key, label: jp };
    }
  );

  return (
    <>
      <Form.Group>
        <Form.Label>ボタンの追加(省略可)</Form.Label>
        <Select
          defaultValue={{
            value: selectedCallToActionType,
            label: CallToActionTypeOption[selectedCallToActionType],
          }}
          onChange={handleSelected}
          options={options}
        />
      </Form.Group>
      {selectedCallToActionType != 'ACTION_TYPE_UNSPECIFIED' && selectedCallToActionType != 'CALL' && (
        <Form.Group>
          <Form.Label>ボタンのリンク</Form.Label>
          <Form.Control
            placeholder="ボタンのリンク"
            value={callToActionUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeActionUrl(e.target.value);
            }}
          />
        </Form.Group>
      )}
      {selectedCallToActionType == 'CALL' && (
        <Form.Group>
          <Form.Label>電話番号</Form.Label>
          <Form.Control placeholder="電話番号" value={'ショップの電話番号'} disabled={true} />
        </Form.Group>
      )}
    </>
  );
};

export default memo(ActionButtonSelect);
