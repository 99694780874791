import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import LocalPostCard from '../gmb-local-posts/LocalPostCard';
import StandardModal from '../../general/gmb-local-posts/modals/StandardModal';
import EventModal from '../../general/gmb-local-posts/modals/EventModal';
import OfferModal from '../../general/gmb-local-posts/modals/OfferModal';
import AlertModal from '../../general/gmb-local-posts/modals/Alert';

import { GmbLocation } from '../../../models/gmb-location';
import { GmbLocalPost } from '../../../models/gmb-local-post';
import { sliceByNumber } from '../../../utils/app-array';
import { isSP } from '../../../utils/app-util';
import { CancelTokenSource } from 'axios';
import { useKeywords } from '../../../hooks/useKeywords';

type Props = {
  localPostListPath: string;
  handleShowGmbLocation: (id: number) => Promise<GmbLocation>;
  fetchLatestLocalPosts: () => Promise<GmbLocalPost[]>;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
};

const LatestLocalPosts: React.FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedGmbLocalPost, setSelectedGmbLocalPost] = useState<GmbLocalPost | null>(null);
  const [gmbLocalPosts, setGmbLocalPosts] = useState<GmbLocalPost[]>([]);

  const refresh = async (): Promise<void> => {
    setIsLoading(true);
    const v = await props.fetchLatestLocalPosts();
    setGmbLocalPosts(v);
    setIsLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const { keywords, clearKeywords } = useKeywords({
    gmbLocationId: selectedGmbLocalPost?.gmbLocationId,
    handleGmbLocationKeyword: props.handleGmbLocationKeyword,
  });

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  return (
    <>
      {gmbLocalPosts.length <= 0 ? (
        <h5>最新の投稿はありません</h5>
      ) : (
        <>
          {sliceByNumber(gmbLocalPosts, isSP() ? 2 : 3).map((splitGmbLocalPosts, index) => (
            <Row key={`gmb_local_post_view_parent_${index}`}>
              {splitGmbLocalPosts.map((gmbLocalPost, childIndex) => {
                return (
                  <Col key={`gmb_local_post_view_${childIndex}`}>
                    <LocalPostCard
                      isLoading={false}
                      gmbLocalPost={gmbLocalPost}
                      topicType={gmbLocalPost.localPost.topicType}
                      handleModal={() => {
                        setShowModal(true);
                        setSelectedGmbLocalPost(gmbLocalPost);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          ))}
          {selectedGmbLocalPost && (
            <>
              {selectedGmbLocalPost.localPost.topicType == 'STANDARD' && (
                <StandardModal
                  isShow={showModal}
                  gmbLocationId={selectedGmbLocalPost.gmbLocationId}
                  handleShowGmbLocation={props.handleShowGmbLocation}
                  gmbLocalPost={selectedGmbLocalPost}
                  templates={[]}
                  handleModal={() => {
                    clearKeywords();
                    setShowModal(false);
                    setSelectedGmbLocalPost(null);
                  }}
                  keywords={keywords}
                />
              )}
              {selectedGmbLocalPost.localPost.topicType == 'EVENT' && (
                <EventModal
                  isShow={showModal}
                  gmbLocationId={selectedGmbLocalPost.gmbLocationId}
                  handleShowGmbLocation={props.handleShowGmbLocation}
                  gmbLocalPost={selectedGmbLocalPost}
                  handleModal={() => {
                    setShowModal(false);
                    setSelectedGmbLocalPost(null);
                  }}
                />
              )}
              {selectedGmbLocalPost.localPost.topicType == 'OFFER' && (
                <OfferModal
                  isShow={showModal}
                  gmbLocationId={selectedGmbLocalPost.gmbLocationId}
                  handleShowGmbLocation={props.handleShowGmbLocation}
                  gmbLocalPost={selectedGmbLocalPost}
                  handleModal={() => {
                    setShowModal(false);
                    setSelectedGmbLocalPost(null);
                  }}
                />
              )}
              {selectedGmbLocalPost.localPost.topicType == 'ALERT' && (
                <AlertModal
                  isShow={showModal}
                  gmbLocationId={selectedGmbLocalPost.gmbLocationId}
                  handleShowGmbLocation={props.handleShowGmbLocation}
                  gmbLocalPost={selectedGmbLocalPost}
                  handleModal={() => {
                    setShowModal(false);
                    setSelectedGmbLocalPost(null);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <div className="d-flex justify-content-end">
        <a href={props.localPostListPath} type="button" className="btn btn-link">
          投稿一覧
        </a>
      </div>
    </>
  );
};

export default memo(LatestLocalPosts);
