import React, { memo, useState } from 'react';
import NewButton from '../../general/button/NewButton';

import { MediaCategory, MediaCategoryJp } from '../../../models/location-media';

import { SaveLocationMediaParams as PartnerLocationMediaParams } from '../../../requests/partner/gmb-location-media-images';

import UploadModal from './UploadModal';

export type SaveLocationMediaParams = PartnerLocationMediaParams;

type Props = {
  gmbLocationId: number;
  mediaCategory: MediaCategory;
  handleAferPosted: () => Promise<void>;
  handleCreateLocationMedia: (params: SaveLocationMediaParams) => Promise<void>;
};

const CreateButton: React.FC<Props> = (props: Props) => {
  const { gmbLocationId, mediaCategory, handleAferPosted, handleCreateLocationMedia } = props;

  const [isShow, setIsShow] = useState<boolean>(false);

  const handleModal = () => {
    setIsShow(!isShow);
  };

  const handleCreate = async (params: SaveLocationMediaParams) => {
    await handleCreateLocationMedia(params);
    handleAferPosted();
    setIsShow(!isShow);
  };

  return (
    <>
      <NewButton onClick={handleModal}>{`${MediaCategoryJp[mediaCategory]}の写真追加`}</NewButton>
      <UploadModal
        isShow={isShow}
        mediaCategory={mediaCategory}
        gmbLocationId={gmbLocationId}
        handleModal={handleModal}
        handleCreate={handleCreate}
      />
    </>
  );
};

export default memo(CreateButton);
