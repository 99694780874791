import React, { memo } from 'react';
type Props = {
  width?: string;
  height?: string;
  onClick?: () => void;
};
const Eye: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '16'}
      height={props.width ? props.width : '16'}
      fill="currentColor"
      viewBox="0 0 16 16"
      onClick={() => (props.onClick ? props.onClick() : '')}
    >
      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
    </svg>
  );
};

export default memo(Eye);
