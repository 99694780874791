import React, { memo, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpecialHoursRaw from '../../general/gmb-locations/raws/SpecialHoursRaw';
import { Alert } from '../../../service/alert';

import { SpecialHours } from '../../../models/gmb-location';
import { CompanySpecialHoursTemplate } from '../../../models/company-special-hours-template';

import { UpdateGmbLocationParams } from '../../../requests/company/gmb-locations';
import { redirect } from '../../../utils/redirect';

type Props = {
  specialHours: SpecialHours;
  handleUpdate: (
    params: UpdateGmbLocationParams,
    templateTitle?: string,
    specialHoursTemplateId?: number
  ) => Promise<CompanySpecialHoursTemplate>;
  redirectUrl?: string;
  specialHoursTemplateId?: number;
  templateTitle?: string;
};

const Form: React.FC<Props> = (props) => {
  const [specialHours, setSpecialHours] = useState<SpecialHours>(props.specialHours);
  const [specialHoursTemplateId, setSpecialHoursTemplateId] = useState<number | undefined>(
    props.specialHoursTemplateId
  );
  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    if (templateTitle === undefined || templateTitle === '') {
      Alert.danger('タイトルは必須です');
      throw 'タイトルは必須です';
    }
    try {
      const result = await props.handleUpdate(params, templateTitle, specialHoursTemplateId);
      setSpecialHoursTemplateId(result.id);
      setSpecialHours(result.specialHours);
      Alert.success('保存しました');
      if (props.redirectUrl) await redirect(props.redirectUrl);
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };
  return (
    <>
      <Row>
        <Col>
          <SpecialHoursRaw
            label="特別営業時間テンプレート"
            specialHourPeriods={specialHours.specialHourPeriods}
            handleUpdate={handleUpdate}
            templateTitle={props.templateTitle}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(Form);
