import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Profile } from '../../../../models/gmb-location';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import BootstrapAlert from 'react-bootstrap/Alert';
import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  profile: Profile | null;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
  keywords: string[];
};

const ProfileModal: React.FC<Props> = (props) => {
  const [description, setDescription] = useState<string>(props.profile?.description || '');
  const handleChangeInput = (value: string) => {
    setDescription(value);
  };
  const handleClose = () => {
    setDescription(props.profile?.description || '');
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          await props.handleUpdate({
            profile: {
              description,
            },
          });
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={10}
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInput(e.target.value);
              }}
            />
          </Form.Group>
          {(props.keywords?.length || 0) > 0 && (
            <Form.Group>
              <BootstrapAlert variant={'primary'}>
                設定キーワード: {props.keywords?.map((keyword) => `「${keyword}」`).join(',')}
              </BootstrapAlert>
            </Form.Group>
          )}
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(ProfileModal);
