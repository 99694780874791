import { useState } from 'react';
import { Attribute as AttributeValue } from '../models/gmb-location';
import { validateUrl } from '../utils/validate';

type Props = {
  attributeValues: AttributeValue[];
};

type AttributeErrors = {
  attributeId: string;
  displayName: string;
  message: string;
};

type useAttributesData = {
  attributeValues: AttributeValue[];
  attributeErrors: AttributeErrors[];
  handleBoolAttributeValue: (attributeValue: AttributeValue) => void;
  handleEnumAttributeValue: (attributeValue: AttributeValue) => void;
  handleRepeatedEnumAttributeValue: (attributeValue: AttributeValue) => void;
  handleUrlAttributeValue: (displayName: string, attributeValue: AttributeValue) => void;
  handleClose: () => void;
};

export const useAttributes = (props: Props): useAttributesData => {
  const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(props.attributeValues);
  const [attributeErrors, setAttributeErrors] = useState<AttributeErrors[]>([]);

  const handleBoolAttributeValue = (attributeValue: AttributeValue) => {
    console.log(attributeValue);
    if (attributeValue.values.length <= 0) {
      const updatedAttributeValues = attributeValues.filter((v) => {
        return v.name != attributeValue.name;
      });
      setAttributeValues(updatedAttributeValues);
    } else if (attributeValue.values[0]) {
      setAttributeValues(attributeValues.concat([attributeValue]));
    } else {
      const updatedAttributeValues = attributeValues.map((v) => {
        if (v.name === attributeValue.name) {
          return attributeValue;
        }
        return v;
      });
      setAttributeValues(updatedAttributeValues);
    }
  };

  const handleEnumAttributeValue = (attributeValue: AttributeValue) => {
    if (attributeValue.values.length <= 0) {
      const updatedAttributeValues = attributeValues.filter((v) => {
        return v.name != attributeValue.name;
      });
      setAttributeValues(updatedAttributeValues);
    } else if (attributeValue.values[0]) {
      const updatedAttributeValues = attributeValues.filter((v) => {
        return v.name != attributeValue.name;
      });
      setAttributeValues(updatedAttributeValues.concat([attributeValue]));
    } else {
      const updatedAttributeValues = attributeValues.map((v) => {
        if (v.name === attributeValue.name) {
          return attributeValue;
        }
        return v;
      });
      setAttributeValues(updatedAttributeValues);
    }
  };

  const handleRepeatedEnumAttributeValue = (attributeValue: AttributeValue) => {
    const updatedAttributeValues = attributeValues.filter((v) => {
      return v.name != attributeValue.name;
    });
    setAttributeValues(updatedAttributeValues.concat([attributeValue]));
  };

  const handleUrlAttributeValue = (displayName: string, attributeValue: AttributeValue) => {
    if (attributeValue.uriValues.length <= 0 || attributeValue.uriValues[0].uri === '') {
      const updatedAttributeValues = attributeValues.filter((v) => {
        return v.name != attributeValue.name;
      });
      setAttributeValues(updatedAttributeValues);
    } else if (attributeValue.uriValues[0]) {
      const updatedAttributeErrors = attributeErrors.filter((v) => {
        return v.attributeId != attributeValue.name.split('/')[1];
      });
      if (validateUrl(attributeValue.uriValues[0].uri) === false) {
        setAttributeErrors(
          updatedAttributeErrors.concat([
            {
              attributeId: attributeValue.name,
              displayName: displayName,
              message: 'URLの形式が正しくありません',
            },
          ])
        );
      } else {
        setAttributeErrors(updatedAttributeErrors);
      }
      const updatedAttributeValues = attributeValues.filter((v) => {
        return v.name != attributeValue.name;
      });
      setAttributeValues(updatedAttributeValues.concat([attributeValue]));
    } else {
      const updatedAttributeValues = attributeValues.map((v) => {
        if (v.name === attributeValue.name) {
          return attributeValue;
        }
        return v;
      });
      setAttributeValues(updatedAttributeValues);
    }
  };

  const handleClose = () => {
    setAttributeValues(props.attributeValues);
  };

  return {
    attributeValues,
    attributeErrors,
    handleBoolAttributeValue,
    handleEnumAttributeValue,
    handleRepeatedEnumAttributeValue,
    handleUrlAttributeValue,
    handleClose,
  };
};
