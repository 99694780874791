import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import {
  SpecialHourPeriod,
  AllSpecialHourPeriod,
} from '../../../../models/gmb-location/special-hour-period';

import Cross from '../../../general/icons/Cross';
import TextField from '@material-ui/core/TextField';
import { buildTime } from 'utils/app-time';

type Props = {
  allSpecialHourPeriods: AllSpecialHourPeriod[];
  handleOnChangeSwitch?: (key: string, parentTargetIndex: number) => void;
  handleOnAdd?: (key: string) => void;
  handleOnDelete?: (key: string, targetIndex: number) => void;
  handleOnChange?: (key: string, isOpen: boolean, value: string, index: number) => void;
  handleOnChangeDate?: (key: string, value: string, parentTargetIndex: number) => void;
  handleOnDeleteDate?: (parentTargetIndex: number) => void;
  handleOnAddDate?: () => void;
  readonly: boolean;
  holidayVisible: boolean;
};

const PeriodsTable: React.FC<Props> = (props) => {
  const {
    allSpecialHourPeriods,
    handleOnAdd,
    handleOnDelete,
    handleOnChange,
    handleOnChangeDate,
    handleOnDeleteDate,
    handleOnChangeSwitch,
    handleOnAddDate,
  } = props;

  return (
    <>
      {allSpecialHourPeriods.map((v: AllSpecialHourPeriod, parentIndex: number) => {
        return v.closed ? (
          <ListGroup key={`listGroup_${v.key}_${parentIndex}_${props.readonly}`}>
            <ListGroup.Item>
              <Row>
                <Col>
                  <>
                    <TextField
                      id={v.key}
                      type="date"
                      defaultValue={v.key}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={props.readonly}
                      onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                        handleOnChangeDate
                          ? handleOnChangeDate(v.key, cv.target.value, parentIndex)
                          : '';
                      }}
                    />
                  </>
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    id={`switch_${v.key}_${parentIndex}_${props.readonly}`}
                    label="定休日"
                    checked={false}
                    disabled={props.readonly}
                    onChange={() =>
                      handleOnChangeSwitch ? handleOnChangeSwitch(v.key, parentIndex) : ''
                    }
                  />
                </Col>
                <Col></Col>
                <Col></Col>
                {!props.readonly && (
                  <>
                    <Col></Col> <Col></Col>
                  </>
                )}
              </Row>
            </ListGroup.Item>
          </ListGroup>
        ) : (
          v.periods.map((specialHourPeriod: SpecialHourPeriod, index: number) => {
            return (
              <ListGroup key={`${v.key}_${parentIndex}_${index}_${props.readonly}`}>
                <ListGroup.Item>
                  <Row>
                    <Col>
                      {index != 0 ? (
                        ''
                      ) : (
                        <>
                          <TextField
                            id={v.key}
                            type="date"
                            defaultValue={v.key}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={props.readonly}
                            onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                              handleOnChangeDate
                                ? handleOnChangeDate(v.key, cv.target.value, parentIndex)
                                : '';
                            }}
                          />
                        </>
                      )}
                    </Col>
                    <Col>
                      {index != 0 || v.key === '' ? (
                        ''
                      ) : (
                        <Form.Check
                          type="switch"
                          label="営業日"
                          id={`switch_${v.key}_${parentIndex}_${props.readonly}`}
                          checked={true}
                          disabled={props.readonly}
                          onChange={() =>
                            handleOnChangeSwitch ? handleOnChangeSwitch(v.key, parentIndex) : ''
                          }
                        />
                      )}
                    </Col>
                    <Col>
                      <TextField
                        type="time"
                        defaultValue={buildTime(specialHourPeriod.openTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        disabled={props.readonly}
                        onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                          handleOnChange ? handleOnChange(v.key, true, cv.target.value, index) : '';
                        }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        type="time"
                        defaultValue={buildTime(specialHourPeriod.closeTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        disabled={props.readonly}
                        onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                          handleOnChange
                            ? handleOnChange(v.key, false, cv.target.value, index)
                            : '';
                        }}
                      />
                    </Col>
                    {!props.readonly && (
                      <Col style={{ textAlign: 'center' }} md="5">
                        {index != 0 ? (
                          <Row>
                            <Col></Col>
                            <Col>
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => (handleOnDelete ? handleOnDelete(v.key, index) : '')}
                              >
                                <Cross width="20" height="20" />
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <Button
                                variant="link"
                                onClick={() => (handleOnAdd ? handleOnAdd(v.key) : '')}
                              >
                                営業時間を追加
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() =>
                                  handleOnDeleteDate ? handleOnDeleteDate(parentIndex) : ''
                                }
                              >
                                <Cross width="20" height="20" />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    )}
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            );
          })
        );
      })}
      {!props.readonly && (
        <Button variant="link" onClick={handleOnAddDate}>
          他の日付を追加する
        </Button>
      )}
    </>
  );
};

export default memo(PeriodsTable);
