import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Attribute } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

type Props = {
  attribute: Attribute;
  targetAttributeValue?: AttributeValue;
  readonly: boolean;
  handleAttributeValue?: (displayName: string, v: AttributeValue) => void;
};

const AttributeUrlDisplay: React.FC<Props> = (props) => {
  return (
    <>
      <Container fluid="xl" style={{ backgroundColor: '#DCDCDC', padding: '10px' }}>
        <Row>
          <Col>{props.attribute.displayName}</Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="text"
              disabled={props.readonly}
              value={(props.targetAttributeValue?.uriValues[0]?.uri as string) || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (props.handleAttributeValue) {
                  props.handleAttributeValue(props.attribute.displayName, {
                    name: 'attributes/' + props.attribute.attributeId,
                    valueType: props.attribute.valueType,
                    repeatedEnumValue: {
                      setValues: [],
                      unsetValues: [],
                    },
                    uriValues: [{ uri: e.target.value }],
                    values: [],
                  });
                }
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(AttributeUrlDisplay);
