import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import PencilSquare from '../../../../components/general/icons/PencilSquare';

type Props = {
  isLoading: boolean;
  label: string;
  value?: string;
  cardNode?: React.ReactNode;
  handleModal?: () => void;
  actionButtons?: React.ReactNode[];
  isHideBtn: boolean | false;
};

const BaseRaw: React.FC<Props> = (props) => {
  return (
    <Card>
      <Card.Header>{props.label}</Card.Header>
      <Card.Body>
        <ListGroup>
          <ListGroup.Item className="mt-10" style={{ border: 'none' }}>
            {props.isLoading ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div style={{ whiteSpace: 'pre-line' }}>{props.value}</div>
                  {!props.isHideBtn && (
                    <div>
                      {props.actionButtons?.map((actionButton) => {
                        return actionButton;
                      })}
                      <Button
                        size="sm"
                        variant="dark"
                        className="text-nowrap"
                        onClick={() => {
                          if (props.handleModal !== undefined) props.handleModal();
                        }}
                      >
                        <PencilSquare /> 編集
                      </Button>
                    </div>
                  )}
                </div>
                {props.cardNode && <div style={{ marginTop: '10px' }}>{props.cardNode}</div>}
              </>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default memo(BaseRaw);
