import React, { memo, useEffect } from 'react';

import SearchBox from '../../../general/gmb-local-posts/SearchBox';
import LocalPostList from '../../../general/gmb-local-posts/LocalPostList';

import { RequestGmbLocalPost } from '../../../../requests/company/gmb-local-posts';
import { RequestGmbLocation } from '../../../../requests/company/gmb-locations';
import { useLocalPostSearch } from '../../../../hooks/useLocalPostSearch';

type Props = {
  specifiedGmbLocationId?: number;
  paginationPath: string;
  searchable?: boolean;
};

const PartnerLocalPostList: React.FC<Props> = (props) => {
  const { specifiedGmbLocationId, paginationPath, searchable } = props;
  const { isLoading, queries, pagination, gmbLocalPosts, refresh, handleSearch } =
    useLocalPostSearch({
      specifiedGmbLocationId,
      specifiedCompanyId: undefined,
      requestLocalPosts: RequestGmbLocalPost.list,
    });

  useEffect(() => {
    refresh(queries.currentPage, {
      topicType: queries.topicType,
      companyId: queries.companyId,
      gmbLocationId: queries.gmbLocationId,
    });
  }, [queries.currentPage, queries.topicType, queries.companyId, queries.gmbLocationId]);

  if (gmbLocalPosts === undefined) {
    return <></>;
  }

  return (
    <>
      <SearchBox
        selectedItems={{
          companyId: queries.companyId,
          gmbLocationId: queries.gmbLocationId,
          selectedTopicType: queries.topicType,
        }}
        searchableItem={['gmbLocation']}
        searchable={searchable === undefined ? true : searchable}
        refreshLocalPosts={refresh}
        handleCreateLocalPost={RequestGmbLocalPost.create}
        handleShowGmbLocation={RequestGmbLocation.show}
        handleGmbLocationRequest={RequestGmbLocation.search}
        handleAfterSearch={handleSearch}
        handleGmbLocationKeyword={RequestGmbLocation.keywords}
      />
      <LocalPostList
        queries={queries}
        isLoading={isLoading}
        pagination={pagination}
        gmbLocalPosts={gmbLocalPosts}
        handleGmbLocationKeyword={RequestGmbLocation.keywords}
        handleShowGmbLocation={RequestGmbLocation.show}
        handleGmbLocalPostUpdate={RequestGmbLocalPost.update}
        handleGmbLocalPostDelete={RequestGmbLocalPost.destroy}
        searchable={searchable === undefined ? true : searchable}
        paginationPath={paginationPath}
        handleSearch={refresh}
      />
    </>
  );
};

export default memo(PartnerLocalPostList);
