import React, { memo, useState } from 'react';
import Switch from 'react-bootstrap/Switch';
import { RequestGmbLocation } from '../../../../requests/partner/gmb-locations';

type Props = {
  gmbLocationId: number;
  isTamperPrevention: boolean;
  isListedStore: boolean;
};

const ProfileRaw: React.FC<Props> = (props: Props) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isTamperPrevention, setIsTamperPrevention] = useState<boolean>(props.isTamperPrevention);

  const updateGmbLocationSetting = async () => {
    setDisabled(true);
    try {
      const gmbLocationSetting = await RequestGmbLocation.updateSetting(props.gmbLocationId, {
        tamperPrevention: !isTamperPrevention,
      });
      setIsTamperPrevention(gmbLocationSetting.tamperPrevention);
    } finally {
      setDisabled(false);
    }
  };
  return (
    <>
      {!props.isListedStore && (
        <div className="d-flex ml-1">
          <Switch
            disabled={disabled}
            checked={isTamperPrevention}
            id="isTamperPrevention"
            name="isTamperPrevention"
            onChange={updateGmbLocationSetting}
            label={
              <div className="d-flex align-self-baseline">
                <div>改ざん防止機能</div>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default memo(ProfileRaw);
