import { ApiClient } from '../client';
import { Company } from '../../models/company';

export type SearchCompanyParams = {
  companyId?: number;
  name?: string;
};

export class RequestCompany {
  static search = async (params: SearchCompanyParams): Promise<Company[]> => {
    const res = await ApiClient.get(`/partner/api/companies/search`, { params });
    return res.data;
  };
}
