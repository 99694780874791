import { TimeOfDay } from '../share/time-of-day';

export type SUNDAY = 'SUNDAY';
export type MONDAY = 'MONDAY';
export type TUESDAY = 'TUESDAY';
export type WEDNESDAY = 'WEDNESDAY';
export type THURSDAY = 'THURSDAY';
export type FRIDAY = 'FRIDAY';
export type SATURDAY = 'SATURDAY';

export type DayOfTheWeek = SUNDAY | MONDAY | TUESDAY | WEDNESDAY | THURSDAY | FRIDAY | SATURDAY;

export const DayOfTheWeekJP = {
  SUNDAY: '日曜日',
  MONDAY: '月曜日',
  TUESDAY: '火曜日',
  WEDNESDAY: '水曜日',
  THURSDAY: '木曜日',
  FRIDAY: '金曜日',
  SATURDAY: '土曜日',
};

export type Period = {
  closeDay: DayOfTheWeek;
  closeTime: TimeOfDay;
  openDay: DayOfTheWeek;
  openTime: TimeOfDay;
};

export type AllWeekHoursPeriods = {
  SUNDAY: Period[];
  MONDAY: Period[];
  TUESDAY: Period[];
  WEDNESDAY: Period[];
  THURSDAY: Period[];
  FRIDAY: Period[];
  SATURDAY: Period[];
};

export const handleDevideHoursPeriods = (periods: Period[]): AllWeekHoursPeriods => {
  const sundayHoursPeriods = periods.filter((v) => v.openDay === 'SUNDAY');

  const mondayHoursPeriods = periods.filter((v) => v.openDay === 'MONDAY');

  const thuesdayHoursPeriods = periods.filter((v) => v.openDay === 'TUESDAY');

  const wednesdayHoursPeriods = periods.filter((v) => v.openDay === 'WEDNESDAY');

  const thursdayHoursPeriods = periods.filter((v) => v.openDay === 'THURSDAY');

  const fridayHoursPeriods = periods.filter((v) => v.openDay === 'FRIDAY');

  const saturdayHoursPeriods = periods.filter((v) => v.openDay === 'SATURDAY');

  return {
    SUNDAY: sundayHoursPeriods,
    MONDAY: mondayHoursPeriods,
    TUESDAY: thuesdayHoursPeriods,
    WEDNESDAY: wednesdayHoursPeriods,
    THURSDAY: thursdayHoursPeriods,
    FRIDAY: fridayHoursPeriods,
    SATURDAY: saturdayHoursPeriods,
  };
};
