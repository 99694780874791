import React, { memo } from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { Attribute } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

type Props = {
  attribute: Attribute;
  targetAttributeValue?: AttributeValue;
  readonly: boolean;
  handleAttributeValue?: (v: AttributeValue) => void;
};

const AttributeBoolDisplay: React.FC<Props> = (props) => {
  const setNextValue = (targetAttributeValue?: AttributeValue) => {
    if (targetAttributeValue === undefined) {
      return [true];
    }
    if (targetAttributeValue.values[0]) {
      return [false];
    } else {
      return [];
    }
  };
  return (
    <Button
      variant={'light'}
      disabled={props.readonly}
      onClick={() => {
        if (props.handleAttributeValue == undefined) {
          return;
        }
        props.handleAttributeValue({
          name: 'attributes/' + props.attribute.attributeId,
          valueType: props.attribute.valueType,
          repeatedEnumValue: {
            setValues: [],
            unsetValues: [],
          },
          uriValues: [],
          values: setNextValue(props.targetAttributeValue),
        });
      }}
    >
      {props.attribute.displayName}
      {props.targetAttributeValue ? (
        <>
          <Badge
            style={{ marginLeft: '10px' }}
            variant={props.targetAttributeValue.values[0] ? 'success' : 'danger'}
          >
            {props.targetAttributeValue.values[0] ? '◯' : 'X'}
          </Badge>
        </>
      ) : (
        <Badge style={{ marginLeft: '10px' }} variant="secondary">
          -
        </Badge>
      )}
    </Button>
  );
};

export default memo(AttributeBoolDisplay);
