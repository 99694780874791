// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '../bootstrap_custom';
import '../../stylesheets/compnay/application';
import ReactRailsUJS from 'react_ujs';

Rails.start();
ActiveStorage.start();
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);
