import React, { memo, useState } from 'react';
import NewButton from '../../general/button/NewButton';

import { LocalPostTopicType, LocalPostTopicTypeJp } from '../../../models/gmb-local-bulk-post';

import { SaveLocalBulkPostParams } from '../../../requests/company/gmb-local-bulk-post';

import StandardModal from './modals/StandardModal';
import EventModal from './modals/EventModal';
import OfferModal from './modals/OfferModal';
import AlertModal from './modals/Alert';

type Props = {
  companyId: number;
  topicType: LocalPostTopicType;
  handleCreateLocalPost: (params: SaveLocalBulkPostParams) => Promise<void>;
  handleAferPosted: () => Promise<void>;
};

const CreateButton: React.FC<Props> = (props: Props) => {
  const { companyId, topicType, handleCreateLocalPost, handleAferPosted } = props;

  const [isShow, setIsShow] = useState<boolean>(false);

  const handleModal = () => {
    setIsShow(!isShow);
  };

  const handleCreate = async (params: SaveLocalBulkPostParams) => {
    await handleCreateLocalPost(params);
    handleAferPosted();
    setIsShow(!isShow);
  };

  return (
    <>
      <NewButton onClick={handleModal}>
        {`${LocalPostTopicTypeJp[props.topicType]}の一括投稿`}
      </NewButton>
      {topicType == 'STANDARD' && (
        <StandardModal
          isShow={isShow}
          companyId={companyId}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
      {topicType == 'EVENT' && (
        <EventModal
          isShow={isShow}
          companyId={companyId}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
      {topicType == 'OFFER' && (
        <OfferModal
          isShow={isShow}
          companyId={companyId}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
      {topicType == 'ALERT' && (
        <AlertModal
          isShow={isShow}
          companyId={companyId}
          handleModal={handleModal}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
};

export default memo(CreateButton);
