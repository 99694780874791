import React, { memo, useState } from 'react';

import { AttributeGroup } from '../../../../models/gmb-location/attribute-group';
import { Attribute } from '../../../../models/gmb-location';

import BaseRaw from './BaseRaw';
import AttributeDisplay from '../partial/AttributeDisplay';
import AtributeModal from '../form-modal/AtributeModal';

type Props = {
  label: string;
  attributeValues: Attribute[];
  attributeGroups: AttributeGroup[];
  handleUpdate?: (params: Attribute[]) => Promise<void>;
  editable?: boolean;
};

const AttributeRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: Attribute[]): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        cardNode={props.attributeGroups.map((attributeGroup: AttributeGroup, index: number) => {
          return (
            <div style={{ marginTop: '30px' }} key={`attribute_group_${index}`}>
              <h5>{attributeGroup.displayName}</h5>
              <AttributeDisplay
                key={`attribute_display_${index}`}
                attributes={attributeGroup.attributes}
                attributeValues={props.attributeValues}
                readonly={true}
              />
            </div>
          );
        })}
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <AtributeModal
          isShow={isShow}
          label={props.label}
          attributeGroups={props.attributeGroups}
          attributeValues={props.attributeValues}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(AttributeRaw);
