import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import {
  LocalPost,
  LocalPostTopicType,
  LocalPostEventValue,
  LocalPostOfferValue,
  eventStartDateTime,
  eventEndDateTime,
} from '../../../models/gmb-local-post';

import moment from '../../../utils/moment-ja';

import styled from 'styled-components';

type Props = {
  isLoading: boolean;
  topicType: LocalPostTopicType;
  localPost: LocalPost;
  handleModal: () => void;
};

const LocalPostCard = styled(Card)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const EventCardText = styled(Card.Text)`
  font-size: 12px;
  display: -webkit-box;
`;

const CardHeaderSpan = styled.span`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const ImageWrap = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 30%;
`;

const ImageWrapImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  object-fit: cover;
`;

const UpdateDate = styled.p`
  text-align: right;
  font-size: 12px;
`;

const BaseRaw: React.FC<Props> = (props) => {
  const { topicType } = props;
  const imageUrl = props.localPost.media[0]
    ? props.localPost.media[0].googleUrl
    : '/images/no_image.png';

  return (
    <LocalPostCard onClick={() => props.handleModal()}>
      <Card.Header>
        <CardHeaderSpan>投稿</CardHeaderSpan>
      </Card.Header>
      <ImageWrap>
        <ImageWrapImage src={imageUrl} />
      </ImageWrap>
      <Card.Body>
        {[LocalPostEventValue, LocalPostOfferValue].includes(topicType) && (
          <>
            <EventCardText>
              {props.localPost.event?.title || ''}
              <br />
              <br />
              開催日:{eventStartDateTime(props.localPost.event?.schedule)}
              <br />
              終了日:{eventEndDateTime(props.localPost.event?.schedule)}
            </EventCardText>
          </>
        )}
        {props.localPost.summary}
        <UpdateDate>{moment(props.localPost.updateTime).format('Y年M月D日(dd)')}</UpdateDate>
      </Card.Body>
    </LocalPostCard>
  );
};

export default memo(BaseRaw);
