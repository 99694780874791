import { TimeOfDay } from 'models/share/time-of-day';

export const buildTime = (time: TimeOfDay): string => {
  return ('0' + time.hours).slice(-2) + ':' + ('0' + (time.minutes || 0)).slice(-2);
};

export const splitTime = (stringTime: string): TimeOfDay => {
  const spritTime = stringTime.split(':');
  return {
    hours: Number(spritTime[0]),
    minutes: Number(spritTime[1]),
  };
};
