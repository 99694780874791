import { ApiClient, PagenationApiResponse } from '../client';
import { GmbLocationReview } from '../../models/gmb-location-review';
import { Pagination } from '../../models/pagination';

export type UpdateReplyParams = {
  comment: string;
};

export type ListParams = {
  gmbLocationId?: number;
  replied?: boolean;
  rate?: string;
};

export type RequestGmnLocationReviewInterface = (
  page: number,
  params: ListParams
) => Promise<PagenationApiResponse<GmbLocationReview[], Pagination>>;

export class RequestGmnLocationReview {
  static list: RequestGmnLocationReviewInterface = async (
    page: number,
    params: ListParams
  ): Promise<PagenationApiResponse<GmbLocationReview[], Pagination>> => {
    const res = await ApiClient.get(`/company/api/gmb_location_reviews?page=${page}`, { params });
    return res.data;
  };

  static latestNoReply = async (): Promise<GmbLocationReview[]> => {
    const res = await ApiClient.get(`/company/api/gmb_location_reviews/latest_no_reply`);
    return res.data;
  };

  static updateReply = async (id: number, params: UpdateReplyParams): Promise<boolean> => {
    const res = await ApiClient.post(
      `/company/api/gmb_location_reviews/${id}/update_reply`,
      params
    );
    return res.data;
  };
}
