import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseRaw from './BaseRaw';
import SpecialPeriodsTable from '../partial/SpecialPeriodsTable';
import SpecialHourModal from '../form-modal/SpecialHourModal';

import {
  SpecialHourPeriod,
  handleDevideSpecialHourPeriods,
} from '../../../../models/gmb-location/special-hour-period';

import { CompanySpecialHoursTemplate } from '../../../../models/company-special-hours-template';

type SpecialHourTemplate = CompanySpecialHoursTemplate;

type Props = {
  label: string;
  specialHourPeriods: SpecialHourPeriod[];
  handleUpdate?: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
  templateTitle?: string;
  specialHourTemplates?: SpecialHourTemplate[];
  editable?: boolean;
};

const SpecialHoursRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const allSpecialHourPeriods = handleDevideSpecialHourPeriods(props.specialHourPeriods);
  const handleSubmit = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params, templateTitle);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        cardNode={
          allSpecialHourPeriods.length <= 0 ? (
            <p>設定がありません</p>
          ) : (
            <SpecialPeriodsTable
              allSpecialHourPeriods={allSpecialHourPeriods}
              readonly={true}
              holidayVisible={true}
            />
          )
        }
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <SpecialHourModal
          isShow={isShow}
          templateTitle={props.templateTitle}
          templateList={props.specialHourTemplates}
          label={props.label}
          specialHourPeriods={props.specialHourPeriods}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(SpecialHoursRaw);
