import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import FileuploadArea from '../../general/FileuploadArea';
import { AttachmentModel } from '../../../models/share/attachment-model';

type Props = {
  selectingPostImage: AttachmentModel | null | undefined;
  handleAttachImageFile: (file: AttachmentModel) => Promise<void>;
};

const ImageUpload: React.FC<Props> = (props) => {
  const { selectingPostImage, handleAttachImageFile } = props;
  return (
    <Form.Group>
      <Form.Label>投稿写真</Form.Label>
      <div style={{ width: '100%' }}>
        <FileuploadArea
          fileId={`localpost_image_area`}
          attachmentFile={selectingPostImage}
          handleAttachFile={(file: AttachmentModel): void => {
            handleAttachImageFile(file);
          }}
        >
          {selectingPostImage?.url ? (
            <Image id={`localpost_image`} fluid src={selectingPostImage?.url} />
          ) : (
            <Card className="text-center" style={{ border: 'none' }}>
              <Card.Body>
                <Card.Text>写真を追加する</Card.Text>
              </Card.Body>
            </Card>
          )}
        </FileuploadArea>
      </div>
    </Form.Group>
  );
};

export default memo(ImageUpload);
