import { useState } from 'react';
import { AttachmentModel } from '../models/share/attachment-model';

import { RequestGoogleMedias } from '../requests/google-medias';

type useUploadImageData = {
  publicUrl: string | null;
  selectingPostImage: AttachmentModel | null;
  setSelectionPostImage: (attachment: AttachmentModel | null) => void;
  handleAttachImageFile: (attachment: AttachmentModel) => Promise<void>;
};

export const useUploadImage = (): useUploadImageData => {
  const [publicUrl, setPublicUrl] = useState<string | null>(null);
  const [selectingPostImage, setSelectionPostImage] = useState<AttachmentModel | null>(null);

  const handleAttachImageFile = async (attachment: AttachmentModel): Promise<void> => {
    const exts = ['image/jpeg', 'image/png', 'image/gif'];
    const regExp = new RegExp(exts.join('|'));
    if (regExp.test(attachment.file.type) === false) {
      alert('選択できるファイルはjpeg, png, gifのみです');
      return;
    }
    const result = await RequestGoogleMedias.postPresignedUrl();
    await RequestGoogleMedias.putFile(result.presignedUrl, attachment);
    setPublicUrl(result.publicLink);
    setSelectionPostImage(attachment);
  };

  return {
    publicUrl,
    selectingPostImage,
    setSelectionPostImage,
    handleAttachImageFile,
  };
};
