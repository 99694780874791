import React, { memo, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Gmap, { handleSearchFromPostalCode } from '../../../../components/general/Gmap';

import { Address } from '../../../../models/gmb-location';
import { validatePostalCode } from '../../../../utils/validate';

import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseModal from '../../BaseModal';

const containerStyle = {
  width: '100%',
  height: '400px',
};

type Props = {
  isShow: boolean;
  label: string;
  addressString: string;
  address: Address;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const AddressModal: React.FC<Props> = (props) => {
  const [postalCode, setPostalCode] = useState<string>(props.address.postalCode);
  const [administrativeArea, setAdministrativeArea] = useState<string>(
    props.address.administrativeArea
  );
  const [addressFirst, setAddressFirst] = useState<string | undefined>(
    props.address.addressLines[0]
  );
  const [addressSecond, setAddressSecond] = useState<string | undefined>(
    props.address.addressLines[1]
  );
  const [addressThird, setAddressThird] = useState<string | undefined>(
    props.address.addressLines[2]
  );
  const [latLang, setLatLang] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);

  const handleChangPostalCodeInput = (value: string) => {
    setPostalCode(value);
  };
  const handleChangAddressFirstInput = (value: string) => {
    setAddressFirst(value);
  };
  const handleChangAddressSecondInput = (value: string) => {
    setAddressSecond(value);
  };
  const handleChangAddressThirdInput = (value: string) => {
    setAddressThird(value);
  };

  const handleBlurePostalCode = async () => {
    if (validatePostalCode(postalCode)) {
      handleSearchAddressFromPostalCode(postalCode);
    }
  };

  const handleSearchAddressFromPostalCode = async (value: string) => {
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    const result = await handleSearchFromPostalCode(value);
    setAdministrativeArea(result.prefecture);
    setLatLang({
      lat: result.latLng.lat,
      lng: result.latLng.lng,
    });
  };

  const handleClose = () => {
    setPostalCode(props.address.postalCode);
    if (props.address.addressLines[0] || props.address.addressLines[0] != '') {
      setAddressFirst(props.address.addressLines[0]);
    }
    if (props.address.addressLines[1] || props.address.addressLines[1] != '') {
      setAddressSecond(props.address.addressLines[1] || '');
    }
    if (props.address.addressLines[2] || props.address.addressLines[2] != '') {
      setAddressThird(props.address.addressLines[2] || '');
    }
    props.handleModal();
  };

  useEffect(() => {
    handleSearchAddressFromPostalCode(props.addressString);
  }, [props.addressString]);

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          let updatedAddress: string[] = [];
          if (addressFirst) {
            updatedAddress = updatedAddress.concat(addressFirst);
          }
          if (addressSecond) {
            updatedAddress = updatedAddress.concat(addressSecond);
          }
          if (addressThird) {
            updatedAddress = updatedAddress.concat(addressThird);
          }
          await props.handleUpdate({
            storefrontAddress: {
              addressLines: updatedAddress,
              administrativeArea,
              postalCode,
              languageCode: props.address.languageCode,
              regionCode: props.address.regionCode,
            },
          });
          await handleSearchAddressFromPostalCode(
            `${postalCode} ${administrativeArea} ${addressFirst} ${addressSecond || ''} ${
              addressThird || ''
            }`
          );
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>郵便番号</Form.Label>
            <Form.Control
              type="text"
              value={postalCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangPostalCodeInput(e.target.value);
              }}
              onBlur={handleBlurePostalCode}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control type="text" disabled={true} value={administrativeArea} />
          </Form.Group>
          <Form.Group>
            <Form.Label>住所1</Form.Label>
            <Form.Control
              type="text"
              value={addressFirst}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangAddressFirstInput(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>住所2</Form.Label>
            <Form.Control
              type="text"
              value={addressSecond}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangAddressSecondInput(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>住所3</Form.Label>
            <Form.Control
              type="text"
              value={addressThird}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangAddressThirdInput(e.target.value);
              }}
            />
          </Form.Group>
          <Gmap containerStyle={containerStyle} latLang={latLang} />
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(AddressModal);
