import React, { memo, useState } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  isShow: boolean;
  centered?: boolean;
  children: React.ReactNodeArray;
  alert?: boolean;
  buttonText?: string;
  handleClose: () => void;
  handleUpdate?: () => Promise<void>;
  handleDelete?: () => Promise<void>;
};

const Modal: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleClose = () => {
    props.handleClose();
  };
  const handleUpdate = async () => {
    setIsLoading(true);
    if (props.handleUpdate) {
      if (!props.alert) {
        await props.handleUpdate();
        return;
      }

      if (
        props.alert &&
        confirm(`${props.buttonText ? props.buttonText : '保存'}してもよろしいですか？`)
      ) {
        await props.handleUpdate();
      } else {
        alert('キャンセルしました');
      }
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    if (props.handleDelete && confirm('削除してもよろしいですか？')) {
      await props.handleDelete();
      props.handleClose();
    }
    setIsLoading(false);
  };

  return (
    <BootstrapModal
      show={props.isShow}
      onHide={handleClose}
      size="xl"
      centered={props.centered === undefined ? true : props.centered}
    >
      {isLoading ? (
        <BootstrapModal.Body style={{ textAlign: 'center' }}>
          <Spinner animation="border" role="status" />
        </BootstrapModal.Body>
      ) : (
        <>
          {props.children}
          <BootstrapModal.Footer>
            <div
              className={`d-flex ${
                props.handleDelete ? 'justify-content-between' : 'justify-content-end'
              }`}
              style={{ width: '100%' }}
            >
              {props.handleDelete && (
                <div>
                  <Button variant="danger" onClick={handleDelete}>
                    削除
                  </Button>
                </div>
              )}
              <div>
                <Button variant="secondary" onClick={() => handleClose()}>
                  閉じる
                </Button>
                {props.handleUpdate && (
                  <Button
                    variant="primary"
                    className="ml-3"
                    onClick={async () => {
                      setIsLoading(true);
                      try {
                        await handleUpdate();
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                  >
                    {props.buttonText ? props.buttonText : '保存'}
                  </Button>
                )}
              </div>
            </div>
          </BootstrapModal.Footer>
        </>
      )}
    </BootstrapModal>
  );
};

export default memo(Modal);
