import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';
import { GmbLocationSimpleReview } from '../../../models/gmb-location-review';
import Star from '../icons/Star';

import styled from 'styled-components';
import moment from '../../../utils/moment-ja';

type Props = {
  isLoading: boolean;
  gmbLocationSimpleReview: GmbLocationSimpleReview;
  handleModal: () => void;
};

const ReviewCard = styled(Card)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const CardText = styled(Card.Text)`
  height: 128px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
`;

const CardHeaderSpan = styled.span`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const SubInfo = styled.p`
  text-align: right;
  font-size: 12px;
`;

const GmbReviewSimpleCard: React.FC<Props> = (props) => {
  const ratings = Array.from(new Array(props.gmbLocationSimpleReview.starRating)).map(
    (_v, i) => i + 1
  );
  return (
    <>
      <ReviewCard onClick={() => props.handleModal()}>
        <Card.Header>
          <CardHeaderSpan>レビュー</CardHeaderSpan>
        </Card.Header>
        <Card.Body>
          <div className="mb-2 text-warning">
            {ratings.map((_rating, index) => (
              <Star key={`star_${index}`} />
            ))}
          </div>
          <CardText>
            {props.gmbLocationSimpleReview.comment || 'コメントのないレビューです'}
          </CardText>
          <SubInfo>{props.gmbLocationSimpleReview.reviewer?.displayName}</SubInfo>
          <SubInfo>
            {moment(props.gmbLocationSimpleReview.updateTime).format('Y年M月D日(dd)')}
          </SubInfo>
        </Card.Body>
      </ReviewCard>
      <ReviewCard onClick={() => props.handleModal()} className="mt-3">
        <Card.Header>
          <CardHeaderSpan>返信内容</CardHeaderSpan>
        </Card.Header>
        <Card.Body>
          <CardText>
            {props.gmbLocationSimpleReview.reviewReply?.comment || '返信内容はありません'}
          </CardText>
        </Card.Body>
      </ReviewCard>
    </>
  );
};

export default memo(GmbReviewSimpleCard);
