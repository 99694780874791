import React, { memo } from 'react';
import Button from '../Button';

type NewButtonProps = {
  children?: React.ReactElement | string;
  onClick: () => void;
};

const NewButton: React.FC<NewButtonProps> = (props) => {
  const { children, onClick } = props;
  return (
    <Button variant="dark" onClick={onClick}>
      <div className="d-flex justify-content-center align-items-center">
        <i className="fas fa-plus-circle"></i>
        <div className="ml-2">{children}</div>
      </div>
    </Button>
  );
};

export default memo(NewButton);
