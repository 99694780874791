import React, { memo } from 'react';
import { GmbLocalPostEvaluation } from '../../../../models/gmb-local-evaluation';
import EvaluationConversionCells from '../TableData/EvaluationConversionCells';

type Props = {
  data: GmbLocalPostEvaluation | undefined;
  hasKeywordContent: boolean;
};

const PostEvaluationTableBody: React.FC<Props> = (props: Props) => {
  const { data, hasKeywordContent } = props;

  const postKeywordContent = (data?: GmbLocalPostEvaluation) => {
    if (hasKeywordContent) {
      return (
        <>
          <tr>
            <th
              className="text-left  align-middle"
              rowSpan={data?.keywordContent ? data.keywordContent.details.length + 1 : 0}
            >
              指定キーワードの出現有無（28日間）
            </th>
          </tr>
          {data?.keywordContent?.details.map((item, index: number) => (
            <tr key={index}>
              <td className="text-left align-middle">{item.keyword}</td>
              <td className="text-center align-middle">{item.keywordRank}</td>
              <EvaluationConversionCells
                evaluation={item.evaluation}
                achievement={item.achievement}
              />
            </tr>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {postKeywordContent(data)}
      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          平均文字数（最新30投稿）
        </th>
        <EvaluationConversionCells
          evaluation={data?.amountOfText.evaluation}
          achievement={data?.amountOfText.achievement}
        />
      </tr>
      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          投稿有無（7日間）
        </th>
        <EvaluationConversionCells
          evaluation={data?.numberOfPostsThisWeek.evaluation}
          achievement={data?.numberOfPostsThisWeek.achievement}
        />
      </tr>
      <tr>
        <th className="text-left align-middle" colSpan={hasKeywordContent ? 3 : 2}>
          投稿の継続週数
        </th>
        <EvaluationConversionCells
          evaluation={data?.consecutivePosting.evaluation}
          achievement={data?.consecutivePosting.achievement}
        />
      </tr>
    </>
  );
};

export default memo(PostEvaluationTableBody);
