import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { validatePhoneNumber } from '../../../../utils/validate';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { Alert } from '../../../../service/alert';

import BaseModal from '../../BaseModal';
import { PhoneNumbers } from 'models/gmb-location';

type Props = {
  isShow: boolean;
  label: string;
  phoneNumbers?: PhoneNumbers | null;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const PrimaryPhoneModal: React.FC<Props> = (props) => {
  const [primaryPhone, setPrimaryPhone] = useState<string>(props.phoneNumbers?.primaryPhone || '');
  const handleChangeInput = (value: string) => {
    setPrimaryPhone(value);
  };
  const handleClose = () => {
    setPrimaryPhone(props.phoneNumbers?.primaryPhone || '');
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          if (validatePhoneNumber(primaryPhone)) {
            await props.handleUpdate({
              phoneNumbers: {
                primaryPhone,
                additionalPhones: props.phoneNumbers?.additionalPhones || [],
              },
            });
            props.handleModal();
          } else {
            throw Alert.danger('電話番号の形式が正しくありません');
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={primaryPhone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeInput(e.target.value);
            }}
          />
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(PrimaryPhoneModal);
