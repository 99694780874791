type BaseEvaluation = {
  evaluation: number;
  achievement: string;
};

type KeywordEvaluationDetail = BaseEvaluation & {
  keyword: string;
  keywordRank: number | string;
};

type KeywordEvaluation = BaseEvaluation & {
  evaluation: number;
  details: [KeywordEvaluationDetail];
};

type CategoryEvaluation = BaseEvaluation & {
  category: string;
};

// 総合評価
export type GmbLocalTotalAverageScoreEvaluation = [
  {
    name: string;
    type: string;
    evaluation: number;
  }
];

// 投稿評価
export type GmbLocalPostEvaluation = {
  amountOfText: BaseEvaluation;
  consecutivePosting: BaseEvaluation;
  keywordContent: KeywordEvaluation;
  numberOfPostsThisWeek: BaseEvaluation;
};

// 写真評価
export type GmbLocalMediaImageEvaluation = {
  baseImage: BaseEvaluation;
  categoryImage: [CategoryEvaluation];
  numberOfImagesThisWeek: BaseEvaluation;
  consecutiveImagePosting: BaseEvaluation;
};

// 口コミ評価
export type GmbLocalReviewEvaluation = {
  reviewNumber: BaseEvaluation;
  avgReplyPeriod: BaseEvaluation;
  userKeywordContent: KeywordEvaluation;
  replyKeywordContent: KeywordEvaluation;
  replyRate: BaseEvaluation;
  avgReviewScore: BaseEvaluation;
};

// 基本情報
export type GmbLocalBasicEvaluation = {
  amountOfText: BaseEvaluation;
  keywordContent: KeywordEvaluation;
};

export const GmbLocalEvaluationPostValue = 'POST';
export const GmbLocalEvaluationMediaImageValue = 'MEDIA_IMAGE';
export const GmbLocalEvaluationReviewValue = 'REVIEW';
export const GmbLocalEvaluationBasicValue = 'BASIC';

type GmbLocalEvaluationStandardType = typeof GmbLocalEvaluationPostValue;
type GmbLocalEvaluationMediaImageType = typeof GmbLocalEvaluationMediaImageValue;
type GmbLocalEvaluationReviewType = typeof GmbLocalEvaluationReviewValue;
type GmbLocalEvaluationInfoType = typeof GmbLocalEvaluationBasicValue;

export type GmbLocalEvaluationType =
  | GmbLocalEvaluationStandardType
  | GmbLocalEvaluationMediaImageType
  | GmbLocalEvaluationReviewType
  | GmbLocalEvaluationInfoType;

export const GmbLocalEvaluationTypeJp = {
  [GmbLocalEvaluationPostValue]: '投稿',
  [GmbLocalEvaluationMediaImageValue]: '写真',
  [GmbLocalEvaluationReviewValue]: '口コミ',
  [GmbLocalEvaluationBasicValue]: '基本情報',
};
