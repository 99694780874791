export const evaluationConversion = (evaluation: number | undefined): string => {
  if (evaluation === 0) {
    return '×';
  } else if (evaluation === 1) {
    return '△';
  } else if (evaluation === 2) {
    return '○';
  } else if (evaluation === 3) {
    return '◎';
  }
  return '-';
};
