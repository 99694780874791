import React, { memo } from 'react';

import { GmbLocation } from '../../../../models/gmb-location';
import Form from '../../../general/gmb-locations/Form';
import { RequestGmbLocation } from '../../../../requests/company/gmb-locations';

import { CompanyRegularHoursTemplate } from '../../../../models/company-regular-hours-template';
import { CompanySpecialHoursTemplate } from '../../../../models/company-special-hours-template';
import { CompanyMoreHoursTemplate } from '../../../../models/company-more-hours-template';
import { Attribute } from 'models/gmb-location';

type Props = {
  gmbLocationId: number;
  isListedStore: boolean;
  location: GmbLocation;
  locationAttributes: Attribute[];
  regularHourTemplates?: CompanyRegularHoursTemplate[];
  specialHourTemplates?: CompanySpecialHoursTemplate[];
  moreHourTemplates?: CompanyMoreHoursTemplate[];
  regularHourTemplateSettingPath?: string;
};

const GmbLocationForm: React.FC<Props> = (props) => {
  return (
    <Form
      gmbLocationId={props.gmbLocationId}
      isListedStore={props.isListedStore}
      location={props.location}
      locationAttributes={props.locationAttributes}
      handleUpdate={RequestGmbLocation.update}
      handleUpdateLocationAttributes={RequestGmbLocation.updateLocationAttributes}
      regularHourTemplates={props.regularHourTemplates}
      regularHourTemplateSettingPath={props.regularHourTemplateSettingPath}
      specialHourTemplates={props.specialHourTemplates}
      moreHourTemplates={props.moreHourTemplates}
      handleGmbLocationKeyword={RequestGmbLocation.keywords}
    />
  );
};

export default memo(GmbLocationForm);
