import React, { memo } from 'react';

import { GmbLocalPost } from '../../../../models/gmb-local-post';

import { RequestGmbLocalPost } from '../../../../requests/company/gmb-local-posts';
import { RequestGmbLocation } from '../../../../requests/company/gmb-locations';

import LatestLocalPosts from '../../../general/gmb-local-posts/LatestLocalPosts';

type Props = {
  localPostListPath: string;
};

const HomeLatestLocalPosts: React.FC<Props> = (props: Props) => {
  const refresh = async (): Promise<GmbLocalPost[]> => {
    return await RequestGmbLocalPost.latestPosts();
  };

  return (
    <LatestLocalPosts
      localPostListPath={props.localPostListPath}
      fetchLatestLocalPosts={refresh}
      handleShowGmbLocation={RequestGmbLocation.show}
      handleGmbLocationKeyword={RequestGmbLocation.keywords}
    />
  );
};

export default memo(HomeLatestLocalPosts);
