import React, { memo, useEffect } from 'react';

import {
  RequestGmnLocationReview,
  UpdateReplyParams,
} from '../../../../requests/partner/gmb-location-reviews';

import ReviewList from '../../../general/gmb-location-reviews/ReviewList';
import ReviewSearchBox from '../../../general/gmb-location-reviews/ReviewSearchBox';
import Spinner from 'react-bootstrap/Spinner';

import { RequestGmbLocation } from '../../../../requests/partner/gmb-locations';
import { useGmbLocationReviewSearch } from '../../../../hooks/useGmbLocationReviewSearch';

type Props = {
  specifiedGmbLocationId?: number;
  disabledSync?: boolean;
};

const CompanyReviewList: React.FC<Props> = (props) => {
  const { disabledSync, specifiedGmbLocationId } = props;
  const { isLoading, queries, pagination, gmbLocationReviews, refresh, updateQuery } =
    useGmbLocationReviewSearch({
      specifiedGmbLocationId,
      requestGmbLocationReviews: RequestGmnLocationReview.list,
    });

  useEffect(() => {
    refresh(queries.currentPage, {
      gmbLocationId: queries.gmbLocationId,
      replied: queries.replied,
      rate: queries.rate,
    });
  }, [queries.currentPage, queries.gmbLocationId, queries.replied, queries.rate]);

  const handleUpdateReply = async (id: number, params: UpdateReplyParams): Promise<void> => {
    await RequestGmnLocationReview.updateReply(id, params);
  };

  if (gmbLocationReviews === undefined) {
    return <Spinner animation="border" role="status" />;
  }

  return (
    <>
      <ReviewSearchBox
        selectedItems={{
          gmbLocationId: queries.gmbLocationId,
          rate: queries.rate,
          replied: queries.replied,
        }}
        updateQuery={updateQuery}
        disabledSync={disabledSync}
        handleGmbLocationReviewAsync={RequestGmnLocationReview.sync}
        handleGmbLocationRequest={RequestGmbLocation.search}
      />
      <ReviewList
        isLoading={isLoading}
        queries={queries}
        pagination={pagination}
        gmbLocationReviews={gmbLocationReviews}
        fetchLocationReviews={refresh}
        handleUpdateReply={handleUpdateReply}
        paginationPath={'/partner/gmb_location_reviews'}
        handleGmbLocationKeyword={RequestGmbLocation.keywords}
      />
    </>
  );
};

export default memo(CompanyReviewList);
