import {
  GmbLocalBasicEvaluation,
  GmbLocalMediaImageEvaluation,
  GmbLocalPostEvaluation,
  GmbLocalReviewEvaluation,
  GmbLocalTotalAverageScoreEvaluation,
} from 'models/gmb-local-evaluation';
import { ApiClient } from '../client';

export class RequestGmbLocalEvaluation {
  // 総合評価API
  static totalAverageScoreEvaluation = async (
    id: number
  ): Promise<GmbLocalTotalAverageScoreEvaluation> => {
    const res = await ApiClient.get(
      `/partner/api/gmb_locations/${id}/total_avg_scores_evaluations`
    );
    return res.data;
  };

  // 投稿評価API
  static postEvaluation = async (id: number): Promise<GmbLocalPostEvaluation> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/gmb_local_post_evaluation`);
    return res.data;
  };
  // 写真評価API
  static mediaImageEvaluation = async (id: number): Promise<GmbLocalMediaImageEvaluation> => {
    const res = await ApiClient.get(
      `/partner/api/gmb_locations/${id}/gmb_location_media_image_evaluation`
    );
    return res.data;
  };
  // 口コミ評価API
  static reviewEvaluation = async (id: number): Promise<GmbLocalReviewEvaluation> => {
    const res = await ApiClient.get(
      `/partner/api/gmb_locations/${id}/gmb_location_review_evaluation`
    );
    return res.data;
  };
  // 基本情報API
  static basicEvaluation = async (id: number): Promise<GmbLocalBasicEvaluation> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/gmb_location_evaluation`);
    return res.data;
  };
}
