import React, { memo } from 'react';

import { RegularHours } from '../../../../models/gmb-location';
import { CompanyRegularHoursTemplate } from '../../../../models/company-regular-hours-template';

import { RequestCompanyRegularHoursTemplate } from '../../../../requests/company/company-regular-hours-template';
import { UpdateGmbLocationParams } from '../../../../requests/company/gmb-locations';

import Form from '../../../general/regular-hours-templates/Form';

type Props = {
  regularHours: RegularHours;
  redirectUrl?: string;
  regularHoursTemplateId?: number;
  templateTitle?: string;
};

const RegularHoursForm: React.FC<Props> = (props) => {
  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle: string,
    regularHoursTemplateId?: number
  ): Promise<CompanyRegularHoursTemplate> => {
    const updateParams = {
      title: templateTitle || '',
      regularHours: params.regularHours ? params.regularHours : { periods: [] },
    };
    return regularHoursTemplateId
      ? await RequestCompanyRegularHoursTemplate.update(regularHoursTemplateId, updateParams)
      : await RequestCompanyRegularHoursTemplate.create(updateParams);
  };
  return (
    <Form
      regularHours={props.regularHours}
      handleUpdate={handleUpdate}
      redirectUrl={props.redirectUrl}
      regularHoursTemplateId={props.regularHoursTemplateId}
      templateTitle={props.templateTitle}
    />
  );
};

export default memo(RegularHoursForm);
