import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import Button from 'react-bootstrap/Button';

import BaseRaw from './BaseRaw';
import RegularHoursModal from '../form-modal/RegularHoursModal';
import PeriodsTable from '../partial/PeriodsTable';
import { Period, handleDevideHoursPeriods } from '../../../../models/gmb-location/period';
import { CompanyRegularHoursTemplate } from '../../../../models/company-regular-hours-template';

type RegularHourTemplate = CompanyRegularHoursTemplate;

type Props = {
  label: string;
  regularHoursPeriods: Period[];
  handleUpdate?: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
  templateTitle?: string;
  regularHourTemplates?: RegularHourTemplate[];
  regularHourTemplateSettingPath?: string;
  editable?: boolean;
};

const LocationNameRaw: React.FC<Props> = (props) => {
  const { regularHourTemplateSettingPath } = props;
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const allWeekHoursPeriods = handleDevideHoursPeriods(props.regularHoursPeriods);
  const handleSubmit = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params, templateTitle);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };

  const createActionButtons = (): React.ReactNode[] => {
    if (regularHourTemplateSettingPath) {
      return [
        <Button
          className="mr-2"
          size="sm"
          variant="dark"
          key="template"
          onClick={() => {
            window.location.href = regularHourTemplateSettingPath;
          }}
        >
          テンプレート設定
        </Button>,
      ];
    }

    return [];
  };

  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        cardNode={
          <PeriodsTable
            allWeekHoursPeriods={allWeekHoursPeriods}
            readonly={true}
            holidayVisible={true}
          />
        }
        handleModal={editable ? handleModal : undefined}
        actionButtons={createActionButtons()}
      />
      {editable ? (
        <RegularHoursModal
          isShow={isShow}
          templateTitle={props.templateTitle}
          templateList={props.regularHourTemplates}
          label={props.label}
          regularHoursPeriods={props.regularHoursPeriods}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(LocationNameRaw);
