import React, { memo, useEffect } from 'react';

import SearchBox from '../../../general/gmb-local-bulk-posts/SearchBox';
import LocalPostList from '../../../general/gmb-local-bulk-posts/LocalPostList';

import {
  RequestGmbLocalBulkPost,
  SaveLocalBulkPostParams,
} from '../../../../requests/company/gmb-local-bulk-post';

import { GmbLocalBulkPost } from '../../../../models/gmb-local-bulk-post';

import { useLocalBulkPostSearch } from '../../../../hooks/useLocalBulkPostSearch';

type Props = {
  specifiedCompanyId?: number;
  paginationPath: string;
  searchable?: boolean;
};

const PartnerLocalBulkPostList: React.FC<Props> = (props) => {
  const { specifiedCompanyId, paginationPath, searchable } = props;

  const { isLoading, queries, pagination, gmbLocalBulkPosts, refresh, handleSearch } =
    useLocalBulkPostSearch({
      specifiedCompanyId,
      requestLocalBulkPosts: RequestGmbLocalBulkPost.list,
    });

  useEffect(() => {
    refresh(queries.currentPage, {
      topicType: queries.topicType,
      companyId: queries.companyId,
    });
  }, [queries.currentPage, queries.topicType, queries.companyId]);

  const handleUpdate = async (
    gmbLocalBulkPostId: number,
    params: SaveLocalBulkPostParams
  ): Promise<GmbLocalBulkPost> => {
    const result = await RequestGmbLocalBulkPost.update(gmbLocalBulkPostId, params);
    refresh(queries.currentPage, {
      topicType: queries.topicType,
      companyId: queries.companyId,
    });
    return result;
  };

  const handleDelete = async (gmbLocalBulkPostId: number) => {
    await RequestGmbLocalBulkPost.destroy(gmbLocalBulkPostId);
    refresh(queries.currentPage, {
      topicType: queries.topicType,
      companyId: queries.companyId,
    });
  };

  if (gmbLocalBulkPosts === undefined) {
    return <></>;
  }
  return (
    <>
      <SearchBox
        searchable={searchable || true}
        searchableItem={['company']}
        selectedItems={{
          companyId: queries.companyId,
          selectedTopicType: queries.topicType,
        }}
        refreshLocalBulkPosts={refresh}
        handleCreateLocalPost={RequestGmbLocalBulkPost.create}
        handleAfterSearch={handleSearch}
      />
      <LocalPostList
        queries={queries}
        isLoading={isLoading}
        pagination={pagination}
        handleGmbLocalBulkPostUpdate={handleUpdate}
        handleGmbLocalBulkPostDelete={handleDelete}
        gmbLocalBulkPosts={gmbLocalBulkPosts}
        searchable={searchable === undefined ? true : searchable}
        paginationPath={paginationPath}
        handleSearch={refresh}
      />
    </>
  );
};

export default memo(PartnerLocalBulkPostList);
