import React, { memo, useState } from 'react';
import BaseRaw from './BaseRaw';
import Spinner from 'react-bootstrap/Spinner';

import { MoreHours } from '../../../../models/gmb-location';
import { HoursType } from '../../../../models/gmb-location/hours-type';
import {
  AllWeekHoursPeriods,
  handleDevideHoursPeriods,
} from '../../../../models/gmb-location/period';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import MoreHoursDetailModal from '../form-modal/MoreHoursDetailModal';
import PeriodsTable from '../partial/PeriodsTable';

import { CompanyMoreHoursTemplate } from '../../../../models/company-more-hours-template';
type MoreHourTemplate = CompanyMoreHoursTemplate;

type Props = {
  label: string;
  moreHours: MoreHours[];
  allHoursTypes: HoursType[];
  hoursTypes: HoursType[];
  handleUpdate?: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
  templateTitle?: string;
  moreHourTemplates?: MoreHourTemplate[];
  editable?: boolean;
};

const MoreHoursDetailRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [moreHours, setMoreHours] = useState<MoreHours[]>(props.moreHours);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getHoursType = (hoursTypeId: string): string => {
    const result = props.allHoursTypes.find(
      (hoursType: HoursType) => hoursType.hoursTypeId == hoursTypeId
    );
    return result?.jaDisplayName || '';
  };

  const handleAllWeekHoursPeriodsListWithLabel = (v: MoreHours[]) => {
    return v.map((moreHour) => {
      return {
        label: getHoursType(moreHour.hoursTypeId),
        allWeekHoursPeriods: handleDevideHoursPeriods(moreHour.periods),
      };
    });
  };
  const [allWeekHoursPeriodsListWithLabel, setAllWeekHoursPeriodsListWithLabel] = useState<
    {
      label: string;
      allWeekHoursPeriods: AllWeekHoursPeriods;
    }[]
  >(handleAllWeekHoursPeriodsListWithLabel(props.moreHours));

  const handleModal = () => {
    setIsShow(!isShow);
  };

  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string
  ): Promise<void> => {
    setIsLoading(true);
    if (params.moreHours !== undefined) {
      setMoreHours(params.moreHours);
      setAllWeekHoursPeriodsListWithLabel(handleAllWeekHoursPeriodsListWithLabel(params.moreHours));
    }
    if (props.handleUpdate !== undefined) await props.handleUpdate(params, templateTitle);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (
        <BaseRaw
          isLoading={isLoading}
          label={props.label}
          cardNode={allWeekHoursPeriodsListWithLabel.map(
            (
              allWeekHoursPeriodsWithLabel: {
                label: string;
                allWeekHoursPeriods: AllWeekHoursPeriods;
              },
              index: number
            ) => {
              return (
                <div style={{ marginTop: '10px' }} key={`periods_table_${index}`}>
                  <h5>{allWeekHoursPeriodsWithLabel.label}</h5>
                  <PeriodsTable
                    allWeekHoursPeriods={allWeekHoursPeriodsWithLabel.allWeekHoursPeriods}
                    readonly={true}
                    holidayVisible={false}
                  />
                </div>
              );
            }
          )}
          handleModal={editable ? handleModal : undefined}
        />
      )}
      {editable ? (
        <MoreHoursDetailModal
          isShow={isShow}
          label={props.label}
          allHoursTypes={props.allHoursTypes}
          moreHours={moreHours}
          hoursTypes={props.hoursTypes}
          handleModal={handleModal}
          handleUpdate={handleUpdate}
          templateTitle={props.templateTitle}
          templateList={props.moreHourTemplates}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(MoreHoursDetailRaw);
