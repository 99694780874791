import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Pagination from '../../general/Pagination';
import LocalPostCard from '../../general/gmb-local-posts/LocalPostCard';
import StandardModal from '../../general/gmb-local-posts/modals/StandardModal';
import EventModal from '../../general/gmb-local-posts/modals/EventModal';
import OfferModal from '../../general/gmb-local-posts/modals/OfferModal';
import AlertModal from '../../general/gmb-local-posts/modals/Alert';

import { GmbLocation } from '../../../models/gmb-location';
import { GmbLocalPost, LocalPostTopicType } from '../../../models/gmb-local-post';
import { Pagination as PaginationModel } from '../../../models/pagination';
import { isSP } from '../../../utils/app-util';

import {
  ListGmbLocalPostParams as PartnerListGmbLocalPostParams,
  SaveLocalPostParams as PartnerSaveLocalPostParams,
} from '../../../requests/partner/gmb-local-posts';
import {
  ListGmbLocalPostParams as CompanyListGmbLocalPostParams,
  SaveLocalPostParams as CompanySaveLocalPostParams,
} from '../../../requests/company/gmb-local-posts';

import { sliceByNumber } from '../../../utils/app-array';
import { CancelTokenSource } from 'axios';
import { useKeywords } from '../../../hooks/useKeywords';

type SearchParams = PartnerListGmbLocalPostParams | CompanyListGmbLocalPostParams;
type SaveParams = PartnerSaveLocalPostParams | CompanySaveLocalPostParams;

type Props = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    companyId?: number;
    gmbLocationId?: number;
    topicType: LocalPostTopicType;
  };
  searchable?: boolean;
  pagination: PaginationModel;
  gmbLocalPosts: GmbLocalPost[];
  paginationPath: string;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
  handleShowGmbLocation: (id: number) => Promise<GmbLocation>;
  handleGmbLocalPostUpdate: (id: number, params?: SaveParams) => Promise<GmbLocalPost>;
  handleGmbLocalPostDelete: (id: number) => Promise<void>;
  handleSearch: (page: number, params?: SearchParams) => Promise<void>;
};

const LocalPostList: React.FC<Props> = (props: Props) => {
  const {
    queries,
    pagination,
    gmbLocalPosts,
    isLoading,
    searchable,
    handleGmbLocationKeyword,
    handleShowGmbLocation,
    handleGmbLocalPostUpdate,
    handleGmbLocalPostDelete,
    handleSearch,
  } = props;

  const history = window.history;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGmbLocalPost, setSelectedGmbLocalPost] = useState<GmbLocalPost | null>(null);

  const pushState = (v: number) => {
    const topicTypeParams = `&topicType=${queries.topicType}`;
    const companyParams = searchable && queries.companyId ? `&companyId=${queries.companyId}` : '';
    const gmbLocationParams =
      searchable && queries.gmbLocationId ? `&gmbLocationId=${queries.gmbLocationId}` : '';
    history.pushState(
      null,
      '',
      `${props.paginationPath}?page=${v}${topicTypeParams}${companyParams}${gmbLocationParams}`
    );
  };

  useEffect(() => {
    pushState(queries.currentPage);
  }, [queries.currentPage, queries.topicType, queries.companyId, queries.gmbLocationId]);

  const { keywords, clearKeywords } = useKeywords({
    gmbLocationId: selectedGmbLocalPost?.gmbLocationId,
    handleGmbLocationKeyword: handleGmbLocationKeyword,
  });

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handlePage = (v: number) => {
    pushState(v);
    handleSearch(v, {
      topicType: queries.topicType,
      companyId: queries.companyId,
      gmbLocationId: queries.gmbLocationId,
    });
  };

  const handleCreate = async (params: SaveParams) => {
    if (selectedGmbLocalPost) {
      await handleGmbLocalPostUpdate(selectedGmbLocalPost.id, params);
      handleSearch(queries.currentPage, {
        topicType: queries.topicType,
        companyId: queries.companyId,
        gmbLocationId: queries.gmbLocationId,
      });
      setShowModal(false);
    }
  };
  const handleDelete = async () => {
    if (selectedGmbLocalPost) {
      await handleGmbLocalPostDelete(selectedGmbLocalPost.id);
      handleSearch(queries.currentPage, {
        topicType: queries.topicType,
        companyId: queries.companyId,
        gmbLocationId: queries.gmbLocationId,
      });
      setShowModal(false);
    }
  };
  const gmbLocationId = queries.gmbLocationId || selectedGmbLocalPost?.gmbLocationId;
  const topicType = queries.topicType;

  return (
    <>
      <div className="mt-3">
        {gmbLocalPosts.length <= 0 ? (
          <h5>投稿がありません</h5>
        ) : (
          <>
            {sliceByNumber(gmbLocalPosts, isSP() ? 2 : 5).map((splitGmbLocalPosts, index) => (
              <Row key={`gmb_local_post_view_parent_${index}`} sm={2} md={5} lg={5}>
                {splitGmbLocalPosts.map((gmbLocalPost, childIndex) => {
                  return (
                    <Col key={`gmb_local_post_view_${childIndex}`}>
                      <LocalPostCard
                        isLoading={false}
                        topicType={topicType}
                        gmbLocalPost={gmbLocalPost}
                        handleModal={() => {
                          setShowModal(true);
                          setSelectedGmbLocalPost(gmbLocalPost);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
            <Row>
              <Col lg={{ span: 9 }}>
                <Pagination
                  path="/"
                  currentPage={pagination.currentPage}
                  lastPage={pagination.totalPage}
                  onClick={handlePage}
                />
              </Col>
            </Row>
            {selectedGmbLocalPost && gmbLocationId && (
              <>
                {topicType == 'STANDARD' && (
                  <StandardModal
                    isShow={showModal}
                    gmbLocationId={gmbLocationId}
                    handleShowGmbLocation={handleShowGmbLocation}
                    gmbLocalPost={selectedGmbLocalPost}
                    templates={[]}
                    handleModal={() => {
                      clearKeywords();
                      setShowModal(false);
                      setSelectedGmbLocalPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                    keywords={keywords}
                  />
                )}
                {topicType == 'EVENT' && (
                  <EventModal
                    isShow={showModal}
                    gmbLocationId={gmbLocationId}
                    handleShowGmbLocation={handleShowGmbLocation}
                    gmbLocalPost={selectedGmbLocalPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
                {topicType == 'OFFER' && (
                  <OfferModal
                    isShow={showModal}
                    gmbLocationId={gmbLocationId}
                    handleShowGmbLocation={handleShowGmbLocation}
                    gmbLocalPost={selectedGmbLocalPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
                {topicType == 'ALERT' && (
                  <AlertModal
                    isShow={showModal}
                    gmbLocationId={gmbLocationId}
                    handleShowGmbLocation={handleShowGmbLocation}
                    gmbLocalPost={selectedGmbLocalPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(LocalPostList);
