import React, { memo } from 'react';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';

const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
  background-color: white;
`;

const ImageWrapImage = styled(Image)`
  max-width: 100%;
  height: auto;
`;

export type ImageBoxProps = {
  imageUrl: string;
};

const ImageBox: React.FC<ImageBoxProps> = (props) => {
  const { imageUrl } = props;
  return (
    <ImageWrap>
      <ImageWrapImage src={imageUrl} />
    </ImageWrap>
  );
};

export default memo(ImageBox);
