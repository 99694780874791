import React, { memo } from 'react';

import { SpecialHours } from '../../../../models/gmb-location';
import { CompanySpecialHoursTemplate } from '../../../../models/company-special-hours-template';

import { RequestCompanySpecialHoursTemplate } from '../../../../requests/company/company-special-hours-template';
import { UpdateGmbLocationParams } from '../../../../requests/company/gmb-locations';

import Form from '../../../general/special-hours-templates/Form';

type Props = {
  specialHours: SpecialHours;
  redirectUrl?: string;
  specialHoursTemplateId?: number;
  templateTitle?: string;
};
const SpecialHoursForm: React.FC<Props> = (props) => {
  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    templateTitle?: string,
    specialHoursTemplateId?: number
  ): Promise<CompanySpecialHoursTemplate> => {
    const updateParams = {
      title: templateTitle || '',
      specialHours: params.specialHours ? params.specialHours : { specialHourPeriods: [] },
    };
    return specialHoursTemplateId
      ? await RequestCompanySpecialHoursTemplate.update(specialHoursTemplateId, updateParams)
      : await RequestCompanySpecialHoursTemplate.create(updateParams);
  };
  return (
    <Form
      specialHours={props.specialHours}
      handleUpdate={handleUpdate}
      redirectUrl={props.redirectUrl}
      specialHoursTemplateId={props.specialHoursTemplateId}
      templateTitle={props.templateTitle}
    />
  );
};
export default memo(SpecialHoursForm);
