import { TimeOfDay } from '../share/time-of-day';

export type SUNDAY = 'SUNDAY';
export type MONDAY = 'MONDAY';
export type TUESDAY = 'TUESDAY';
export type WEDNESDAY = 'WEDNESDAY';
export type THURSDAY = 'THURSDAY';
export type FRIDAY = 'FRIDAY';
export type SATURDAY = 'SATURDAY';

export type Date = {
  year: number;
  month: number;
  day: number;
};

export const DayOfTheWeekJP = {
  SUNDAY: '日曜日',
  MONDAY: '月曜日',
  TUESDAY: '火曜日',
  WEDNESDAY: '水曜日',
  THURSDAY: '木曜日',
  FRIDAY: '金曜日',
  SATURDAY: '土曜日',
};

export type SpecialHourPeriod = {
  endDate: Date | null;
  closeTime: TimeOfDay;
  startDate: Date | null;
  openTime: TimeOfDay;
  closed: boolean;
};

export type AllSpecialHourPeriod = {
  key: string;
  closed: boolean;
  parentIndex: number;
  periods: SpecialHourPeriod[];
};

export const dateToString = (date: Date | null): string => {
  return date
    ? `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day
        .toString()
        .padStart(2, '0')}`
    : '';
};

export const stringToDate = (key: string): Date | null => {
  const splitedKeys = key.split('-');
  if (splitedKeys.length < 3) {
    return null;
  }
  return {
    year: parseInt(splitedKeys[0]),
    month: parseInt(splitedKeys[1]),
    day: parseInt(splitedKeys[2]),
  };
};

export const handleDevideSpecialHourPeriods = (
  periods: SpecialHourPeriod[]
): AllSpecialHourPeriod[] => {
  const tmpMap = new Map<string, { closed: boolean; periods: SpecialHourPeriod[] }>();
  periods.forEach((period) => {
    const key = dateToString(period.startDate);
    if (tmpMap.has(key)) {
      tmpMap.set(key, {
        closed: period.closed,
        periods: tmpMap.get(key)?.periods?.concat([period]) || [period],
      });
    } else {
      tmpMap.set(key, { closed: period.closed, periods: [period] });
    }
  });

  return Array.from(tmpMap).map((map, parentIndex) => {
    return {
      key: map[0],
      closed: map[1].closed,
      parentIndex: parentIndex,
      periods: map[1].periods,
    };
  });
};
