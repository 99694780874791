import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import { MediaItem } from '../../../models/location-media';

import moment from '../../../utils/moment-ja';

import styled from 'styled-components';
// import Eye from 'components/general/icons/Eye';

type Props = {
  isLoading: boolean;
  mediaItem: MediaItem;
  handleModal: () => void;
};

const LocalPostCard = styled(Card)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const CardHeaderSpan = styled.span`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const ImageWrap = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 50%;
`;

const ImageWrapImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UpdateDate = styled.p`
  text-align: right;
  font-size: 12px;
`;
//
// const CountBox = styled.p`
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   text-align: right;
//   font-size: 12px;
// `;

const MediaItemCard: React.FC<Props> = (props) => {
  const imageUrl = props.mediaItem.item.googleUrl
    ? props.mediaItem.item.googleUrl
    : '/images/no_image.png';
  return (
    <LocalPostCard onClick={() => props.handleModal()}>
      <Card.Header>
        <CardHeaderSpan>{props.mediaItem.locationName}</CardHeaderSpan>
      </Card.Header>
      <ImageWrap>
        <ImageWrapImage src={imageUrl} />
      </ImageWrap>
      <Card.Body>
        <UpdateDate>{moment(props.mediaItem.createTime).format('Y年M月D日(dd)')}</UpdateDate>
      </Card.Body>
    </LocalPostCard>
  );
};

export default memo(MediaItemCard);
