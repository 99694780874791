import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export interface HeaderProps {
  currentPage: number;
  lastPage: number;
  path: string;
  onClick: (page: number) => void;
}

export interface PageNationLiProps {
  active?: boolean;
}

const Pagintion: React.FC<HeaderProps> = (props) => {
  return (
    <Pagination>
      {props.currentPage >= 5 && (
        <Pagination.Prev onClick={() => props.onClick(props.currentPage - 4)} />
      )}
      {Array(props.lastPage > 5 ? 5 : props.lastPage)
        .fill(0)
        .map((_v, i: number) => {
          const pageNo: number =
            props.currentPage - 5 <= -2
              ? 0 + props.currentPage <= 3
                ? i + 1
                : i + props.currentPage
              : i + props.currentPage - 2;
          if (pageNo <= props.lastPage) {
            return (
              <Pagination.Item
                key={`page-${pageNo}`}
                active={pageNo === props.currentPage}
                onClick={(): void => {
                  props.onClick(pageNo);
                }}
              >
                {pageNo}
              </Pagination.Item>
            );
          }
        })}
      {props.currentPage <= props.lastPage - 6 && (
        <Pagination.Next onClick={() => props.onClick(props.currentPage + 4)} />
      )}
    </Pagination>
  );
};

export default React.memo(Pagintion);
