import React, { memo } from 'react';

import { GmbLocationReview } from '../../../../models/gmb-location-review';
import {
  RequestGmnLocationReview,
  UpdateReplyParams,
} from '../../../../requests/company/gmb-location-reviews';

import { RequestGmbLocation } from '../../../../requests/company/gmb-locations';

import NoReplyReview from '../../../general/gmb-location-reviews/NoReplyReview';

type Props = {
  gmbLocationReviewsListPath: string;
  gmbLocationId: number;
};

const NoReplyReviewView: React.FC<Props> = (props: Props) => {
  const refresh = async (): Promise<GmbLocationReview[]> => {
    return await RequestGmbLocation.noReplyReviews(props.gmbLocationId);
  };

  const handleUpdateReply = async (id: number, params: UpdateReplyParams): Promise<void> => {
    await RequestGmnLocationReview.updateReply(id, params);
  };
  return (
    <NoReplyReview
      gmbLocationReviewsListPath={props.gmbLocationReviewsListPath}
      fetchLatestNoReplyReviews={refresh}
      handleUpdateReply={handleUpdateReply}
      handleGmbLocationKeyword={RequestGmbLocation.keywords}
    />
  );
};

export default memo(NoReplyReviewView);
