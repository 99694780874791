import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import GmapPlace from '../../../general/GmapPlace';
import Cross from '../../../general/icons/Cross';
import { handleSearchFromPostalCode } from '../../../general/Gmap';

import { ServiceArea, PlaceInfo } from '../../../../models/gmb-location';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  serviceArea: ServiceArea | null;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const ServiceAreaModal: React.FC<Props> = (props) => {
  const [serviceArea, setServiceArea] = useState<ServiceArea | null>(props.serviceArea);

  const handleChangeInput = (value: string) => {
    handleSearchFromPostalCode(value).then((v) => {
      const placeInfos = serviceArea?.places?.placeInfos?.concat([
        {
          name: value,
          placeName: value,
          placeId: v.placeId,
        },
      ]);
      const updated: ServiceArea = {
        places: {
          placeInfos: placeInfos || [],
        },
      };
      setServiceArea(updated);
    });
  };

  const handleRemove = (removePlaceInfo: PlaceInfo) => {
    const udpated: ServiceArea = {
      places: {
        placeInfos:
          serviceArea?.places?.placeInfos.filter((v) => {
            return removePlaceInfo.name != v.name;
          }) || [],
      },
    };
    setServiceArea(udpated);
  };

  const handleClose = () => {
    setServiceArea(props.serviceArea);
    props.handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          if (serviceArea) {
            await props.handleUpdate({
              serviceArea,
            });
          }
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GmapPlace handlePlaceName={handleChangeInput} />
          <div style={{ marginTop: '10px' }}>
            {serviceArea?.places?.placeInfos.map((v, idx) => {
              return (
                <Alert key={idx} variant="light" style={{ padding: '2px', marginBottom: '5px' }}>
                  {v.placeName || v.name}
                  <Cross
                    onClick={() => {
                      handleRemove(v);
                    }}
                  />
                </Alert>
              );
            })}
          </div>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(ServiceAreaModal);
