import React, { memo } from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Attribute } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

type Props = {
  attribute: Attribute;
  targetAttributeValue?: AttributeValue;
  readonly: boolean;
  handleAttributeValue?: (v: AttributeValue) => void;
};

const AttributeEnumDisplay: React.FC<Props> = (props) => {
  const setNextValue = (metaValue: string, targetAttributeValue?: AttributeValue) => {
    if (targetAttributeValue === undefined || targetAttributeValue.values[0] !== metaValue) {
      return [metaValue];
    }
    return [];
  };

  return (
    <Container fluid style={{ backgroundColor: '#DCDCDC', padding: '10px' }}>
      <Row>
        <Col>{props.attribute.displayName}</Col>
      </Row>
      <Row>
        {props.attribute.valueMetadata.map((data, index) => {
          return (
            <Col key={`meta_data_${index}`} md="auto">
              <Button
                variant={'light'}
                disabled={props.readonly}
                onClick={() => {
                  if (props.handleAttributeValue == undefined) {
                    return;
                  }
                  props.handleAttributeValue({
                    name: 'attributes/' + props.attribute.attributeId,
                    valueType: props.attribute.valueType,
                    repeatedEnumValue: {
                      setValues: [],
                      unsetValues: [],
                    },
                    uriValues: [],
                    values: setNextValue(data.value, props.targetAttributeValue),
                  });
                }}
              >
                {data.displayName}
                {props.targetAttributeValue ? (
                  <>
                    <Badge
                      style={{ marginLeft: '10px' }}
                      variant={
                        props.targetAttributeValue.values[0] == data.value ? 'success' : 'secondary'
                      }
                    >
                      {props.targetAttributeValue.values[0] == data.value ? '◯' : '-'}
                    </Badge>
                  </>
                ) : (
                  <Badge style={{ marginLeft: '10px' }} variant="secondary">
                    -
                  </Badge>
                )}
              </Button>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default memo(AttributeEnumDisplay);
