import React, { memo } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {
  Period,
  DayOfTheWeekJP,
  DayOfTheWeek,
  AllWeekHoursPeriods,
} from '../../../../models/gmb-location/period';
import Cross from '../../../../components/general/icons/Cross';
import TextField from '@material-ui/core/TextField';
import { buildTime } from 'utils/app-time';

type Props = {
  allWeekHoursPeriods: AllWeekHoursPeriods;
  handleOnChangeSwitch?: (weekday: DayOfTheWeek) => void;
  handleOnAdd?: (weekday: DayOfTheWeek) => void;
  handleOnDelete?: (weekday: DayOfTheWeek, index: number) => void;
  handleOnChange?: (weekday: DayOfTheWeek, isOpen: boolean, value: string, index: number) => void;
  readonly: boolean;
  holidayVisible: boolean;
  selectedPeriods?: Period[];
};

const PeriodsTable: React.FC<Props> = (props) => {
  const { allWeekHoursPeriods, handleOnChangeSwitch, handleOnAdd, handleOnDelete, handleOnChange } =
    props;

  return (
    <>
      {Object.entries(allWeekHoursPeriods).map((value: [key: DayOfTheWeek, periods: Period[]]) => {
        const [key, periods] = value;
        return periods.length <= 0 && props.holidayVisible ? (
          <ListGroup key={`${key}_${props.readonly}`}>
            <ListGroup.Item>
              <Row>
                <Col>
                  <>{DayOfTheWeekJP[key]}</>
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    id={`${key}_${props.readonly}`}
                    label="定休日"
                    checked={false}
                    disabled={props.readonly}
                    onChange={() => (handleOnChangeSwitch ? handleOnChangeSwitch(key) : '')}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
                {!props.readonly && <Col></Col>}
              </Row>
            </ListGroup.Item>
          </ListGroup>
        ) : (
          periods.map((regularHoursPeriod: Period, index: number) => {
            return (
              <ListGroup key={`${key}_${index}_${props.readonly}`}>
                <ListGroup.Item>
                  <Row>
                    <Col>{index != 0 ? '' : DayOfTheWeekJP[regularHoursPeriod.openDay]}</Col>
                    <Col>
                      {index != 0 ? (
                        ''
                      ) : (
                        <Form.Check
                          type="switch"
                          id={`${key}_${index}_${props.readonly}`}
                          label="営業日"
                          checked={true}
                          disabled={props.readonly}
                          onChange={() => (handleOnChangeSwitch ? handleOnChangeSwitch(key) : '')}
                        />
                      )}
                    </Col>
                    <Col>
                      <TextField
                        type="time"
                        defaultValue={buildTime(regularHoursPeriod.openTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        disabled={props.readonly}
                        onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                          handleOnChange ? handleOnChange(key, true, v.target.value, index) : '';
                        }}
                      />
                    </Col>
                    <Col>
                      <TextField
                        type="time"
                        defaultValue={buildTime(regularHoursPeriod.closeTime)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        disabled={props.readonly}
                        onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
                          handleOnChange ? handleOnChange(key, false, v.target.value, index) : '';
                        }}
                      />
                    </Col>
                    {!props.readonly && (
                      <Col style={{ textAlign: 'center' }}>
                        {index != 0 ? (
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => (handleOnDelete ? handleOnDelete(key, index) : '')}
                          >
                            <Cross width="20" height="20" />
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            onClick={() => (handleOnAdd ? handleOnAdd(key) : '')}
                          >
                            営業時間を追加
                          </Button>
                        )}
                      </Col>
                    )}
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            );
          })
        );
      })}
    </>
  );
};

export default memo(PeriodsTable);
