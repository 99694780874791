import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  value: string;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const LocationNameModal: React.FC<Props> = (props) => {
  const [locationName, setLocationName] = useState<string>(props.value);
  const handleChangeInput = (value: string) => {
    setLocationName(value);
  };
  const handleClose = () => {
    setLocationName(props.value);
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          await props.handleUpdate({
            title: locationName,
          });
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={locationName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeInput(e.target.value);
            }}
          />
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(LocationNameModal);
