import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { isSP } from '../../utils/app-util';

type Dataset = {
  type: string;
  label: string;
  data: number[];
  borderColor: string[];
  fill: boolean;
  backgroundColor?: string;
  borderWidth: number;
};

type Props = {
  labels: string[];
  datasets: Dataset[];
  height?: number;
};

const Graph: React.FC<Props> = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };
  return <Bar data={data} height={isSP() ? props.height : 100} />;
};

export default memo(Graph);
