import React, { memo } from 'react';

import { useFetchGmbLocalEvaluation } from '../../../../hooks/useFetchGmbLocalEvaluation';

import EvaluationNav from '../,,/../../../general/gmb-local-evaluation/EvaluationNav';

import { isSP } from '../../../../utils/app-util';

type Props = {
  isPartner: boolean;
  specifiedGmbLocationId: number;
  gmbOperationalEvaluationPath: string;
};

const EvaluationNavLink: React.FC<Props> = (props: Props) => {
  const { isPartner, specifiedGmbLocationId, gmbOperationalEvaluationPath } = props;

  const { totalAverageScoreEvaluationData, evaluationType, handleSelectEvaluationType } =
    useFetchGmbLocalEvaluation({
      isPartner: isPartner,
      specifiedGmbLocationId,
      gmbOperationalEvaluationPath,
    });

  return (
    <EvaluationNav
      actionType="href"
      fontSize={isSP() ? '14px' : '16px'}
      align="center"
      columnWidth="50%"
      totalAverageScoreEvaluationData={totalAverageScoreEvaluationData}
      evaluationType={evaluationType}
      handleSelectEvaluationType={handleSelectEvaluationType}
    />
  );
};

export default memo(EvaluationNavLink);
