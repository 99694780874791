import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Pagination from '../../general/Pagination';

import ReviewCard from '../../general/gmb-location-reviews/ReviewCard';
import ReviewModal from '../../general/gmb-location-reviews/ReviewModal';
import { Alert } from '../../../service/alert';

import { GmbLocationReview } from '../../../models/gmb-location-review';
import { Pagination as PaginationModel } from '../../../models/pagination';
import { UpdateReplyParams, ListParams } from '../../../requests/partner/gmb-location-reviews';
import { sliceByNumber } from '../../../utils/app-array';
import { isSP } from '../../../utils/app-util';
import { useKeywords } from '../../../hooks/useKeywords';

import { CancelTokenSource } from 'axios';

type Props = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    gmbLocationId?: number;
    rate?: string;
    replied?: boolean;
  };
  disabledSync?: boolean;
  gmbLocationReviews: GmbLocationReview[];
  pagination: PaginationModel;
  fetchLocationReviews: (page: number, params: ListParams) => Promise<void>;
  handleUpdateReply: (id: number, params: UpdateReplyParams) => Promise<void>;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
  paginationPath: string;
  gmbLocationReviewManagementPath?: string;
};

const ReviewList: React.FC<Props> = (props: Props) => {
  const {
    queries,
    isLoading,
    gmbLocationReviews,
    pagination,
    fetchLocationReviews,
    handleGmbLocationKeyword,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLocationReviews, setSelectedLocationReviews] = useState<GmbLocationReview | null>(
    null
  );

  const pushState = (v: number) => {
    const rateParams = queries.rate !== undefined ? `&rate=${queries.rate}` : '';
    const repliedParams = queries.replied !== undefined ? `&replied=${queries.replied}` : '';
    const gmbLocationParams = queries.gmbLocationId
      ? `&gmbLocationId=${queries.gmbLocationId}`
      : '';
    history.pushState(
      null,
      '',
      `${props.paginationPath}?page=${v}${rateParams}${repliedParams}${gmbLocationParams}`
    );
  };

  useEffect(() => {
    pushState(queries.currentPage);
  }, [queries.currentPage, queries.rate, queries.replied, queries.gmbLocationId]);

  const { keywords, clearKeywords } = useKeywords({
    gmbLocationId: selectedLocationReviews?.gmbLocationId,
    handleGmbLocationKeyword: handleGmbLocationKeyword,
  });

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handleClose = () => {
    clearKeywords();
    setShowModal(false);
    setSelectedLocationReviews(null);
  };

  const handleUpdateReply = async (id: number, params: UpdateReplyParams): Promise<void> => {
    try {
      await props.handleUpdateReply(id, params);
      Alert.success('返信しました');
      location.reload();
    } catch {
      Alert.danger('返信に失敗しました');
    }
  };

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handlePage = (v: number) => {
    pushState(v);
    fetchLocationReviews(v, {
      rate: queries.rate,
      replied: queries.replied,
      gmbLocationId: queries.gmbLocationId,
    });
  };

  return (
    <>
      {gmbLocationReviews.length <= 0 ? (
        <h5>口コミはありません</h5>
      ) : (
        <>
          {sliceByNumber(gmbLocationReviews, isSP() ? 2 : 5).map(
            (splitGmbLocationReviews, index) => (
              <Row key={`gmb_location_view_parent_${index}`} lg={5}>
                {splitGmbLocationReviews.map((gmbLocationReview, childIndex) => {
                  return (
                    <Col key={`gmb_location_view_card_${childIndex}`}>
                      <ReviewCard
                        isLoading={false}
                        gmbLocationReview={gmbLocationReview}
                        handleModal={() => {
                          setShowModal(true);
                          setSelectedLocationReviews(gmbLocationReview);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            )
          )}
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Pagination
                path="/"
                currentPage={pagination.currentPage}
                lastPage={pagination.totalPage}
                onClick={handlePage}
              />
            </Col>
          </Row>
          {selectedLocationReviews && (
            <ReviewModal
              isShow={showModal}
              gmbLocationReview={selectedLocationReviews}
              handleClose={handleClose}
              handleUpdate={handleUpdateReply}
              keywords={keywords}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(ReviewList);
