import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import BaseModal from '../BaseModal';

import { MediaItem } from '../../../models/location-media';

type Props = {
  isShow: boolean;
  mediaItem: MediaItem;
  handleModal: () => void;
  handeleDeleteMediaImage?: () => Promise<void>;
};

const ViewModel: React.FC<Props> = (props) => {
  const { isShow, mediaItem, handleModal, handeleDeleteMediaImage } = props;

  const handleClose = () => {
    handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        handleClose={handleClose}
        handleDelete={handeleDeleteMediaImage}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`登録写真`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>写真</Form.Label>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Image id={`localpost_image`} fluid src={mediaItem.item.googleUrl} />
            </div>
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(ViewModel);
