import { useState } from 'react';
import queryString from 'query-string';

import { SelectedItems } from '../components/general/gmb-location-reviews/ReviewSearchBox';

import { GmbLocationReview } from '../models/gmb-location-review';
import { Pagination as PaginationModel, initializePagination } from '../models/pagination';

import { RequestGmnLocationReviewInterface as PartnerRequestGmnLocationReviewInterface } from '../requests/partner/gmb-location-reviews';
import { RequestGmnLocationReviewInterface as CompanyRequestGmnLocationReviewInterface } from '../requests/company/gmb-location-reviews';
import { ListParams as PartnerListGmbLocationReviewParams } from '../requests/partner/gmb-location-reviews';
import { ListParams as CompanyListGmbLocatuibReviewParams } from '../requests/company/gmb-location-reviews';
import { toBoolean } from '../utils/app-string';

export type SearchParams = PartnerListGmbLocationReviewParams | CompanyListGmbLocatuibReviewParams;

type Props = {
  specifiedGmbLocationId?: number;
  requestGmbLocationReviews:
    | PartnerRequestGmnLocationReviewInterface
    | CompanyRequestGmnLocationReviewInterface;
  replyInitialStatus: boolean | undefined;
};

type Queries = {
  currentPage: number;
  gmbLocationId?: number;
  replied?: boolean;
  rate?: string;
};

type usePeriodsData = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    gmbLocationId?: number;
    replied?: boolean;
    rate?: string;
  };
  pagination: PaginationModel;
  gmbLocationReviews?: GmbLocationReview[];
  refresh(p: number, params?: SearchParams): Promise<void>;
  updateQuery(selectedItems?: SelectedItems): Promise<void>;
};

export const useGmbLocationReviewSearch = (props: Props): usePeriodsData => {
  const { specifiedGmbLocationId, requestGmbLocationReviews, replyInitialStatus } = props;

  const qs = queryString.parse(location.search);
  const currentPage = qs?.page ? parseInt(qs.page as string) : 1;
  const gmbLocationId = specifiedGmbLocationId
    ? specifiedGmbLocationId
    : qs?.gmbLocationId
    ? parseInt(qs.gmbLocationId as string)
    : undefined;
  const replied = qs?.replied != undefined ? toBoolean(qs.replied as string) : replyInitialStatus;
  const rate = qs?.rate ? (qs.rate as string) : '';
  const [queries, setQueries] = useState<Queries>({
    currentPage: currentPage,
    gmbLocationId: gmbLocationId,
    replied: replied,
    rate: rate,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationModel>(initializePagination);
  const [gmbLocationReviews, setGmbLocationReviews] = useState<GmbLocationReview[] | undefined>(
    undefined
  );

  const refresh = async (p: number, params: SearchParams): Promise<void> => {
    setIsLoading(true);
    const v = await requestGmbLocationReviews(p, params);
    setGmbLocationReviews(v.data);
    setPagination(v.pagination);
    setIsLoading(false);
  };

  const updateQuery = async (selectedItems?: SelectedItems): Promise<void> => {
    setQueries({
      currentPage: currentPage,
      gmbLocationId: selectedItems?.gmbLocationId,
      replied: selectedItems?.replied,
      rate: selectedItems?.rate,
    });
  };

  return {
    isLoading,
    queries,
    pagination,
    gmbLocationReviews,
    refresh,
    updateQuery,
  };
};
