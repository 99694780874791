import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

import {
  SpecialHourPeriod,
  handleDevideSpecialHourPeriods,
} from '../../../../models/gmb-location/special-hour-period';

import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import SpecialPeriodsTable from '../partial/SpecialPeriodsTable';

import { useSpecialHourPeriods } from '../../../../hooks/useSpecialHourPeriods';

import { CompanySpecialHoursTemplate } from '../../../../models/company-special-hours-template';

import BaseModal from '../../BaseModal';

type Template = CompanySpecialHoursTemplate;
type Option = {
  value: SpecialHourPeriod[];
  label: string;
};

type Props = {
  isShow: boolean;
  label: string;
  specialHourPeriods: SpecialHourPeriod[];
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
  templateTitle?: string;
  templateList?: Template[];
};

const SpecialHourModal: React.FC<Props> = (props) => {
  const [templateTitle, setTemplateTitle] = useState<string | undefined>(props.templateTitle);
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const specialHourPeriodsStates = useSpecialHourPeriods({
    periods: props.specialHourPeriods,
  });

  const {
    allSpecialHourPeriods,
    setAllSpecialHourPeriods,
    handleOnChangeSwitch,
    handleOnAdd,
    handleOnDelete,
    handleOnChange,
    handleOnChangeDate,
    handleOnDeleteDate,
    handleOnAddDate,
    handleClose,
    resetPeriods,
  } = specialHourPeriodsStates;
  const handleModalClose = () => {
    props.handleModal();
    handleClose();
  };

  const options = props.templateList?.map((v: Template): Option => {
    return { value: v.specialHours.specialHourPeriods, label: v.title };
  });

  const handleSelected = async (option: Option) => {
    setIsLoading(true);
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    resetPeriods(option.value);
    setIsLoading(false);
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleModalClose}
        handleUpdate={async (): Promise<void> => {
          const updateAllSpecialHourPeriods = allSpecialHourPeriods.filter((v) => v.key != '');
          const updatePeriods: SpecialHourPeriod[] = updateAllSpecialHourPeriods
            .map((allSpecialHourPeriod) => {
              return allSpecialHourPeriod.periods.map((v: SpecialHourPeriod) => v);
            })
            .flat();
          await props.handleUpdate(
            {
              specialHours: { specialHourPeriods: updatePeriods },
            },
            templateTitle
          );
          setAllSpecialHourPeriods(handleDevideSpecialHourPeriods(updatePeriods));
          props.handleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <Container>
              {templateTitle !== undefined ? (
                <Form.Group>
                  <Form.Label>タイトル</Form.Label>
                  <Form.Control
                    placeholder="タイトル"
                    value={templateTitle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTemplateTitle(e.target.value);
                    }}
                  />
                </Form.Group>
              ) : (
                <Form.Group>
                  <Form.Label>テンプレートから選択</Form.Label>
                  <Select onChange={handleSelected} options={options} />
                </Form.Group>
              )}
              <SpecialPeriodsTable
                allSpecialHourPeriods={allSpecialHourPeriods}
                handleOnChangeSwitch={handleOnChangeSwitch}
                handleOnAdd={handleOnAdd}
                handleOnDelete={handleOnDelete}
                handleOnChange={handleOnChange}
                handleOnChangeDate={handleOnChangeDate}
                handleOnDeleteDate={handleOnDeleteDate}
                handleOnAddDate={handleOnAddDate}
                readonly={false}
                holidayVisible={true}
              />
            </Container>
          )}
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(SpecialHourModal);
