import React, { memo } from 'react';
import { Category, MoreHours } from '../../../../models/gmb-location';
import { CompanyMoreHoursTemplate } from '../../../../models/company-more-hours-template';

import { RequestCompanyMoreHoursTemplate } from '../../../../requests/company/company-more-hours-template';
import { UpdateGmbLocationParams } from '../../../../requests/company/gmb-locations';

import Form from '../../../general/more-hours-templates/Form';

type Props = {
  moreHours: MoreHours[];
  moreHoursTemplateId?: number;
  gmbCategory?: Category;
  templateTitle?: string;
};

const MoreHoursForm: React.FC<Props> = (props) => {
  const handleUpdate = async (
    params: UpdateGmbLocationParams,
    moreHoursTemplateId?: number,
    categoryId?: string,
    templateTitle?: string
  ): Promise<CompanyMoreHoursTemplate> => {
    const updateParams = {
      categoryId: categoryId || '',
      title: templateTitle || '',
      moreHours: params.moreHours ? params.moreHours : [],
    };
    return moreHoursTemplateId
      ? await RequestCompanyMoreHoursTemplate.update(moreHoursTemplateId, updateParams)
      : await RequestCompanyMoreHoursTemplate.create(updateParams);
  };

  return (
    <Form
      moreHours={props.moreHours}
      moreHoursTemplateId={props.moreHoursTemplateId}
      gmbCategory={props.gmbCategory}
      handleUpdate={handleUpdate}
      templateTitle={props.templateTitle}
    />
  );
};

export default memo(MoreHoursForm);
