import { useState, useEffect, useCallback } from 'react';
import { CancelTokenSource } from 'axios';
import { ApiCancelToken, isApiCancel } from '../requests/client';

type Props = {
  gmbLocationId?: number;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
};

type returnData = {
  keywords: string[];
  clearKeywords: () => void;
};

export const useKeywords = (props: Props): returnData => {
  const { gmbLocationId } = props;
  const [keywords, setKeywords] = useState<string[]>([]);

  const fetchKeywords = useCallback(
    async (cancelToken: CancelTokenSource) => {
      if (!gmbLocationId) return;

      try {
        const data = await props.handleGmbLocationKeyword(gmbLocationId, cancelToken);
        setKeywords(data);
      } catch (e) {
        if (isApiCancel(e)) {
          return;
        }
      }
    },
    [gmbLocationId]
  );

  useEffect(() => {
    const cancelToken = ApiCancelToken.source();
    fetchKeywords(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [fetchKeywords]);

  const clearKeywords = () => {
    setKeywords([]);
  };

  return {
    keywords,
    clearKeywords,
  };
};
