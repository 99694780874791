import React, { memo } from 'react';
import { Pie } from 'react-chartjs-2';

type Dataset = {
  label: string;
  scaleShowLabels: boolean;
  data: number[];
  borderColor: string[];
  fill: boolean;
  backgroundColor?: string;
};

type Props = {
  labels: string[];
  datasets: Dataset[];
  width?: number;
  height?: number;
};

const CircleGraph: React.FC<Props> = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };
  const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      display: false,
    },
    layout: {
      margin: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  return <Pie data={data} options={options} />;
};

export default memo(CircleGraph);
