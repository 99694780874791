import { Period } from './gmb-location/period';
import { SpecialHourPeriod } from './gmb-location/special-hour-period';
import { ValueType } from './gmb-location/attribute-group';

export type Address = {
  addressLines: string[];
  administrativeArea: string;
  languageCode: 'ja';
  postalCode: string;
  regionCode: string;
};

export type Profile = {
  description: string;
};

export const OpenInfoOpen = 'OPEN';
export const OpenInfoClosedPermanently = 'CLOSED_PERMANENTLY';
export const OpenInfoClosedTemporary = 'CLOSED_TEMPORARILY';
export const OpenInfoStatusJa = {
  [OpenInfoOpen]: '営業中',
  [OpenInfoClosedPermanently]: '閉店',
  [OpenInfoClosedTemporary]: '臨時休業',
};
export const OpenInfoStatusList = [
  OpenInfoOpen,
  OpenInfoClosedPermanently,
  OpenInfoClosedTemporary,
];
export type OpenInfoStatus = 'OPEN' | 'CLOSED_PERMANENTLY' | 'CLOSED_TEMPORARILY';

export type OpenInfo = {
  status: OpenInfoStatus;
  openingDate: {
    year: number | null;
    month: number | null;
    day: number | null;
  };
};

export type Category = {
  name: string;
  displayName: string;
};

export type RegularHours = {
  periods: Period[];
};

export type SpecialHours = {
  specialHourPeriods: SpecialHourPeriod[];
};

export type MoreHours = {
  hoursTypeId: string;
  periods: Period[];
};

export type uriValue = {
  uri: string;
};

export type PlaceInfo = {
  name: string | null; // deprecated
  placeName: string | null;
  placeId: string;
};

export type ServiceAreaPlaces = {
  placeInfos: PlaceInfo[];
};

export type ServiceArea = {
  places: ServiceAreaPlaces;
};

export type PhoneNumbers = {
  primaryPhone: string;
  additionalPhones: string[];
};

export type Categories = {
  primaryCategory: Category;
  additionalCategories: Category[];
};

export type Attribute = {
  name: string;
  repeatedEnumValue: {
    setValues: string[];
    unsetValues: string[];
  };
  uriValues: uriValue[];
  valueType: ValueType;
  values: string[] | boolean[];
};

export type GmbLocation = {
  id: number;
  title: string;
  storefrontAddress: Address;
  phoneNumbers: PhoneNumbers | null;
  websiteUri: string | null;
  profile: Profile;
  openInfo: OpenInfo | null;
  categories: Categories;
  regularHours: RegularHours;
  specialHours: SpecialHours;
  moreHours: MoreHours[];
  serviceArea: ServiceArea;
};
