export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const tel = phoneNumber.replace(/[━.*‐.*―.*－.*\-.*ー.*-]/gi, '');
  if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
    return false;
  }
  return true;
};

export const validateUrl = (value: string): boolean => {
  const url_pattern =
    /^(https|http):\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
  if (!value.match(url_pattern)) {
    return false;
  }
  return true;
};

export const validatePostalCode = (value: string): boolean => {
  const pattern = /^[0-9]{3}-[0-9]{4}$/;
  return pattern.test(value);
};
