import React, { memo, useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ImageBox from '../../ImageBox';

import TextField from '@material-ui/core/TextField';
import { SaveLocalPostParams } from '../../../../requests/partner/gmb-local-posts';

import ImageUpload from '../../gmb-local-post-share/ImageUpload';

import { GmbLocation } from '../../../../models/gmb-location';
import { GmbLocalPost, CallToActionType } from '../../../../models/gmb-local-post';

import ActionButtonSelect, { Option } from './ActionButtonSelect';
import BaseModal from '../../BaseModal';

import { validateUrl } from '../../../../utils/validate';
import { Alert } from '../../../../service/alert';

import { useUploadImage } from '../../../../hooks/useUploadImage';

import moment from '../../../../utils/moment-ja';

import { CancelTokenSource } from 'axios';
import { ApiCancelToken, isApiCancel } from '../../../../requests/client';

type Props = {
  isShow: boolean;
  gmbLocationId: number;
  gmbLocalPost?: GmbLocalPost;
  handleModal: () => void;
  handleShowGmbLocation: (id: number, cancelToken?: CancelTokenSource) => Promise<GmbLocation>;
  handleCreate?: (params: SaveLocalPostParams) => Promise<void>;
  handleDelete?: () => Promise<void>;
};

const EventModal: React.FC<Props> = (props) => {
  const {
    isShow,
    gmbLocationId,
    gmbLocalPost,
    handleModal,
    handleShowGmbLocation,
    handleCreate,
    handleDelete,
  } = props;
  const [gmbLocation, setGmbLocation] = useState<GmbLocation | null>(null);
  const [eventTitle, setEventTitle] = useState<string>(gmbLocalPost?.localPost?.event?.title || '');
  const [startDate, setStartDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endDate, setEndDate] = useState<string>(moment().add(7, 'days').format('YYYY-MM-DD'));
  const [endTime, setEndTime] = useState<string>('00:00');

  const [callToActionType, setCallToActionType] = useState<CallToActionType>(
    gmbLocalPost?.localPost?.callToAction?.actionType || 'ACTION_TYPE_UNSPECIFIED'
  );
  const [callToActionUrl, setCallToActioUrl] = useState<string>('');
  const { publicUrl, selectingPostImage, setSelectionPostImage, handleAttachImageFile } =
    useUploadImage();

  const handleChangeActionButtonType = (option: Option) => {
    setCallToActionType(option.value);
  };
  const handleChangeActionUrl = (val: string) => {
    setCallToActioUrl(val);
  };

  const fetchGmbLocation = useCallback(
    async (cancelToken?: CancelTokenSource) => {
      try {
        const v = await handleShowGmbLocation(gmbLocationId, cancelToken);
        setGmbLocation(v);
      } catch (e) {
        if (isApiCancel(e)) return;
      }
    },
    [gmbLocationId]
  );

  useEffect(() => {
    const cancelToken = ApiCancelToken.source();
    fetchGmbLocation(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [fetchGmbLocation]);

  const resetForm = () => {
    setEventTitle('');
    setSelectionPostImage(null);
    setCallToActionType('ACTION_TYPE_UNSPECIFIED');
    setCallToActioUrl('');
  };

  const handleClose = () => {
    resetForm();
    handleModal();
  };

  const disabled = handleCreate === undefined;

  if (gmbLocation === null) {
    return <></>;
  }

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        buttonText="投稿"
        alert={true}
        handleClose={handleClose}
        handleUpdate={
          disabled
            ? undefined
            : async (): Promise<void> => {
                if (handleCreate === undefined) {
                  return;
                }
                if (eventTitle == '') {
                  throw Alert.danger('イベントタイトルは必須です');
                }
                if (
                  callToActionType != 'ACTION_TYPE_UNSPECIFIED' &&
                  callToActionType != 'CALL' &&
                  !validateUrl(callToActionUrl)
                ) {
                  throw Alert.danger('ボタンのリンクが正しくありません');
                }
                const momentStartDateTime = moment(`${startDate} ${startTime}`);
                const momentEndDateTime = moment(`${endDate} ${endTime}`);
                try {
                  await handleCreate({
                    gmbLocationId,
                    params: {
                      event: {
                        title: eventTitle,
                        schedule: {
                          startDate: {
                            year: momentStartDateTime.year(),
                            month: momentStartDateTime.month() + 1,
                            day: momentStartDateTime.date(),
                          },
                          startTime: {
                            hours: momentStartDateTime.hour(),
                            minutes: momentStartDateTime.minutes(),
                            seconds: 0,
                            nanos: 0,
                          },
                          endDate: {
                            year: momentEndDateTime.year(),
                            month: momentEndDateTime.month() + 1,
                            day: momentEndDateTime.date(),
                          },
                          endTime: {
                            hours: momentEndDateTime.hour(),
                            minutes: momentEndDateTime.minutes(),
                            seconds: 0,
                            nanos: 0,
                          },
                        },
                      },
                      languageCode: 'JP',
                      topicType: 'EVENT',
                      callToAction:
                        callToActionType == 'ACTION_TYPE_UNSPECIFIED'
                          ? undefined
                          : {
                              actionType: callToActionType,
                              url: callToActionType == 'CALL' ? '' : callToActionUrl,
                            },
                      media:
                        publicUrl !== null
                          ? [
                              {
                                mediaFormat: 'PHOTO',
                                sourceUrl: publicUrl,
                              },
                            ]
                          : undefined,
                    },
                  });
                  Alert.success('投稿しました');
                  handleModal();
                } catch {
                  Alert.danger('投稿に失敗しました');
                }
              }
        }
        handleDelete={handleDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`最新情報投稿の追加(${gmbLocation.locationName})`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectingPostImage == null && gmbLocalPost?.localPost.media[0] && (
            <ImageBox imageUrl={gmbLocalPost.localPost.media[0].googleUrl} />
          )}
          <ImageUpload
            selectingPostImage={selectingPostImage}
            handleAttachImageFile={handleAttachImageFile}
          />
          <Form.Group>
            <Form.Label>イベントタイトル</Form.Label>
            <Form.Control
              placeholder="イベントタイトルを入力してください"
              value={eventTitle}
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEventTitle(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>開催日</Form.Label>
            <Form.Group>
              <TextField
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={startDate}
                disabled={disabled}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setStartDate(cv.target.value);
                }}
              />
              <TextField
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={startTime}
                inputProps={{
                  step: 300, // 5 min
                }}
                disabled={disabled}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setStartTime(cv.target.value);
                }}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>終了日</Form.Label>
            <Form.Group>
              <TextField
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={endDate}
                disabled={disabled}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setEndDate(cv.target.value);
                }}
              />
              <TextField
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={endTime}
                inputProps={{
                  step: 300, // 5 min
                }}
                disabled={disabled}
                onChange={(cv: React.ChangeEvent<HTMLInputElement>) => {
                  setEndTime(cv.target.value);
                }}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <ActionButtonSelect
              disabled={disabled}
              phoneNumber={gmbLocation.primaryPhone}
              selectedCallToActionType={callToActionType}
              callToActionUrl={callToActionUrl}
              handleChangeActionUrl={handleChangeActionUrl}
              handleSelected={handleChangeActionButtonType}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(EventModal);
