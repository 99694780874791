import { ApiClient } from '../client';
import { MoreHours } from '../../models/gmb-location';
import { CompanyMoreHoursTemplate } from '../../models/company-more-hours-template';

export type SaveCompanyMoreHoursTempate = {
  title: string;
  categoryId: string;
  moreHours: MoreHours[];
};

export class RequestCompanyMoreHoursTemplate {
  static create = async (
    params: SaveCompanyMoreHoursTempate
  ): Promise<CompanyMoreHoursTemplate> => {
    const res = await ApiClient.post(`/company/api/company_more_hours_templates`, params);
    return res.data;
  };

  static update = async (
    id: number,
    params: SaveCompanyMoreHoursTempate
  ): Promise<CompanyMoreHoursTemplate> => {
    const res = await ApiClient.patch(`/company/api/company_more_hours_templates/${id}`, params);
    return res.data;
  };
}
