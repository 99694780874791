import React, { memo } from 'react';
import Geocode from 'react-geocode';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const GmapApiKey = 'AIzaSyDXBhCGpf2zncYvWblB6AnQfjF8oBvtnNY';

Geocode.setApiKey(GmapApiKey);
Geocode.setLanguage('jp');

type GMapAddress = {
  postalCode: string;
  country: string;
  prefecture: string;
  city: string;
  placeId: string;
  latLng: {
    lat: number;
    lng: number;
  };
};

export const handleSearchFromPostalCode = async (postalCode: string): Promise<GMapAddress> => {
  const res = await Geocode.fromAddress(postalCode);
  const address = res.results[0].address_components;
  const location = res.results[0].geometry.location;
  const placeId = res.results[0].place_id;
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    country: address.find((v: any) => v.types.some((t: string) => t === 'country'))?.long_name,
    postalCode: postalCode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prefecture: address.find((v: any) =>
      v.types.some((t: string) => t === 'administrative_area_level_1')
    )?.long_name,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    city: address.find((v: any) => v.types.some((t: string) => t === 'locality'))?.long_name,
    placeId: placeId,
    latLng: {
      lat: location.lat,
      lng: location.lng,
    },
  };
};

type Props = {
  containerStyle: React.CSSProperties | undefined;
  latLang: {
    lat: number;
    lng: number;
  };
};
const Gmap: React.FC<Props> = (props) => {
  return (
    <LoadScript googleMapsApiKey={GmapApiKey}>
      <GoogleMap mapContainerStyle={props.containerStyle} center={props.latLang} zoom={17}>
        <Marker position={props.latLang} />
      </GoogleMap>
    </LoadScript>
  );
};

export default memo(Gmap);
