import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import LocationNameRaw from './raws/LocationNameRaw';
import PrimaryCategoryRaw from './raws/PrimaryCategoryRaw';
import AdditionalCategoriesRaw from './raws/AdditionalCategoriesRaw';
import RegularHoursRaw from './raws/RegularHoursRaw';
import MoreHoursDetailRaw from './raws/MoreHoursDetailRaw';
import PrimaryPhoneRaw from './raws/PrimaryPhoneRaw';
import WebsiteUrlRaw from './raws/WebsiteUrlRaw';
import ProfileRaw from './raws/ProfileRaw';
import OpenInfoRaw from './raws/OpenInfoRaw';
import AttributeRaw from './raws/AttributeRaw';
import SpecialHoursRaw from './raws/SpecialHoursRaw';
import AddressRaw from './raws/AddressRaw';
import ServiceAreaRaw from './raws/ServiceAreaRaw';
import OpenInfoButton from './button/OpenInfoButton';

import { Alert } from '../../../service/alert';

import { GmbLocation, Attribute } from '../../../models/gmb-location';
import { HoursType } from '../../../models/gmb-location/hours-type';
import { AttributeGroup } from '../../../models/gmb-location/attribute-group';

import {
  UpdateGmbLocationAttributesParams,
  UpdateGmbLocationParams,
} from '../../../requests/partner/gmb-locations';
import { RequestGmbHoursType } from '../../../requests/gmb-hours-types';
import { RequestGmbAttributeGroup } from '../../../requests/gmb-attribute-groups';

import { CompanyRegularHoursTemplate } from '../../../models/company-regular-hours-template';
type RegularHourTemplate = CompanyRegularHoursTemplate;

import { CompanySpecialHoursTemplate } from '../../../models/company-special-hours-template';
type SpecialHourTemplate = CompanySpecialHoursTemplate;

import { CompanyMoreHoursTemplate } from '../../../models/company-more-hours-template';
type MoreHourTemplate = CompanyMoreHoursTemplate;

import { useKeywords } from '../../../hooks/useKeywords';
import { CancelTokenSource } from 'axios';

type Props = {
  gmbLocationId: number;
  isListedStore: boolean;
  location: GmbLocation;
  locationAttributes: Attribute[];
  handleUpdate: (id: number, params: UpdateGmbLocationParams) => Promise<GmbLocation>;
  handleUpdateLocationAttributes: (
    id: number,
    params: UpdateGmbLocationAttributesParams
  ) => Promise<Attribute[]>;
  regularHourTemplates?: RegularHourTemplate[];
  regularHourTemplateSettingPath?: string;
  specialHourTemplates?: SpecialHourTemplate[];
  moreHourTemplates?: MoreHourTemplate[];
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
};

const Form: React.FC<Props> = (props) => {
  const [location, setLocation] = useState<GmbLocation>(props.location);
  const [locationAttributes, setLocationAttributes] = useState<Attribute[]>(
    props.locationAttributes
  );
  const [allHoursTypes, setAllHoursTypes] = useState<HoursType[]>([]);
  const [hoursTypes, setHoursTypes] = useState<HoursType[]>([]);
  const [attributeGroups, setAttributeGroups] = useState<AttributeGroup[]>([]);
  const [moreHourTemplates, setMoreHourTemplates] = useState<MoreHourTemplate[] | undefined>(
    props.moreHourTemplates
  );
  useEffect(() => {
    const primaryCategoryName = location.categories.primaryCategory?.name;
    const primaryCategoryId = primaryCategoryName.split('/')[1];
    RequestGmbHoursType.all({
      categoryId: null,
    }).then((v: HoursType[]) => {
      setAllHoursTypes(v);
    });
    RequestGmbHoursType.all({
      categoryId: primaryCategoryId,
    }).then((v: HoursType[]) => {
      setHoursTypes(v);
    });
    RequestGmbAttributeGroup.all({
      categoryId: primaryCategoryId,
    }).then((v: AttributeGroup[]) => {
      setAttributeGroups(v);
    });
    if (props.moreHourTemplates !== undefined) {
      setMoreHourTemplates(
        props.moreHourTemplates.filter((v: MoreHourTemplate) => {
          return v.categoryId === primaryCategoryId;
        })
      );
    }
  }, []);

  const { keywords } = useKeywords({
    gmbLocationId: props.gmbLocationId,
    handleGmbLocationKeyword: props.handleGmbLocationKeyword,
  });

  const handleUpdate = async (params: UpdateGmbLocationParams): Promise<void> => {
    try {
      const result = await props.handleUpdate(props.gmbLocationId, params);
      setLocation(result);
      Alert.success('保存しました');
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };

  const handleUpdateLocationAttributes = async (attributes: Attribute[]): Promise<void> => {
    try {
      const result = await props.handleUpdateLocationAttributes(props.gmbLocationId, {
        attributes,
      });
      setLocationAttributes(result);
      Alert.success('保存しました');
    } catch (e) {
      Alert.danger('保存に失敗しました');
      throw e;
    }
  };

  if (
    location &&
    allHoursTypes.length <= 0 &&
    hoursTypes.length <= 0 &&
    attributeGroups.length <= 0
  ) {
    return <Spinner animation="border" role="status" />;
  }

  return (
    <>
      {!props.isListedStore && (
        <>
          <Row>
            <Col style={{ textAlign: 'right' }}>
              <OpenInfoButton openInfo={location.openInfo} handleUpdate={handleUpdate} />
            </Col>
          </Row>
          <Row>
            <Col>
              <LocationNameRaw label="店名" value={location.title} handleUpdate={handleUpdate} />
            </Col>
          </Row>
          <Row>
            <Col>
              <PrimaryCategoryRaw
                label="メインカテゴリー"
                categories={location.categories}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AdditionalCategoriesRaw
                label="追加カテゴリー"
                categories={location.categories}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <RegularHoursRaw
                label="営業時間"
                regularHoursPeriods={location.regularHours.periods}
                handleUpdate={handleUpdate}
                regularHourTemplates={props.regularHourTemplates}
                regularHourTemplateSettingPath={props.regularHourTemplateSettingPath}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <MoreHoursDetailRaw
                label="営業時間の詳細"
                moreHours={location.moreHours}
                allHoursTypes={allHoursTypes}
                hoursTypes={hoursTypes}
                handleUpdate={handleUpdate}
                moreHourTemplates={moreHourTemplates}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SpecialHoursRaw
                label="特別営業時間"
                specialHourPeriods={location.specialHours.specialHourPeriods}
                handleUpdate={handleUpdate}
                specialHourTemplates={props.specialHourTemplates}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AddressRaw
                label="住所"
                address={location.storefrontAddress}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <ServiceAreaRaw
            label="サービス提供地域"
            serviceArea={location.serviceArea}
            handleUpdate={handleUpdate}
          />
        </Col>
      </Row>
      {!props.isListedStore && (
        <>
          <Row>
            <Col>
              <PrimaryPhoneRaw
                label="電話番号"
                phoneNumbers={location.phoneNumbers}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <WebsiteUrlRaw
                label="Webサイト"
                value={location.websiteUri}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <AttributeRaw
            label="その他設定"
            attributeValues={locationAttributes}
            attributeGroups={attributeGroups}
            handleUpdate={handleUpdateLocationAttributes}
          />
        </Col>
      </Row>
      {!props.isListedStore && (
        <>
          <Row>
            <Col>
              <ProfileRaw
                label="ビジネス説明"
                profile={location.profile}
                keywords={keywords}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OpenInfoRaw
                label="開業日"
                openInfo={location.openInfo}
                handleUpdate={handleUpdate}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(Form);
