import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
// import Carousel from 'react-bootstrap/Carousel';

import FileuploadArea from '../../general/FileuploadArea';
import BaseModal from '../BaseModal';

import { RequestGoogleMedias } from '../../../requests/google-medias';

import { AttachmentModel } from '../../../models/share/attachment-model';
import { MediaItem, MediaCategory } from '../../../models/location-media';

import { SaveLocationMediaParams } from '../../../requests/partner/gmb-location-media-images';

import { Alert } from '../../../service/alert';

type Props = {
  isShow: boolean;
  mediaCategory: MediaCategory;
  gmbLocationId: number;
  mediaItem?: MediaItem;
  handleModal: () => void;
  handleCreate: (params: SaveLocationMediaParams) => Promise<void>;
};

const UploadModal: React.FC<Props> = (props) => {
  const { isShow, gmbLocationId, mediaCategory, handleModal, handleCreate } = props;
  const [publicUrl, setPublicUrl] = useState<string>('');
  const [selectingPostImage, setSelectionPostImage] = useState<AttachmentModel | null>(null);

  const handleAttachImageFile = async (attachment: AttachmentModel): Promise<void> => {
    const exts = ['image/jpeg', 'image/png', 'image/gif'];
    const regExp = new RegExp(exts.join('|'));
    if (regExp.test(attachment.file.type) === false) {
      alert('選択できるファイルはjpeg, png, gifのみです');
      return;
    }
    const result = await RequestGoogleMedias.postPresignedUrl();
    await RequestGoogleMedias.putFile(result.presignedUrl, attachment);
    setPublicUrl(result.publicLink);
    setSelectionPostImage(attachment);
  };

  const handleClose = () => {
    setSelectionPostImage(null);
    handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          await handleCreate({
            gmbLocationId,
            params: {
              locationAssociation: {
                category: mediaCategory,
              },
              mediaFormat: 'PHOTO',
              sourceUrl: publicUrl,
            },
          });
          Alert.success('保存しました');
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`写真のアップロード`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>写真</Form.Label>
            <div style={{ width: '100%' }}>
              <FileuploadArea
                fileId={`localpost_image_area`}
                attachmentFile={selectingPostImage}
                handleAttachFile={(file: AttachmentModel): void => {
                  handleAttachImageFile(file);
                }}
              >
                {selectingPostImage?.url ? (
                  <Image id={`localpost_image`} fluid src={selectingPostImage?.url} />
                ) : (
                  <Card className="text-center" style={{ border: 'none' }}>
                    <Card.Body>
                      <Card.Text>写真を追加する</Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </FileuploadArea>
            </div>
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(UploadModal);
