import { useState } from 'react';
import { splitTime } from 'utils/app-time';

import {
  SpecialHourPeriod,
  AllSpecialHourPeriod,
  handleDevideSpecialHourPeriods,
  stringToDate,
} from '../models/gmb-location/special-hour-period';

type Props = {
  periods: SpecialHourPeriod[];
};

type useSpecialHourPeriodsData = {
  allSpecialHourPeriods: AllSpecialHourPeriod[];
  newAllSpecialHourPeriods: AllSpecialHourPeriod[];
  setAllSpecialHourPeriods: (v: AllSpecialHourPeriod[]) => void;
  handleOnChangeSwitch: (key: string, parentTargetIndex: number) => void;
  handleOnAdd: (key: string) => void;
  handleOnDelete: (key: string, targetIndex: number) => void;
  handleOnChange: (key: string, isOpen: boolean, value: string, index: number) => void;
  handleOnDeleteDate: (parentTargetIndex: number) => void;
  handleOnChangeDate: (key: string, value: string, parentTargetIndex: number) => void;
  handleOnAddDate: () => void;
  handleClose: () => void;
  resetPeriods: (periods: SpecialHourPeriod[]) => void;
};

export const useSpecialHourPeriods = (props: Props): useSpecialHourPeriodsData => {
  const defaultAllSpecialHourPeriods = handleDevideSpecialHourPeriods(props.periods);
  const [allSpecialHourPeriods, setAllSpecialHourPeriods] = useState<AllSpecialHourPeriod[]>(
    defaultAllSpecialHourPeriods
  );

  const [newAllSpecialHourPeriods, _] = useState<AllSpecialHourPeriod[]>([]);

  const handleOnChangeSwitch = (key: string, parentTargetIndex: number) => {
    const updated = allSpecialHourPeriods.map((v: AllSpecialHourPeriod, index: number) => {
      if (parentTargetIndex === index) {
        return {
          key: key,
          closed: !v.closed,
          parentIndex: index,
          periods: v.periods.map((v: SpecialHourPeriod) => {
            return {
              endDate: v.endDate,
              closeTime: v.closeTime,
              startDate: v.startDate,
              openTime: v.openTime,
              closed: !v.closed,
            };
          }),
        };
      } else {
        return v;
      }
    });
    setAllSpecialHourPeriods(updated);
  };

  const handleOnAdd = (key: string) => {
    const date = stringToDate(key);
    const newPeriod: SpecialHourPeriod = {
      startDate: date,
      openTime: {
        hours: 0,
        minutes: 0,
      },
      endDate: date,
      closeTime: {
        hours: 0,
        minutes: 0,
      },
      closed: false,
    };
    setAllSpecialHourPeriods(
      allSpecialHourPeriods.map((v, index) => {
        if (v.key === key) {
          return {
            key: key,
            closed: false,
            parentIndex: index,
            periods: v.periods.concat([newPeriod]),
          };
        } else {
          return v;
        }
      })
    );
  };

  const handleOnDelete = (key: string, targetIndex: number) => {
    setAllSpecialHourPeriods(
      allSpecialHourPeriods.map((v: AllSpecialHourPeriod, index: number) => {
        if (v.key === key) {
          return {
            key: key,
            closed: false,
            parentIndex: index,
            periods: v.periods.filter((_v: SpecialHourPeriod, index: number) => {
              return targetIndex != index;
            }),
          };
        } else {
          return v;
        }
      })
    );
  };

  const handleOnChange = (key: string, isOpen: boolean, value: string, targetIndex: number) => {
    const updateState = (v: SpecialHourPeriod, key: string, index: number): SpecialHourPeriod => {
      return {
        startDate: stringToDate(key),
        openTime: isOpen && index == targetIndex ? splitTime(value) : v.openTime,
        endDate: stringToDate(key),
        closeTime: !isOpen && index == targetIndex ? splitTime(value) : v.closeTime,
        closed: false,
      };
    };
    const updated = allSpecialHourPeriods.map((v: AllSpecialHourPeriod, index: number) => {
      if (v.key === key) {
        return {
          key: key,
          closed: false,
          parentIndex: index,
          periods: v.periods.map((v: SpecialHourPeriod, index: number) => {
            if (targetIndex == index) {
              return updateState(v, key, index);
            }
            return v;
          }),
        };
      } else {
        return v;
      }
    });
    setAllSpecialHourPeriods(updated);
  };

  const handleOnChangeDate = (key: string, value: string, parentTargetIndex: number) => {
    const updateState = (v: SpecialHourPeriod, key: string): SpecialHourPeriod => {
      return {
        startDate: stringToDate(key),
        openTime: v.openTime,
        endDate: stringToDate(key),
        closeTime: v.closeTime,
        closed: false,
      };
    };
    const updated = allSpecialHourPeriods.map((v: AllSpecialHourPeriod, parentIndex: number) => {
      if (v.key === key && parentTargetIndex == parentIndex) {
        return {
          key: value,
          closed: false,
          parentIndex: parentIndex,
          periods: v.periods.map((v: SpecialHourPeriod) => {
            return updateState(v, value);
          }),
        };
      } else {
        return v;
      }
    });
    setAllSpecialHourPeriods(updated);
  };

  const handleOnDeleteDate = (parentTargetIndex: number) => {
    const updated = allSpecialHourPeriods.filter(
      (_v: AllSpecialHourPeriod, parentIndex: number) => {
        return parentTargetIndex != parentIndex;
      }
    );
    setAllSpecialHourPeriods(updated);
  };

  const handleOnAddDate = () => {
    const updated = allSpecialHourPeriods.concat([
      {
        key: '',
        closed: false,
        parentIndex: allSpecialHourPeriods.length,
        periods: [
          {
            startDate: null,
            openTime: {
              hours: 0,
              minutes: 0,
            },
            endDate: null,
            closeTime: {
              hours: 0,
              minutes: 0,
            },
            closed: false,
          },
        ],
      },
    ]);
    setAllSpecialHourPeriods(updated);
  };
  const handleClose = () => {
    setAllSpecialHourPeriods(defaultAllSpecialHourPeriods);
  };

  const resetPeriods = (periods: SpecialHourPeriod[]) => {
    setAllSpecialHourPeriods(handleDevideSpecialHourPeriods(periods));
  };

  return {
    allSpecialHourPeriods,
    newAllSpecialHourPeriods,
    setAllSpecialHourPeriods,
    handleOnChangeSwitch,
    handleOnAdd,
    handleOnDelete,
    handleOnChange,
    handleOnChangeDate,
    handleOnDeleteDate,
    handleOnAddDate,
    handleClose,
    resetPeriods,
  };
};
