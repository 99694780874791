import React, { memo, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Pagination from '../Pagination';
import MediaItemCard from './MediaItemCard';
import ViewModal from './ViewModal';

import { MediaItem, MediaCategory } from '../../../models/location-media';
import { Pagination as PaginationModel } from '../../../models/pagination';

import { ListGmbLocationMedisParams as PartnerListGmbLocationMedisParams } from '../../../requests/partner/gmb-location-media-images';
import { ListGmbLocationMedisParams as CompanyListGmbLocationMedisParams } from '../../../requests/company/gmb-location-media-images';

import { sliceByNumber } from '../../../utils/app-array';
import { isSP } from '../../../utils/app-util';

type SearchParams = PartnerListGmbLocationMedisParams | CompanyListGmbLocationMedisParams;

type Props = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    gmbLocationId?: number;
    mediaCategory: MediaCategory;
  };
  searchable?: boolean;
  pagination: PaginationModel;
  mediaItems: MediaItem[];
  paginationPath: string;
  handleSearch: (page: number, params?: SearchParams) => Promise<void>;
  handeleDeleteMediaImage: (id: number) => Promise<void>;
};

const LocalPostList: React.FC<Props> = (props: Props) => {
  const {
    queries,
    pagination,
    mediaItems,
    isLoading,
    searchable,
    handleSearch,
    handeleDeleteMediaImage,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedMediaItem, setSelectedMediaItem] = useState<MediaItem | null>(null);

  const history = window.history;

  const pushState = (v: number) => {
    const mediaCategoryParams = `&mediaCategory=${queries.mediaCategory}`;
    const gmbLocationParams =
      searchable && queries.gmbLocationId ? `&gmbLocationId=${queries.gmbLocationId}` : '';
    history.pushState(
      null,
      '',
      `${props.paginationPath}?page=${v}${mediaCategoryParams}${gmbLocationParams}`
    );
  };

  useEffect(() => {
    pushState(queries.currentPage);
  }, [queries.currentPage, queries.mediaCategory, queries.gmbLocationId]);

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handlePage = (v: number) => {
    pushState(v);
    handleSearch(v, {
      mediaCategory: queries.mediaCategory,
      gmbLocationId: queries.gmbLocationId,
    });
  };

  const handleModal = () => {
    setShowModal(false);
  };

  const handleDelete = async () => {
    if (selectedMediaItem) {
      await handeleDeleteMediaImage(selectedMediaItem.id);
      await handleSearch(queries.currentPage, {
        mediaCategory: queries.mediaCategory,
        gmbLocationId: queries.gmbLocationId,
      });
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="mt-3">
        {mediaItems.length <= 0 ? (
          <h5>写真がありません</h5>
        ) : (
          <>
            {sliceByNumber(mediaItems, isSP() ? 2 : 5).map((splitMediaItems, index) => (
              <Row key={`gmb_location_media_image_view_parent_${index}`} sm={2} md={5} lg={5}>
                {splitMediaItems.map((mediaItem, childIndex) => {
                  return (
                    <Col key={`gmb_location_media_image_view_${childIndex}`}>
                      <MediaItemCard
                        isLoading={false}
                        mediaItem={mediaItem}
                        handleModal={() => {
                          setSelectedMediaItem(mediaItem);
                          setShowModal(true);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
            <Row>
              <Col md={{ span: 9, offset: 5 }}>
                <Pagination
                  path="/"
                  currentPage={pagination.currentPage}
                  lastPage={pagination.totalPage}
                  onClick={handlePage}
                />
              </Col>
            </Row>
            {selectedMediaItem && (
              <ViewModal
                isShow={showModal}
                mediaItem={selectedMediaItem}
                handleModal={handleModal}
                handeleDeleteMediaImage={handleDelete}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(LocalPostList);
