import React, { memo } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  MediaItem,
  MediaCategory,
  AdditionalMediaValue,
  MediaCategoryJp,
  LogoMediaValue,
  CoverMediaValue,
} from '../../../models/location-media';

import {
  SaveLocationMediaParams,
  ListGmbLocationMedisParams,
} from '../../../requests/partner/gmb-location-media-images';

import CreateButton from './CreateButton';

export type Option = {
  label: string;
  value: number;
};

export type SelectedItems = {
  gmbLocationId?: number;
  selectedMediaCategory: MediaCategory;
};

type Props = {
  selectedItems?: SelectedItems;
  searchable: boolean;
  mediaItems: MediaItem[];
  refreshLocationMedia?: (p: number, params?: ListGmbLocationMedisParams) => Promise<void>;
  handleCreateLocationMedia: (params: SaveLocationMediaParams) => Promise<void>;
  handleAfterSearch: (selectedItems?: SelectedItems) => Promise<void>;
};

const SearchBox: React.FC<Props> = (props: Props) => {
  const {
    selectedItems,
    searchable,
    mediaItems,
    refreshLocationMedia,
    handleCreateLocationMedia,
    handleAfterSearch,
  } = props;

  const selectedGmbLocationId = selectedItems?.gmbLocationId;
  const selectedMediaCategory = selectedItems?.selectedMediaCategory || AdditionalMediaValue;

  const handleSelectMediaCategory = async (key: MediaCategory) => {
    await handleAfterSearch({
      gmbLocationId: selectedGmbLocationId,
      selectedMediaCategory: key,
    });
  };

  const handleAferPosted = async () => {
    if (refreshLocationMedia) {
      refreshLocationMedia(1, {
        gmbLocationId: selectedGmbLocationId,
        mediaCategory: selectedMediaCategory,
      });
    }
  };

  const hiddenCreateButton =
    [LogoMediaValue, CoverMediaValue].includes(selectedMediaCategory) && mediaItems.length >= 1;

  return (
    <div>
      <div className="tab-area">
        <Tabs
          defaultActiveKey={selectedMediaCategory || AdditionalMediaValue}
          className="mb-3"
          onSelect={handleSelectMediaCategory}
        >
          {Object.entries(MediaCategoryJp).map((value: [key: MediaCategory, jp: string]) => {
            const [key, jp] = value;
            return <Tab key={key} eventKey={key} title={jp}></Tab>;
          })}
        </Tabs>
      </div>
      <div className={`d-flex ${searchable ? 'justify-content-between' : 'justify-content-end'}`}>
        {!hiddenCreateButton && selectedGmbLocationId && (
          <div className="ml-2">
            <CreateButton
              gmbLocationId={selectedGmbLocationId}
              mediaCategory={selectedMediaCategory}
              handleAferPosted={handleAferPosted}
              handleCreateLocationMedia={handleCreateLocationMedia}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SearchBox);
