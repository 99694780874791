import React, { memo, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Pagination from '../../general/Pagination';
import LocalPostCard from '../../general/gmb-local-bulk-posts/LocalPostCard';
import StandardModal from '../../general/gmb-local-bulk-posts/modals/StandardModal';
import EventModal from '../../general/gmb-local-bulk-posts/modals/EventModal';
import OfferModal from '../../general/gmb-local-bulk-posts/modals/OfferModal';
import AlertModal from '../../general/gmb-local-bulk-posts/modals/Alert';

import { GmbLocalBulkPost, LocalPostTopicType } from '../../../models/gmb-local-bulk-post';
import { Pagination as PaginationModel } from '../../../models/pagination';

import {
  ListGmbLocalBulkPostParams as CompanyListGmbLocalBulkPostParams,
  SaveLocalBulkPostParams as CompanySaveLocalBulkPostParams,
} from '../../../requests/company/gmb-local-bulk-post';

import { sliceByNumber } from '../../../utils/app-array';

type SearchParams = CompanyListGmbLocalBulkPostParams;
type SaveParams = CompanySaveLocalBulkPostParams;

type Props = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    companyId?: number;
    topicType: LocalPostTopicType;
  };
  searchable?: boolean;
  pagination: PaginationModel;
  gmbLocalBulkPosts: GmbLocalBulkPost[];
  paginationPath: string;
  handleGmbLocalBulkPostUpdate: (id: number, params?: SaveParams) => Promise<GmbLocalBulkPost>;
  handleGmbLocalBulkPostDelete: (id: number) => Promise<void>;
  handleSearch: (page: number, params?: SearchParams) => Promise<void>;
};

const LocalPostList: React.FC<Props> = (props: Props) => {
  const {
    queries,
    pagination,
    gmbLocalBulkPosts,
    isLoading,
    searchable,
    handleGmbLocalBulkPostUpdate,
    handleGmbLocalBulkPostDelete,
    handleSearch,
  } = props;

  const history = window.history;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGmbLocalBulkPost, setSelectedGmbLocalBulkPost] = useState<GmbLocalBulkPost | null>(
    null
  );

  const pushState = (v: number) => {
    const topicTypeParams = `&topicType=${queries.topicType}`;
    const companyParams = searchable && queries.companyId ? `&companyId=${queries.companyId}` : '';
    history.pushState(
      null,
      '',
      `${props.paginationPath}?page=${v}${topicTypeParams}${companyParams}`
    );
  };

  useEffect(() => {
    pushState(queries.currentPage);
  }, [queries.currentPage, queries.topicType, queries.companyId]);

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  const handlePage = (v: number) => {
    pushState(v);
    handleSearch(v, {
      topicType: queries.topicType,
      companyId: queries.companyId,
    });
  };

  const handleCreate = async (params: SaveParams) => {
    if (selectedGmbLocalBulkPost) {
      await handleGmbLocalBulkPostUpdate(selectedGmbLocalBulkPost.id, params);
      handleSearch(queries.currentPage, {
        topicType: queries.topicType,
        companyId: queries.companyId,
      });
      setShowModal(false);
    }
  };
  const handleDelete = async () => {
    if (selectedGmbLocalBulkPost) {
      await handleGmbLocalBulkPostDelete(selectedGmbLocalBulkPost.id);
      handleSearch(queries.currentPage, {
        topicType: queries.topicType,
        companyId: queries.companyId,
      });
      setShowModal(false);
    }
  };

  const companyId = queries.companyId || selectedGmbLocalBulkPost?.companyId;
  const topicType = queries.topicType;

  return (
    <>
      <div className="mt-3">
        {gmbLocalBulkPosts.length <= 0 ? (
          <h5>投稿がありません</h5>
        ) : (
          <>
            {sliceByNumber(gmbLocalBulkPosts, 5).map((splitGmbLocalBulkPosts, index) => (
              <Row key={`gmb_local_bulk_post_view_parent_${index}`} sm={2} md={5} lg={5}>
                {splitGmbLocalBulkPosts.map((gmbLocalBulkPost, childIndex) => {
                  return (
                    <Col key={`gmb_local_post_view_${childIndex}`}>
                      <LocalPostCard
                        isLoading={false}
                        topicType={topicType}
                        gmbLocalBulkPost={gmbLocalBulkPost}
                        handleModal={() => {
                          setShowModal(true);
                          setSelectedGmbLocalBulkPost(gmbLocalBulkPost);
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            ))}
            <Row>
              <Col md={{ span: 9, offset: 5 }}>
                <Pagination
                  path="/"
                  currentPage={pagination.currentPage}
                  lastPage={pagination.totalPage}
                  onClick={handlePage}
                />
              </Col>
            </Row>
            {selectedGmbLocalBulkPost && companyId && (
              <>
                {topicType == 'STANDARD' && (
                  <StandardModal
                    isShow={showModal}
                    companyId={companyId}
                    gmbLocalBulkPost={selectedGmbLocalBulkPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalBulkPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
                {topicType == 'EVENT' && (
                  <EventModal
                    isShow={showModal}
                    companyId={companyId}
                    gmbLocalBulkPost={selectedGmbLocalBulkPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalBulkPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
                {topicType == 'OFFER' && (
                  <OfferModal
                    isShow={showModal}
                    companyId={companyId}
                    gmbLocalBulkPost={selectedGmbLocalBulkPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalBulkPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
                {topicType == 'ALERT' && (
                  <AlertModal
                    isShow={showModal}
                    companyId={companyId}
                    gmbLocalBulkPost={selectedGmbLocalBulkPost}
                    handleModal={() => {
                      setShowModal(false);
                      setSelectedGmbLocalBulkPost(null);
                    }}
                    handleCreate={handleCreate}
                    handleDelete={handleDelete}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(LocalPostList);
