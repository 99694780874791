import { ApiClient } from '../client';
import { RegularHours } from '../../models/gmb-location';
import { CompanyRegularHoursTemplate } from '../../models/company-regular-hours-template';

export type SaveCompanyRegularHoursTempate = {
  title: string;
  regularHours: RegularHours;
};

export class RequestCompanyRegularHoursTemplate {
  static create = async (
    params: SaveCompanyRegularHoursTempate
  ): Promise<CompanyRegularHoursTemplate> => {
    const res = await ApiClient.post(`/company/api/company_regular_hours_templates`, params);
    return res.data;
  };

  static update = async (
    id: number,
    params: SaveCompanyRegularHoursTempate
  ): Promise<CompanyRegularHoursTemplate> => {
    const res = await ApiClient.patch(`/company/api/company_regular_hours_templates/${id}`, params);
    return res.data;
  };
}
