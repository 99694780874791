import { ApiClient } from './client';
import { HoursType } from '../models/gmb-location/hours-type';

type SearchParams = {
  categoryId?: string | null;
};

export class RequestGmbHoursType {
  static all = async (params: SearchParams): Promise<HoursType[]> => {
    const res = await ApiClient.get(`/api/gmb_hours_types/all`, { params });
    return res.data;
  };
}
