import React, { memo, useState } from 'react';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { Address } from '../../../../models/gmb-location';

import BaseRaw from './BaseRaw';
import AddressModal from '../form-modal/AddressModal';

type Props = {
  label: string;
  address: Address;
  handleUpdate?: (params: UpdateGmbLocationParams) => Promise<void>;
  editable?: boolean;
};

const AddressRaw: React.FC<Props> = (props) => {
  const editable = props.editable === undefined || props.editable ? true : false;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleModal = () => {
    setIsShow(!isShow);
  };
  const handleSubmit = async (params: UpdateGmbLocationParams): Promise<void> => {
    setIsLoading(true);
    try {
      if (props.handleUpdate !== undefined) await props.handleUpdate(params);
      setIsShow(!isShow);
    } finally {
      setIsLoading(false);
    }
  };
  const addressString = `${props.address.postalCode} ${props.address.administrativeArea} ${
    props.address.addressLines[0] || ''
  } ${props.address.addressLines[1] || ''} ${props.address.addressLines[2] || ''}`;
  return (
    <>
      <BaseRaw
        isLoading={isLoading}
        label={props.label}
        value={addressString}
        handleModal={editable ? handleModal : undefined}
      />
      {editable ? (
        <AddressModal
          isShow={isShow}
          label={props.label}
          addressString={addressString}
          address={props.address}
          handleModal={handleModal}
          handleUpdate={handleSubmit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(AddressRaw);
