import React, { memo } from 'react';

import { GmbLocationReview } from '../../../../models/gmb-location-review';
import {
  RequestGmnLocationReview,
  UpdateReplyParams,
} from '../../../../requests/partner/gmb-location-reviews';

import NoReplyReview from '../../../general/gmb-location-reviews/NoReplyReview';
import { RequestGmbLocation } from '../../../../requests/partner/gmb-locations';

type Props = {
  gmbLocationReviewsListPath: string;
};

const HomeNoReplyReview: React.FC<Props> = (props: Props) => {
  const refresh = async (): Promise<GmbLocationReview[]> => {
    return await RequestGmnLocationReview.latestNoReply();
  };

  const handleUpdateReply = async (id: number, params: UpdateReplyParams): Promise<void> => {
    await RequestGmnLocationReview.updateReply(id, params);
  };
  return (
    <NoReplyReview
      gmbLocationReviewsListPath={props.gmbLocationReviewsListPath}
      fetchLatestNoReplyReviews={refresh}
      handleUpdateReply={handleUpdateReply}
      handleGmbLocationKeyword={RequestGmbLocation.keywords}
    />
  );
};

export default memo(HomeNoReplyReview);
