import { ApiClient } from '../client';
import { SpecialHours } from '../../models/gmb-location';
import { CompanySpecialHoursTemplate } from '../../models/company-special-hours-template';

export type SaveCompanySpecialHoursTempate = {
  title: string;
  specialHours: SpecialHours;
};

export class RequestCompanySpecialHoursTemplate {
  static create = async (
    params: SaveCompanySpecialHoursTempate
  ): Promise<CompanySpecialHoursTemplate> => {
    const res = await ApiClient.post(`/company/api/company_special_hours_templates`, params);
    return res.data;
  };

  static update = async (
    id: number,
    params: SaveCompanySpecialHoursTempate
  ): Promise<CompanySpecialHoursTemplate> => {
    const res = await ApiClient.patch(`/company/api/company_special_hours_templates/${id}`, params);
    return res.data;
  };
}
