import React, { memo } from 'react';
import { evaluationConversion } from '../../../../utils/gmb-local-evaluation';

type Props = {
  evaluation: number | undefined;
};

const EvaluationTab: React.FC<Props> = (props: Props) => {
  const { evaluation } = props;

  return (
    <span className={'text-white ml-2 px-1 rounded evaluation-bg-color-' + evaluation}>
      {evaluationConversion(evaluation)}
    </span>
  );
};

export default memo(EvaluationTab);
