import React, { memo } from 'react';

type Props = {
  evaluation: number | undefined;
  achievement: string | undefined;
};

const AchievementCell: React.FC<Props> = (props: Props) => {
  const { evaluation, achievement } = props;

  return (
    <>
      <td className={'align-middle evaluation-color-' + evaluation}>{achievement}</td>
    </>
  );
};

export default memo(AchievementCell);
