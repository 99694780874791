import React, { memo } from 'react';
import Plus from '../icons/Plus';
import Button from '../Button';

type SaveButtonProps = {
  children?: React.ReactElement | string;
  disabled?: boolean;
  onClick: () => void;
  size?: 'sm' | 'lg';
};

const SaveButton: React.FC<SaveButtonProps> = (props) => {
  const { children, onClick, disabled, size } = props;
  return (
    <Button size={size} variant="primary" onClick={onClick} disabled={disabled || false}>
      <div className="d-flex justify-content-center align-items-center">
        <Plus />
        <div className="ml-2">{children}</div>
      </div>
    </Button>
  );
};

export default memo(SaveButton);
