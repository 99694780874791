import React, { memo, useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CreateButton, { SaveLocalPostParams } from './CreateButton';
import SaveButton from '../button/SaveButton';

import AsyncSelectGmbLocation from '../async-select/gmb-location';
import AsyncSelectCompany from '../async-select/company';

import { Company } from '../../../models/company';
import { GmbLocation } from '../../../models/gmb-location';
import { LocalPostTopicType, LocalPostTopicTypeJp } from '../../../models/gmb-local-post';

import { SearchCompanyParams as PartnerSearchCompanyParams } from '../../../requests/partner/companies';
import { SearchGmbLocationParams as PartnerSearchGmabLocationParams } from '../../../requests/partner/gmb-locations';
import { SearchGmbLocationParams as CompanySearchGmabLocationParams } from '../../../requests/company/gmb-locations';

import { SearchParams as SearchLocalPostParams } from '../../../hooks/useLocalPostSearch';
import { isSP } from '../../../utils/app-util';
import { ApiCancelToken, isApiCancel } from '../../../requests/client';
import { CancelTokenSource } from 'axios';

export type Option = {
  label: string;
  value: number;
};

export type SelectedItems = {
  companyId?: number;
  gmbLocationId?: number;
  selectedTopicType: LocalPostTopicType;
};

type SearchableItem = 'company' | 'gmbLocation';
type SearchCompanyParams = PartnerSearchCompanyParams;
type SearchGmbLocationParams = PartnerSearchGmabLocationParams | CompanySearchGmabLocationParams;

type Props = {
  searchable: boolean;
  searchableItem: SearchableItem[];
  selectedItems?: SelectedItems;
  isSyncing?: boolean;
  refreshLocalPosts?: (p: number, params?: SearchLocalPostParams) => Promise<void>;
  handleGmbLocationKeyword: (id: number, cancelToken?: CancelTokenSource) => Promise<string[]>;
  handleCreateLocalPost: (params: SaveLocalPostParams) => Promise<void>;
  handleShowGmbLocation: (id: number) => Promise<GmbLocation>;
  handleCompanyRequest?: (params: SearchCompanyParams) => Promise<Company[]>;
  handleGmbLocationRequest?: (params: SearchGmbLocationParams) => Promise<GmbLocation[]>;
  handleAfterSearch: (selectedItems?: SelectedItems) => Promise<void>;
  handleGmbLocalPost?: () => Promise<boolean>;
};

const SearchBox: React.FC<Props> = (props: Props) => {
  const {
    searchableItem,
    searchable,
    selectedItems,
    isSyncing,
    refreshLocalPosts,
    handleGmbLocationKeyword,
    handleCreateLocalPost,
    handleShowGmbLocation,
    handleCompanyRequest,
    handleGmbLocationRequest,
    handleGmbLocalPost,
    handleAfterSearch,
  } = props;

  const selectedGmbLocationId = selectedItems?.gmbLocationId;
  const selectedCompanyId = selectedItems?.companyId;
  const selectedTopicType = selectedItems?.selectedTopicType || 'STANDARD';

  const [isSyncingState, setIsSyncingState] = useState<boolean>(isSyncing || false);
  const [keywords, setKeywords] = useState<string[]>([]);

  const handlAsyncGmbLocation = async (gmbLocationId?: number, name?: string) => {
    const v =
      gmbLocationId === undefined || name === undefined
        ? undefined
        : { label: name, value: gmbLocationId };
    await handleAfterSearch({
      companyId: selectedCompanyId,
      gmbLocationId: v?.value,
      selectedTopicType: selectedTopicType,
    });
  };

  const fetchKeywords = async (gmbLocationId: number, cancelToken: CancelTokenSource) => {
    try {
      const data = await handleGmbLocationKeyword(gmbLocationId, cancelToken);
      setKeywords(data);
    } catch (e) {
      if (isApiCancel(e)) {
        return;
      }
    }
  };

  useEffect(() => {
    if (!selectedGmbLocationId) return;

    const cancelToken = ApiCancelToken.source();
    fetchKeywords(selectedGmbLocationId, cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [selectedGmbLocationId]);

  const handlAsyncCompany = async (companyId?: number, name?: string) => {
    const v =
      companyId === undefined || name === undefined ? undefined : { label: name, value: companyId };
    await handleAfterSearch({
      companyId: v?.value,
      gmbLocationId: selectedGmbLocationId,
      selectedTopicType: selectedTopicType,
    });
  };

  const handleSelectTopicType = async (key: LocalPostTopicType) => {
    await handleAfterSearch({
      companyId: selectedCompanyId,
      gmbLocationId: selectedGmbLocationId,
      selectedTopicType: key,
    });
  };

  const handleAferPosted = async () => {
    if (refreshLocalPosts) {
      refreshLocalPosts(1, {
        companyId: selectedCompanyId,
        gmbLocationId: selectedGmbLocationId,
        topicType: selectedTopicType,
      });
    }
  };

  const handleSyncLocalPost = async () => {
    if (handleGmbLocalPost) {
      setIsSyncingState(true);
      await handleGmbLocalPost();
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey={selectedItems?.selectedTopicType || 'STANDARD'}
        className="mb-3"
        onSelect={handleSelectTopicType}
      >
        <Tab eventKey="STANDARD" title={LocalPostTopicTypeJp['STANDARD']}></Tab>
        <Tab eventKey="EVENT" title={LocalPostTopicTypeJp['EVENT']}></Tab>
        <Tab eventKey="OFFER" title={LocalPostTopicTypeJp['OFFER']}></Tab>
        <Tab eventKey="ALERT" title={LocalPostTopicTypeJp['ALERT']}></Tab>
      </Tabs>
      <div className={`d-flex ${searchable ? 'justify-content-between' : 'justify-content-end'}`}>
        {searchable && (
          <div className="d-md-flex justify-content-start mr-2" style={{ width: '70%' }}>
            {searchableItem.includes('company') && handleCompanyRequest && (
              <div className="mt-2 mr-2" style={{ width: isSP() ? '100%' : '50%' }}>
                <AsyncSelectCompany
                  companyId={selectedItems?.companyId}
                  handleRequest={handleCompanyRequest}
                  handleAfterSearch={handlAsyncCompany}
                />
              </div>
            )}
            {searchableItem.includes('gmbLocation') && handleGmbLocationRequest && (
              <div className="mt-2" style={{ width: isSP() ? '100%' : '50%' }}>
                <AsyncSelectGmbLocation
                  gmbLocationId={selectedItems?.gmbLocationId}
                  handleGmbLocationRequest={handleGmbLocationRequest}
                  handleAfterSearch={handlAsyncGmbLocation}
                />
              </div>
            )}
          </div>
        )}
        <div className="d-md-flex justify-content-end" style={{ width: '50%' }}>
          {selectedGmbLocationId && selectedCompanyId === undefined && (
            <div className="mr-2 mt-2">
              <CreateButton
                gmbLocationId={selectedGmbLocationId}
                topicType={selectedTopicType}
                handleCreateLocalPost={handleCreateLocalPost}
                handleShowGmbLocation={handleShowGmbLocation}
                handleAferPosted={handleAferPosted}
                keywords={keywords}
              />
            </div>
          )}
          {handleGmbLocalPost && (
            <div className="mr-2 mt-2">
              <SaveButton onClick={handleSyncLocalPost} disabled={isSyncingState}>
                {isSyncingState ? 'GBP同期中' : 'GBP同期'}
              </SaveButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SearchBox);
