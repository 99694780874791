import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { Alert } from '../../../../service/alert';
import { AttributeGroup } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

import AttributeDisplay from '../partial/AttributeDisplay';
import BaseModal from '../../BaseModal';

import { useAttributes } from '../../../../hooks/useAttributes';

type Props = {
  isShow: boolean;
  label: string;
  attributeGroups: AttributeGroup[];
  attributeValues: AttributeValue[];
  handleModal: () => void;
  handleUpdate: (params: AttributeValue[]) => Promise<void>;
};

const AttributeModal: React.FC<Props> = (props) => {
  const {
    attributeValues,
    attributeErrors,
    handleBoolAttributeValue,
    handleEnumAttributeValue,
    handleRepeatedEnumAttributeValue,
    handleUrlAttributeValue,
    handleClose,
  } = useAttributes({
    attributeValues: props.attributeValues,
  });

  const handleModalClose = () => {
    handleClose();
    props.handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleModalClose}
        handleUpdate={async (): Promise<void> => {
          if (attributeErrors.length === 0) {
            await props.handleUpdate(attributeValues);
            props.handleModal();
          } else {
            const errroMessages = attributeErrors
              .map((error) => {
                return `${error.displayName}の${error.message}`;
              })
              .join(`</br>`);
            Alert.danger(errroMessages);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {props.attributeGroups.map((attributeGroup: AttributeGroup, index: number) => {
              return (
                <div style={{ marginTop: '30px' }} key={`attribute_group_${index}`}>
                  <h5>{attributeGroup.displayName}</h5>
                  <AttributeDisplay
                    key={`attribute_display_${index}`}
                    attributes={attributeGroup.attributes}
                    attributeValues={attributeValues}
                    readonly={false}
                    handleBoolAttributeValue={handleBoolAttributeValue}
                    handleEnumAttributeValue={handleEnumAttributeValue}
                    handleUrlAttributeValue={handleUrlAttributeValue}
                    handleRepeatedEnumAttributeValue={handleRepeatedEnumAttributeValue}
                  />
                </div>
              );
            })}
          </Container>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(AttributeModal);
