import React, { memo } from 'react';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Attribute } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

type Props = {
  attribute: Attribute;
  targetAttributeValue?: AttributeValue;
  readonly: boolean;
  handleAttributeValue?: (v: AttributeValue) => void;
};

const AttributeRepeatedEnumDisplay: React.FC<Props> = (props) => {
  const setSetValue = (metaValue: string, setValues?: string[], unSetValues?: string[]) => {
    if (setValues === undefined) {
      return [metaValue];
    }
    if (setValues?.includes(metaValue)) {
      return setValues.filter((v) => {
        return v != metaValue;
      });
    }
    if (unSetValues?.includes(metaValue)) {
      return (
        setValues?.filter((v) => {
          return v != metaValue;
        }) || []
      );
    }
    return setValues?.concat([metaValue]) || [];
  };

  const setUnsetValue = (metaValue: string, setValues?: string[], unSetValues?: string[]) => {
    if (unSetValues === undefined) {
      return [];
    }
    if (setValues?.includes(metaValue)) {
      return unSetValues?.concat([metaValue]) || [];
    }
    if (unSetValues?.includes(metaValue)) {
      return (
        unSetValues.filter((v) => {
          return v != metaValue;
        }) || []
      );
    }
    return unSetValues?.concat([metaValue]) || [];
  };

  return (
    <Container fluid style={{ backgroundColor: '#DCDCDC', padding: '10px' }}>
      <Row>
        <Col>{props.attribute.displayName}</Col>
      </Row>
      <Row>
        {props.attribute.valueMetadata.map((data, index) => {
          return (
            <Col key={`meta_data_${index}`} md="auto" style={{ marginTop: '5px' }}>
              <Button
                variant={'light'}
                disabled={props.readonly}
                onClick={() => {
                  if (props.handleAttributeValue == undefined) {
                    return;
                  }
                  props.handleAttributeValue({
                    name: 'attributes/' + props.attribute.attributeId,
                    valueType: props.attribute.valueType,
                    repeatedEnumValue: {
                      setValues: setSetValue(
                        data.value,
                        props.targetAttributeValue?.repeatedEnumValue?.setValues,
                        props.targetAttributeValue?.repeatedEnumValue?.unsetValues
                      ),
                      unsetValues: setUnsetValue(
                        data.value,
                        props.targetAttributeValue?.repeatedEnumValue?.setValues,
                        props.targetAttributeValue?.repeatedEnumValue?.unsetValues
                      ),
                    },
                    uriValues: [],
                    values: [],
                  });
                }}
              >
                {data.displayName}
                {props.targetAttributeValue ? (
                  <>
                    <Badge
                      style={{ marginLeft: '10px' }}
                      variant={
                        props.targetAttributeValue.repeatedEnumValue.setValues?.includes(data.value)
                          ? 'success'
                          : props.targetAttributeValue.repeatedEnumValue.unsetValues?.includes(
                              data.value
                            )
                          ? 'danger'
                          : 'secondary'
                      }
                    >
                      {props.targetAttributeValue.repeatedEnumValue.setValues?.includes(data.value)
                        ? '◯'
                        : props.targetAttributeValue.repeatedEnumValue.unsetValues?.includes(
                            data.value
                          )
                        ? 'X'
                        : '-'}
                    </Badge>
                  </>
                ) : (
                  <Badge style={{ marginLeft: '10px' }} variant="secondary">
                    -
                  </Badge>
                )}
              </Button>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default memo(AttributeRepeatedEnumDisplay);
