import { ApiClient, PagenationApiResponse } from '../client';
import {
  GmbLocalPost,
  LocalPostEvent,
  LocalPostOffer,
  LocalPostTopicType,
  CallToAction,
} from '../../models/gmb-local-post';
import { Pagination } from '../../models/pagination';

export type ListGmbLocalPostParams = {
  companyId?: number;
  gmbLocationId?: number;
  topicType: LocalPostTopicType;
};

export type SaveLocalPostParams = {
  gmbLocationId: number;
  params: {
    summary?: string;
    event?: LocalPostEvent;
    languageCode: string;
    topicType: LocalPostTopicType;
    callToAction?: CallToAction;
    offer?: LocalPostOffer;
    alertType?: 'COVID_19';
  };
};

export class RequestGmbLocalPost {
  static create = async (params?: SaveLocalPostParams): Promise<void> => {
    const res = await ApiClient.post(`/company/api/gmb_local_posts`, params);
    return res.data;
  };

  static update = async (id: number, params?: SaveLocalPostParams): Promise<GmbLocalPost> => {
    const res = await ApiClient.patch(`/company/api/gmb_local_posts/${id}`, params);
    return res.data;
  };

  static destroy = async (id: number): Promise<void> => {
    const res = await ApiClient.delete(`/company/api/gmb_local_posts/${id}`);
    return res.data;
  };

  static list = async (
    page: number,
    params: ListGmbLocalPostParams
  ): Promise<PagenationApiResponse<GmbLocalPost[], Pagination>> => {
    const res = await ApiClient.get(`/company/api/gmb_local_posts?page=${page}`, { params });
    return res.data;
  };

  static latestPosts = async (): Promise<GmbLocalPost[]> => {
    const res = await ApiClient.get(`/company/api/gmb_local_posts/latest_posts`);
    return res.data;
  };
}
