import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Plus from '../../../../components/general/icons/Plus';

import { MoreHours } from '../../../../models/gmb-location';
import { HoursType, getHoursType } from '../../../../models/gmb-location/hours-type';
import {
  AllWeekHoursPeriods,
  handleDevideHoursPeriods,
} from '../../../../models/gmb-location/period';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';

import MoreHoursModal from './MoreHoursModal';
import PeriodsTable from '../partial/PeriodsTable';
import BaseRaw from '../raws/BaseRaw';
import BaseModal from '../../BaseModal';

import { CompanyMoreHoursTemplate } from '../../../../models/company-more-hours-template';
type MoreHourTemplate = CompanyMoreHoursTemplate;

type Option = {
  value: MoreHours[];
  label: string;
};

type Props = {
  isShow: boolean;
  label: string;
  moreHours: MoreHours[];
  allHoursTypes: HoursType[];
  hoursTypes: HoursType[];
  templateTitle?: string;
  templateList?: MoreHourTemplate[];
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams, templateTitle?: string) => Promise<void>;
};

const MoreHoursDetailModeal: React.FC<Props> = (props) => {
  const [templateTitle, setTemplateTitle] = useState<string | undefined>(props.templateTitle);
  const [moreHours, setMoreHours] = useState<MoreHours[]>(props.moreHours);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChildModalShow, setIsChildModalShow] = useState<boolean[]>(
    moreHours.map(() => {
      return false;
    })
  );
  const [searchTimeId, setSearchTimeId] = useState<number | null>(null);

  const handleAllWeekHoursPeriodsListWithLabel = (v: MoreHours[]) =>
    v.map((moreHour) => {
      return {
        label: getHoursType(props.allHoursTypes, moreHour.hoursTypeId),
        allWeekHoursPeriods: handleDevideHoursPeriods(moreHour.periods),
      };
    });

  const [allWeekHoursPeriodsListWithLabel, setAllWeekHoursPeriodsListWithLabel] = useState<
    {
      label: string;
      allWeekHoursPeriods: AllWeekHoursPeriods;
    }[]
  >(handleAllWeekHoursPeriodsListWithLabel(props.moreHours));

  const hoursTypeIds = moreHours.map((moreHour) => moreHour.hoursTypeId);
  const newHoursType = props.hoursTypes.filter(
    (hoursType) => !hoursTypeIds.includes(hoursType.hoursTypeId)
  );

  const handleChilsModalClose = (raw: number) => {
    setIsChildModalShow(
      isChildModalShow.map((v, index) => {
        return raw == index ? !v : v;
      })
    );
    setMoreHours(moreHours.filter((m) => m.periods.length > 0));
    props.handleModal();
  };

  const handleUpdateMorehours = (updateHours: MoreHours[]) => {
    const v = updateHours.filter((m) => m.periods.length > 0);
    setMoreHours(v);
    setAllWeekHoursPeriodsListWithLabel(handleAllWeekHoursPeriodsListWithLabel(v));
  };

  const addHoursType = (hoursType: HoursType) => {
    const updatedMoreHours = moreHours.concat([
      {
        hoursTypeId: hoursType.hoursTypeId,
        periods: [],
      },
    ]);
    setMoreHours(updatedMoreHours);
    setIsChildModalShow(
      updatedMoreHours.map((v) => {
        return v.hoursTypeId == hoursType.hoursTypeId ? true : false;
      })
    );
    props.handleModal();
  };

  const handleAllUpdate = async () => {
    await props.handleUpdate(
      {
        moreHours,
      },
      templateTitle
    );
  };

  const options = props.templateList?.map((v: MoreHourTemplate): Option => {
    return { value: v.moreHours, label: v.title };
  });

  const handleSelected = async (option: Option) => {
    setIsLoading(true);
    if (searchTimeId !== null) {
      clearTimeout(searchTimeId);
    }
    await new Promise((resolve) => {
      setSearchTimeId(window.setTimeout(resolve, 500));
    });
    setAllWeekHoursPeriodsListWithLabel(handleAllWeekHoursPeriodsListWithLabel(option.value));
    setMoreHours(option.value);
    setIsLoading(false);
  };

  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={props.handleModal}
        handleUpdate={handleAllUpdate}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <>
              <Container>
                <Row>
                  {newHoursType.map((hoursType, index) => {
                    return (
                      <Col md="auto" key={`hours_type_${index}`} style={{ marginTop: '10px' }}>
                        <Button variant="light" onClick={() => addHoursType(hoursType)}>
                          {hoursType.jaDisplayName}&nbsp;
                          <Plus />
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
              <Container>
                {templateTitle !== undefined ? (
                  <Form.Group>
                    <Form.Label>タイトル</Form.Label>
                    <Form.Control
                      placeholder="タイトル"
                      value={templateTitle}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTemplateTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Form.Label>テンプレートから選択</Form.Label>
                    <Select onChange={handleSelected} options={options} />
                  </Form.Group>
                )}
              </Container>
              {allWeekHoursPeriodsListWithLabel.map(
                (
                  allWeekHoursPeriodsWithLabel: {
                    label: string;
                    allWeekHoursPeriods: AllWeekHoursPeriods;
                  },
                  index: number
                ) => {
                  return (
                    <div key={`more_houres_detail_${index}`} style={{ marginTop: '10px' }}>
                      <BaseRaw
                        isLoading={false}
                        label={allWeekHoursPeriodsWithLabel.label}
                        cardNode={
                          <div style={{ marginTop: '10px' }} key={`periods_table_${index}`}>
                            <PeriodsTable
                              allWeekHoursPeriods={allWeekHoursPeriodsWithLabel.allWeekHoursPeriods}
                              readonly={true}
                              holidayVisible={false}
                            />
                          </div>
                        }
                        handleModal={() => handleChilsModalClose(index)}
                      />
                    </div>
                  );
                }
              )}
            </>
          )}
        </Modal.Body>
      </BaseModal>
      {!isLoading &&
        moreHours.map((v: MoreHours, index: number): React.ReactNode => {
          return (
            <MoreHoursModal
              key={index}
              isShow={isChildModalShow[index]}
              label={`${getHoursType(props.hoursTypes, v.hoursTypeId)}`}
              moreHours={moreHours}
              hoursTypeId={v.hoursTypeId}
              moreHoursPeriods={v.periods}
              handleUpdate={props.handleUpdate}
              handleUpdateMorehours={handleUpdateMorehours}
              handleModal={() => handleChilsModalClose(index)}
              templateTitle={templateTitle}
            />
          );
        })}
    </>
  );
};

export default memo(MoreHoursDetailModeal);
