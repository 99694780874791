import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { validateUrl } from '../../../../utils/validate';
import { UpdateGmbLocationParams } from '../../../../requests/partner/gmb-locations';
import { Alert } from '../../../../service/alert';

import BaseModal from '../../BaseModal';

type Props = {
  isShow: boolean;
  label: string;
  value: string;
  handleModal: () => void;
  handleUpdate: (params: UpdateGmbLocationParams) => Promise<void>;
};

const WebsiteUrlModal: React.FC<Props> = (props) => {
  const [websiteUri, setWebsiteUri] = useState<string>(props.value);
  const handleChangeInput = (value: string) => {
    setWebsiteUri(value);
  };
  const handleClose = () => {
    setWebsiteUri(props.value);
    props.handleModal();
  };
  return (
    <>
      <BaseModal
        isShow={props.isShow}
        handleClose={handleClose}
        handleUpdate={async () => {
          if (validateUrl(websiteUri)) {
            await props.handleUpdate({
              websiteUri,
            });
            props.handleModal();
          } else {
            throw Alert.danger('WebサイトURLの形式が正しくありません');
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={websiteUri}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeInput(e.target.value);
            }}
          />
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(WebsiteUrlModal);
