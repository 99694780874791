import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';

type Dataset = {
  label: string;
  scaleShowLabels: boolean;
  data: number[];
  borderColor: string[];
  fill: boolean;
  backgroundColor?: string;
};

type Props = {
  labels: string[];
  datasets: Dataset[];
  width?: number;
  height?: number;
};

const StackBarChart: React.FC<Props> = (props) => {
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  return (
    <Bar
      data={{
        labels: data.labels,
        responsive: true,
        offset: true,
        datasets: data.datasets,
      }}
      options={{
        responsive: true,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      }}
    />
  );
};

export default memo(StackBarChart);
