import React, { memo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { SaveLocalBulkPostParams } from '../../../../requests/partner/gmb-local-bulk-post';

import { GmbLocalBulkPost, CallToActionType } from '../../../../models/gmb-local-bulk-post';

import ActionButtonSelect, { Option } from './ActionButtonSelect';
import BaseModal from '../../BaseModal';

import { validateUrl } from '../../../../utils/validate';
import { Alert } from '../../../../service/alert';

type Props = {
  isShow: boolean;
  companyId: number;
  gmbLocalBulkPost?: GmbLocalBulkPost;
  handleModal: () => void;
  handleCreate: (params: SaveLocalBulkPostParams) => Promise<void>;
  handleDelete?: () => Promise<void>;
};

const AlertModal: React.FC<Props> = (props) => {
  const { isShow, companyId, gmbLocalBulkPost, handleModal, handleCreate, handleDelete } = props;

  const [summary, setSummary] = useState<string>(gmbLocalBulkPost?.localPost?.summary || '');
  const [callToActionType, setCallToActionType] = useState<CallToActionType>(
    gmbLocalBulkPost?.localPost?.callToAction?.actionType || 'ACTION_TYPE_UNSPECIFIED'
  );
  const [callToActionUrl, setCallToActioUrl] = useState<string>('');

  const handleChangeInput = (value: string) => {
    setSummary(value);
  };

  const handleChangeActionButtonType = (option: Option) => {
    setCallToActionType(option.value);
  };

  const handleChangeActionUrl = (val: string) => {
    setCallToActioUrl(val);
  };

  const resetForm = () => {
    setSummary('');
    setCallToActionType('ACTION_TYPE_UNSPECIFIED');
    setCallToActioUrl('');
  };

  const handleClose = () => {
    resetForm();
    handleModal();
  };

  return (
    <>
      <BaseModal
        isShow={isShow}
        centered={false}
        alert={true}
        buttonText="投稿"
        handleClose={handleClose}
        handleUpdate={async (): Promise<void> => {
          if (summary == '') {
            throw Alert.danger('投稿内容は必須です');
          }
          if (
            callToActionType != 'ACTION_TYPE_UNSPECIFIED' &&
            callToActionType != 'CALL' &&
            !validateUrl(callToActionUrl)
          ) {
            throw Alert.danger('ボタンのリンクが正しくありません');
          }

          try {
            await handleCreate({
              companyId,
              params: {
                summary,
                languageCode: 'JP',
                topicType: 'ALERT',
                alertType: 'COVID_19',
                callToAction:
                  callToActionType == 'ACTION_TYPE_UNSPECIFIED'
                    ? undefined
                    : {
                        actionType: callToActionType,
                        url: callToActionType == 'CALL' ? '' : callToActionUrl,
                      },
              },
            });
            Alert.success('投稿しました');
            handleModal();
          } catch {
            Alert.danger('投稿に失敗しました');
          }
        }}
        handleDelete={handleDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`最新情報投稿の追加`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>投稿内容</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="投稿内容を入力してください"
              rows={10}
              value={summary}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeInput(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <ActionButtonSelect
              selectedCallToActionType={callToActionType}
              callToActionUrl={callToActionUrl}
              handleChangeActionUrl={handleChangeActionUrl}
              handleSelected={handleChangeActionButtonType}
            />
          </Form.Group>
        </Modal.Body>
      </BaseModal>
    </>
  );
};

export default memo(AlertModal);
