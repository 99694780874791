import { useState } from 'react';
import queryString from 'query-string';

import {
  MediaItem,
  MediaCategory,
  AdditionalMediaValue,
  MediaCategoryJp,
} from '../models/location-media';

import { Pagination as PaginationModel, initializePagination } from '../models/pagination';

import { PagenationApiResponse } from '../requests/client';
import { ListGmbLocationMedisParams as PartnerListGmbLocationMedisParams } from '../requests/partner/gmb-location-media-images';

export type SearchParams = PartnerListGmbLocationMedisParams;

export type SelectedItems = {
  companyId?: number;
  gmbLocationId?: number;
  selectedMediaCategory: MediaCategory;
};

type Props = {
  specifiedGmbLocationId?: number;
  requestLocationMediaImages: (
    page: number,
    params?: SearchParams
  ) => Promise<PagenationApiResponse<MediaItem[], PaginationModel>>;
};

type Queries = {
  currentPage: number;
  mediaCategory: MediaCategory;
  gmbLocationId?: number;
};

type useGmbLocationMediaImageData = {
  isLoading: boolean;
  queries: {
    currentPage: number;
    mediaCategory: MediaCategory;
    gmbLocationId?: number;
  };
  pagination: PaginationModel;
  mediaItems?: MediaItem[];
  refresh(p: number, params?: SearchParams): Promise<void>;
  handleSearch(selectedItems?: SelectedItems): Promise<void>;
};

export const useGmbLocationMediaImage = (props: Props): useGmbLocationMediaImageData => {
  const { specifiedGmbLocationId, requestLocationMediaImages } = props;

  const qs = queryString.parse(location.search);
  const currentPage = qs?.page ? parseInt(qs.page as string) : 1;
  const mediaCategory = qs?.mediaCategory
    ? (qs.mediaCategory as MediaCategory)
    : AdditionalMediaValue;
  const gmbLocationId = specifiedGmbLocationId
    ? specifiedGmbLocationId
    : qs?.gmbLocationId
    ? parseInt(qs.gmbLocationId as string)
    : undefined;

  const validMediaCategory = Object.entries(MediaCategoryJp).some(
    (value: [key: MediaCategory, jp: string]) => {
      const [key, _] = value;
      return key === mediaCategory;
    }
  );

  const [queries, setQueries] = useState<Queries>({
    currentPage: currentPage,
    mediaCategory: validMediaCategory ? mediaCategory : AdditionalMediaValue,
    gmbLocationId: gmbLocationId,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationModel>(initializePagination);
  const [mediaItems, setMediaItems] = useState<MediaItem[] | undefined>(undefined);

  const refresh = async (p: number, params?: SearchParams): Promise<void> => {
    setIsLoading(true);
    const v = await requestLocationMediaImages(p, params);
    setMediaItems(v.data);
    setPagination(v.pagination);
    setIsLoading(false);
  };

  const handleSearch = async (selectedItems?: SelectedItems): Promise<void> => {
    setQueries({
      currentPage: 1,
      gmbLocationId: selectedItems?.gmbLocationId,
      mediaCategory: selectedItems?.selectedMediaCategory || AdditionalMediaValue,
    });
  };

  return {
    isLoading,
    queries,
    pagination,
    mediaItems,
    refresh,
    handleSearch,
  };
};
