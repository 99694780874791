import React, { memo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Attribute } from '../../../../models/gmb-location/attribute-group';
import { Attribute as AttributeValue } from '../../../../models/gmb-location';

import AttributeBoolDisplay from './AttributeBoolDisplay';
import AttributeEnumDisplay from './AttributeEnumDisplay';
import AttributeUrlDisplay from './AttributeUrlDisplay';
import AttributeRepeatedEnumDisplay from './AttributeRepeatedEnumDisplay';

import { sliceByNumber } from '../../../../utils/app-array';

type Props = {
  attributes: Attribute[];
  attributeValues: AttributeValue[];
  readonly: boolean;
  handleBoolAttributeValue?: (v: AttributeValue) => void;
  handleEnumAttributeValue?: (v: AttributeValue) => void;
  handleRepeatedEnumAttributeValue?: (v: AttributeValue) => void;
  handleUrlAttributeValue?: (displayName: string, v: AttributeValue) => void;
};

const AttributeDisplay: React.FC<Props> = (props) => {
  const switchDisplay = (
    valuType: string,
    attribute: Attribute,
    targetAttributeValue: AttributeValue | undefined,
    index: number
  ): React.ReactNode => {
    switch (valuType) {
      case 'BOOL':
        return (
          <AttributeBoolDisplay
            key={`attribute_bool_display_${index}`}
            attribute={attribute}
            targetAttributeValue={targetAttributeValue}
            readonly={props.readonly}
            handleAttributeValue={props.handleBoolAttributeValue}
          />
        );
      case 'ENUM':
        return (
          <AttributeEnumDisplay
            key={`attribute_enum_display_${index}`}
            attribute={attribute}
            targetAttributeValue={targetAttributeValue}
            readonly={props.readonly}
            handleAttributeValue={props.handleEnumAttributeValue}
          />
        );
      case 'URL':
        return (
          <AttributeUrlDisplay
            key={`attribute_url_display_${index}`}
            attribute={attribute}
            targetAttributeValue={targetAttributeValue}
            readonly={props.readonly}
            handleAttributeValue={props.handleUrlAttributeValue}
          />
        );
      case 'REPEATED_ENUM':
        return (
          <AttributeRepeatedEnumDisplay
            key={`attribute_url_display_${index}`}
            attribute={attribute}
            targetAttributeValue={targetAttributeValue}
            readonly={props.readonly}
            handleAttributeValue={props.handleRepeatedEnumAttributeValue}
          />
        );
      default:
        return <>{attribute.displayName}</>;
    }
  };

  return (
    <Container fluid>
      {sliceByNumber(props.attributes, 5).map((sliceAttributes: Attribute[], index: number) => {
        return (
          <Row key={`attributeDisplay_${index}`}>
            {sliceAttributes.map((attribute, index: number): React.ReactNode => {
              const targetAttributeValue = props.attributeValues.find((v) => {
                return v.name.split('/')[1] == attribute.attributeId;
              });
              return (
                <Col key={`switchDisplay_${index}`} md="auto" style={{ marginTop: '10px' }}>
                  {switchDisplay(attribute.valueType, attribute, targetAttributeValue, index)}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </Container>
  );
};

export default memo(AttributeDisplay);
