import React, { memo } from 'react';
import { GmbLocalMediaImageEvaluation } from '../../../../models/gmb-local-evaluation';
import EvaluationConversionCells from '../TableData/EvaluationConversionCells';

type Props = {
  data: GmbLocalMediaImageEvaluation | undefined;
};

const MediaImageEvaluationTableBody: React.FC<Props> = (props: Props) => {
  const { data } = props;

  return (
    <>
      <tr>
        <th className="text-left align-middle" colSpan={2}>
          ロゴ写真、カバー写真の設定
        </th>
        <EvaluationConversionCells
          evaluation={data?.baseImage.evaluation}
          achievement={data?.baseImage.achievement}
        />
      </tr>
      <tr>
        <th className="text-left align-middle" colSpan={2}>
          写真追加有無（７日間）
        </th>
        <EvaluationConversionCells
          evaluation={data?.numberOfImagesThisWeek.evaluation}
          achievement={data?.numberOfImagesThisWeek.achievement}
        />
      </tr>
      <tr>
        <th className="text-left align-middle" colSpan={2}>
          写真追加の継続週数
        </th>
        <EvaluationConversionCells
          evaluation={data?.consecutiveImagePosting.evaluation}
          achievement={data?.consecutiveImagePosting.achievement}
        />
      </tr>
    </>
  );
};

export default memo(MediaImageEvaluationTableBody);
