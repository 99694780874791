import { ApiClient } from '../client';
import {
  GmbLocation,
  Address,
  Profile,
  RegularHours,
  MoreHours,
  Attribute,
  SpecialHours,
  ServiceArea,
  Categories,
  PhoneNumbers,
  OpenInfoStatus,
} from '../../models/gmb-location';

import { GmbLocalPost } from 'models/gmb-local-post';
import { MediaItem } from 'models/location-media';
import { GmbLocationReview } from 'models/gmb-location-review';
import { GmbLocationSetting } from 'models/gmb-location-setting';
import { CancelTokenSource } from 'axios';

export type UpdateOpenInfoParams = {
  status?: OpenInfoStatus;
  openingDate?: {
    year: number | null;
    month: number | null;
    day: number | null;
  };
};

export type UpdateGmbLocationParams = {
  title?: string;
  storefrontAddress?: Address;
  categories?: Categories;
  phoneNumbers?: PhoneNumbers;
  websiteUri?: string;
  profile?: Profile;
  openInfo?: UpdateOpenInfoParams | null;
  regularHours?: RegularHours;
  specialHours?: SpecialHours;
  moreHours?: MoreHours[];
  serviceArea?: ServiceArea;
};

export type UpdateGmbLocationAttributesParams = {
  attributes: Attribute[];
};

export type UpdateGmbLocationSettingParams = {
  tamperPrevention?: boolean;
};

export type SearchGmbLocationParams = {
  gmbLocationId?: number;
  name?: string;
};

export class RequestGmbLocation {
  static sync = async (): Promise<boolean> => {
    const res = await ApiClient.post(`/partner/api/gmb_locations/sync`);
    return res.data;
  };

  static show = async (id: number, cancelToken?: CancelTokenSource): Promise<GmbLocation> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}`, {
      cancelToken: cancelToken?.token,
    });
    return res.data;
  };

  static search = async (params?: SearchGmbLocationParams): Promise<GmbLocation[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/search`, { params });
    return res.data;
  };

  static update = async (id: number, params: UpdateGmbLocationParams): Promise<GmbLocation> => {
    const res = await ApiClient.patch(`/partner/api/gmb_locations/${id}`, params);
    return res.data;
  };

  static updateLocationAttributes = async (
    id: number,
    params: UpdateGmbLocationAttributesParams
  ): Promise<Attribute[]> => {
    const res = await ApiClient.patch(
      `/partner/api/gmb_locations/${id}/update_location_attributes`,
      params
    );
    return res.data;
  };

  static latestLocalPosts = async (id: number): Promise<GmbLocalPost[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/latest_local_posts`);
    return res.data;
  };

  static mostRecentLocalPosts = async (id: number): Promise<GmbLocalPost[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/most_recent_local_posts`);
    return res.data;
  };

  static latestMediaImages = async (id: number): Promise<MediaItem[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/latest_media_images`);
    return res.data;
  };

  static noReplyReviews = async (id: number): Promise<GmbLocationReview[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/no_reply_reviews`);
    return res.data;
  };

  static updateSetting = async (
    id: number,
    params: UpdateGmbLocationSettingParams
  ): Promise<GmbLocationSetting> => {
    const res = await ApiClient.post(`/partner/api/gmb_locations/${id}/update_setting`, params);
    return res.data;
  };

  static keywords = async (id: number, cancelToken?: CancelTokenSource): Promise<string[]> => {
    const res = await ApiClient.get(`/partner/api/gmb_locations/${id}/keywords`, {
      cancelToken: cancelToken?.token,
    });
    return res.data;
  };
}
