import React, { memo } from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import {
  GmbLocalBulkPost,
  LocalPostTopicType,
  LocalPostEventValue,
  LocalPostOfferValue,
  eventStartDateTime,
  eventEndDateTime,
  CallToActionTypeOption,
} from '../../../models/gmb-local-bulk-post';

import moment from '../../../utils/moment-ja';

import styled from 'styled-components';

type Props = {
  isLoading: boolean;
  topicType: LocalPostTopicType;
  gmbLocalBulkPost: GmbLocalBulkPost;
  handleModal: () => void;
};

const LocalPostCard = styled(Card)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const CardText = styled(Card.Text)`
  height: 72px;
  font-size: 12px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow-wrap: anywhere;
`;

const CardHeaderSpan = styled.span`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const ImageWrap = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 50%;
`;

const ImageWrapImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const UpdateDate = styled.p`
  text-align: right;
  font-size: 12px;
`;

const BaseRaw: React.FC<Props> = (props) => {
  const { topicType } = props;
  const imageUrl = props.gmbLocalBulkPost.localPost.media[0]
    ? props.gmbLocalBulkPost.localPost.media[0].googleUrl
    : '/images/no_image.png';
  return (
    <LocalPostCard onClick={() => props.handleModal()}>
      <Card.Header>
        <CardHeaderSpan>{props.gmbLocalBulkPost.companyName}</CardHeaderSpan>
      </Card.Header>
      <ImageWrap>
        <ImageWrapImage src={imageUrl} />
      </ImageWrap>
      <Card.Body>
        <CardText>{props.gmbLocalBulkPost.localPost.summary || ''}</CardText>
        {[LocalPostEventValue, LocalPostOfferValue].includes(topicType) && (
          <>
            <CardText>{props.gmbLocalBulkPost.localPost.event?.title || ''}</CardText>
            <CardText>
              開催日:{eventStartDateTime(props.gmbLocalBulkPost.localPost.event?.schedule)}
              <br />
              終了日:{eventEndDateTime(props.gmbLocalBulkPost.localPost.event?.schedule)}
            </CardText>
          </>
        )}
        {props.gmbLocalBulkPost.localPost.callToAction && (
          <Button>
            {CallToActionTypeOption[props.gmbLocalBulkPost.localPost.callToAction.actionType]}
          </Button>
        )}
        <UpdateDate>
          {moment(props.gmbLocalBulkPost.localPost.updateTime).format('Y年M月D日(dd)')}
        </UpdateDate>
      </Card.Body>
    </LocalPostCard>
  );
};

export default memo(BaseRaw);
