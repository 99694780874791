import { DateType } from './share/date-type';
import { TimeOfDay } from './share/time-of-day';

export const CallToActionBookValue = 'BOOK';
export const CallToActionOrderValue = 'ORDER';
export const CallToActionShopValue = 'SHOP';
export const CallToActionLearnMoreValue = 'LEARN_MORE';
export const CallToActionSignUpValue = 'SIGN_UP';
export const CallToActionCallValue = 'CALL';

export type CallToActionBookType = typeof CallToActionBookValue;
export type CallToActionOrderType = typeof CallToActionOrderValue;
export type CallToActionShopType = typeof CallToActionShopValue;
export type CallToActionLearnMoreType = typeof CallToActionLearnMoreValue;
export type CallToActionSignUpType = typeof CallToActionSignUpValue;
export type CallToActionCallType = typeof CallToActionCallValue;

export type CallToActionType =
  | 'ACTION_TYPE_UNSPECIFIED'
  | CallToActionBookType
  | CallToActionOrderType
  | CallToActionShopType
  | CallToActionLearnMoreType
  | CallToActionSignUpType
  | CallToActionCallType;

export const CallToActionTypeOption = {
  ACTION_TYPE_UNSPECIFIED: 'なし',
  [CallToActionBookValue]: '予約',
  [CallToActionOrderValue]: 'オンライン注文',
  [CallToActionShopValue]: '購入',
  [CallToActionLearnMoreValue]: '詳細',
  [CallToActionSignUpValue]: '登録',
  [CallToActionCallValue]: '今すぐ電話',
};

export type CallToAction = {
  actionType: CallToActionType;
  url: string;
};

export type ScheduleDateTime = {
  startDate: DateType;
  startTime: TimeOfDay;
  endDate: DateType;
  endTime: TimeOfDay;
};

export type LocalPostEvent = {
  title: string;
  schedule: ScheduleDateTime;
};

export type LocalPostOffer = {
  couponCode: string;
  redeemOnlineUrl: string;
  termsConditions: string;
};

export type MediaItem = {
  googleUrl: string;
};

export type LocalPostState = 'LOCAL_POST_STATE_UNSPECIFIED' | 'REJECTED' | 'LIVE' | 'PROCESSING';

export const LocalPostStndardValue = 'STANDARD';
export const LocalPostEventValue = 'EVENT';
export const LocalPostOfferValue = 'OFFER';
export const LocalPostAlertValue = 'ALERT';

export type LocalPostStndardType = typeof LocalPostStndardValue;
export type LocalPostEventType = typeof LocalPostEventValue;
export type LocalPostOfferType = typeof LocalPostOfferValue;
export type LocalPostAlertType = typeof LocalPostAlertValue;

export type LocalPostTopicType =
  | 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED'
  | LocalPostStndardType
  | LocalPostEventType
  | LocalPostOfferType
  | LocalPostAlertType;

export const LocalPostTopicTypeJp = {
  [LocalPostStndardValue]: '最新情報',
  [LocalPostEventValue]: 'イベント',
  [LocalPostOfferValue]: '特典',
  [LocalPostAlertValue]: 'COVID-19',
};

export type AlertType = 'ALERT_TYPE_UNSPECIFIED' | 'COVID_19';

export type LocalPost = {
  name: string;
  languageCode: string;
  summary: string;
  callToAction: CallToAction;
  createTime: string;
  updateTime: string;
  event: LocalPostEvent;
  state: LocalPostState;
  media: MediaItem[];
  searchUrl: string;
  topicType: LocalPostTopicType;
  alertType: AlertType;
  offer: LocalPostOffer;
};

export type GmbLocalPost = {
  id: number;
  gmbLocationId: number;
  locationName: string;
  viewCount: number;
  localPost: LocalPost;
};

export const eventStartDateTime = (v: ScheduleDateTime | null): string => {
  const startDate = v?.startDate;
  const startTime = v?.startTime;

  if (startDate) {
    return `${startDate.year}/${startDate.month}/${startDate.day} ${startTime?.hours || '00'}:${
      startTime?.minutes || '00'
    }`;
  }

  return '-';
};

export const eventEndDateTime = (v: ScheduleDateTime | null): string => {
  const endDate = v?.endDate;
  const endTime = v?.endTime;

  if (endDate) {
    return `${endDate.year}/${endDate.month}/${endDate.day} ${endTime?.hours || '00'}:${
      endTime?.minutes || '00'
    }`;
  }

  return '-';
};
