export type Pagination = {
  currentPage: number;
  totalPage: number;
  totalCount: number;
};

export const initializePagination = {
  currentPage: 1,
  totalPage: 1,
  totalCount: 1,
};
